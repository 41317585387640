import React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import Dashboard from "./dashboard/dashboard";
import Layout from "../../../layout/ClientAppLayout";
import AllSurveys from "./AllSurveys/AllSurveys";
import AllCustomers from "./allcustomers/allcustomers";
import QuatationReports from "./quatationReport/quatationreports";
import LoadedSurveys from "./Loaded  Surveys/LoadedSurveys";
import CreateAdmin from "./createAdmin/index";
import AllUsers from "./allusers/index";
import companies from "./companies/index";
import createCompany from "./createcompanies/index";
import editCompany from "./editcompanies/index";
import CreateItem from "./gallary/createItem/createItem";
import AppRegisteredUsers from "./AppRegisterUser/AppRegisterUser";
import PackageList from "./Package/Package";
import PackageConfig from "./PackageConfig";
import DynamicProduct from "./dynamicProduct/DynamicProduct";
import AllSchedules from "./AllSchedules/AllSchedules";
import LoadedSchedules from "./Loaded Schedules/LoadedSchedule";
import ScheduleReport from "./scheduleReport/scheduleReport";

class Client extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Layout>
        <Switch>
          <Redirect
            exact
            from={`${match.url}/`}
            to={`${match.url}/dashboard`}
          />
          <Route path={`${match.url}/dashboard`} exact component={Dashboard} />
          <Route
            path={`${match.url}/lighting surveys`}
            exact
            component={AllSurveys}
          />
          <Route
            path={`${match.url}/emergency lighting schedules`}
            exact
            component={AllSchedules}
          />
          <Route
            path={`${match.url}/Companies Statistic`}
            exact
            component={AllCustomers}
          />
          <Route
            path={`${match.url}/Default Settings`}
            exact
            component={AppRegisteredUsers}
          />
          <Route
            path={`${match.url}/Create Package`}
            exact
            component={PackageList}
          />
          <Route
            path={`${match.url}/Users Statistic`}
            exact
            component={AllUsers}
          />
          <Route
            path={`${match.url}/Companies Profile`}
            exact
            component={companies}
          />
          <Route
            path={`${match.url}/Package config`}
            exact
            component={PackageConfig}
          />
          <Route
            path={`${match.url}/create company`}
            exact
            component={createCompany}
          />
          <Route
            exact
            path={`${match.url}/edit company`}
            component={editCompany}
          />
          <Route
            path={`${match.url}/lighting quotations`}
            exact
            component={QuatationReports}
          />
          <Route
            path={`${match.url}/emergency lighting reports`}
            exact
            component={ScheduleReport}
          />
          <Route
            exact
            path={`${match.url}/create admin`}
            component={CreateAdmin}
          />
          <Route
            exact
            path={`${match.url}/create item`}
            component={CreateItem}
          />
          <Route
            exact
            path={`${match.url}/all items`}
            component={CreateAdmin}
          />
          <Route
            exact
            path={`${match.url}/loaded-surveys/:id?`}
            component={LoadedSurveys}
          />
          <Route
            exact
            path={`${match.url}/loaded-schedules/:id?`}
            component={LoadedSchedules}
          />
          <Route
            exact
            path={`${match.url}/all products`}
            component={DynamicProduct}
          />
          <Redirect to="/404" />
        </Switch>
      </Layout>
    );
  }
}

export default withRouter(Client);
