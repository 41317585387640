export const MyChartConfiguration = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        display: 2,
        ticks: {
          display: true
        },
        gridLines: {
          drawBorder: false
        }
      }
    ],
    xAxes: [
      {
        display: 2,
        ticks: {
          display: true
        },
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: false,
          display: true,
          drawBorder: false
        }
      }
    ]
  },
  layout: {
    padding: { left: 0, right: 0, top: 30, bottom: 0 }
  }
};
