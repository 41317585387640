export const apiUrl = "https://app.ledme.ie";
// export const apiUrl = "https://salty-sands-97933.herokuapp.com";
//  export const apiUrl = "https://6e585a57.ngrok.io";

export const StripePublicKey = "pk_live_T6ykikK5rQwKPzX0Ll3koJBF00HIZ94zTI";

// export const apiUrl = "https://ledme.ie";

export const API = "/api/adminUser";

export const getToken = () => {
  let newToken = localStorage.getItem("access_token");
  return newToken;
};

export const SucessColor = "#155724";
export const ErrorColor = "#721c24";

//salty-sands-97933.herokuapp.com

//https://ledme.ie

//78.46.160.41:8000
