import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import * as Actions from "../../store/Actions/index";
import "./table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faEye } from "@fortawesome/free-regular-svg-icons";
// import { height } from "@material-ui/system";
import { GetFormattedDate } from "../../contants/constantVariable";

class MaterialTableDemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      data: [],
      Data: [],
      DataToDownload: [],
      firstLoad: true,
      pageNumber: 0,
    };
  }

  componentWillReceiveProps(nextData) {
    let { data } = this.state;
    data = nextData.data.data;
    let result = data.map((object, i) => {
      var newObject = Object.assign({}, object);
      newObject.row_id = i;
      if (newObject.created_at) {
        newObject.created_at = GetFormattedDate(newObject.created_at);
      }
      return newObject;
    });
    this.setState({ data: result }, () => {});
  }

  handleRowNanClick = () => {};

  componentDidMount() {
    const { columns } = this.state;
    //eslint-disable-next-line
    this.props.data.Header.map((data) => {
      columns.push(data);
    });
    this.setState({ columns });
  }

  componentWillMount() {
    let data = this.props.data.data;
    let result = data.map((object, i) => {
      var newObject = Object.assign({}, object);
      newObject.row_id = i;
      if (newObject.created_at) {
        newObject.created_at = GetFormattedDate(newObject.created_at);
      }
      return newObject;
    });
    this.setState({ data: result });
  }

  handleClick = (event, rowData) => {
    const { history } = this.props;
    this.props.dispatch(Actions.setLoadedCompanyData(rowData));
    history.push(`loaded-surveys/${rowData.suvey_id}`);
    return true;
  };

  render() {
    const { columns } = this.state;
    if (this.props.showMore) {
      //eslint-disable-next-line
      this.props.data.optionalHeader.map((data) => {
        columns.push(data);
      });
      this.props.HandleshowMore();
      this.props.columnAdded();
    }

    return (
      <MaterialTable
        getAllSurveys={this.props.getAllSurveys}
        title={this.props.TableTitle ? this.props.TableTitle : " "}
        columns={columns}
        data={this.state.data}
        isLoading={this.props.isLoading}
        localization={{
          body: {
            emptyDataSourceMessage: this.props.NoDataMessage,
            editRow: {
              deleteText: this.props.deleteTextMsg
                ? this.props.deleteTextMsg
                : "Are you sure delete this row?",
            },
            // pagination: {
            //   labelDisplayedRows: "{0}-{0} and {0}"
            // },
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
        onRowClick={
          this.props.RowClickable ? this.handleClick : this.handleRowNanClick
        }
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        editable={{
          onRowUpdate: this.props.RowEdit
            ? (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    // resolve();
                    // const data = this.state.data;
                    // let filteredObject = data.filter((object, i) => {
                    //   return object.row_id === oldData.row_id;
                    // });
                    // data[filteredObject[0].row_id] = newData;
                    // this.setState({ data }, () => {
                    // });
                    this.props.handleUpdate(newData);
                    resolve();
                  }, 1000);
                })
            : null,
          onRowDelete: this.props.RowDelete
            ? (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    this.props.handleDelete(oldData);
                    resolve();
                  }, 600);
                })
            : null,
        }}
        actions={
          this.props.actions
            ? [
                this.props.isPdf && {
                  icon: () => (
                    <FontAwesomeIcon
                      style={{ color: "#222222", opacity: 1 }}
                      size="1x"
                      icon={faFilePdf}
                    />
                  ),
                  tooltip: "Download PDF",
                  onClick: (event, rowData) => {
                    this.props.generateReport(rowData);
                  },
                },
                this.props.isView && {
                  icon: () => (
                    <FontAwesomeIcon
                      style={{ color: "#222222", opacity: 1 }}
                      size="1x"
                      icon={faEye}
                    />
                  ),
                  tooltip: "View",
                  onClick: (event, rowData) => {
                    this.props.handleView(rowData);
                  },
                },
              ]
            : []
        }
        options={{
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: "#1AB394",
            color: "#FFF",
          },
          exportButton: this.props.isDownload,
          exportAllData: true,
          exportFileName: this.props.PdfFile,
          pageSizeOptions: [5, 10],
          search: this.props.search,
          toolbar: this.props.toolbar,
        }}
      />
    );
  }
}

export default MaterialTableDemo;
