import React from "react";
import { Col, Row } from "reactstrap";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import TimeIcon from "../../../../assets/icons/time.png";
import DetailedScheduleTable from "../../../../component/DetailedOverviewTable/DetailedScheduleTable";
import { Doughnut } from "react-chartjs-2";
import ChartConfig from "../../../../contants/chartConfig";
import sadEmoji from "../../../../assets/img/sadEmoji.png";

class ScheduleChart extends React.Component {
  render() {
    const data = {
      labels: ["Passed", "Failed", "Incomplete"],
      datasets: [
        {
          data: [
            this.props.scheduleData.passed_schedule,
            this.props.scheduleData.failed_schedule,
            this.props.scheduleData.incomplete_schedule,
          ],
          backgroundColor: [
            ChartConfig.color.darkgreen1,
            ChartConfig.color.darkgreen2,
            ChartConfig.color.darkgreen3,
          ],
          hoverBackgroundColor: [
            ChartConfig.color.darkgreen1,
            ChartConfig.color.darkgreen2,
            ChartConfig.color.darkgreen3,
          ],
        },
      ],
    };

    const options = {
      legend: {
        position: "bottom",
        labels: {
          fontColor: ChartConfig.legendFontColor,
          boxWidth: 10,
        },
      },
    };
    const { passed_schedule, failed_schedule, incomplete_schedule } =
      this.props.scheduleData;
    // console.log("ScheduleData=========", scheduleData);

    return (
      <>
        <Col xs={4} md={4}>
          <div className="col-xl-12">
            <section className="box ">
              <header className="panel_header">
                <img src={surveysIcon} alt="survey" /> ACTIVITY OVERVIEW
              </header>
              <hr />
              <div className="content-body">
                <div className="total-surveys-container">
                  <div>
                    <div className="total-survey-text">Total Schedules</div>
                  </div>
                  <div className="total-survey-number">
                    {this.props.activityData.total_schedules}
                  </div>
                </div>
                <div
                  className="total-surveys-container"
                  style={{ marginTop: "0px" }}
                >
                  <div>
                    <div className="total-survey-text">Total Users</div>
                  </div>
                  <div className="total-survey-number">
                    {this.props.activityData.total_users}
                  </div>
                </div>
              </div>
              <div className="detailed-overview-text">
                <img src={TimeIcon} style={{ marginTop: "-10px" }} alt="time" />{" "}
                Detailed Overview
              </div>
              <div
                style={{
                  marginTop: "3px",
                  marginBottom: "0px",
                  // height: "210px",
                }}
              >
                <DetailedScheduleTable scheduleData={this.props.scheduleData} />
              </div>
            </section>
          </div>
        </Col>
        <Col xs={8} md={8}>
          <div className="Float-Right">
            <div className="col-xl-12">
              <Row>
                <Col xs={12} md={12}>
                  <div className="my-chart">
                    <section className="box">
                      <header
                        style={{ marginLeft: "10px" }}
                        className="panel_header text-uppercase"
                      >
                        <img src={surveysIcon} alt="survey" /> Schedules Pass to
                        Fail Ratio
                      </header>
                      <hr style={{ marginLeft: "10px", marginRight: "10px" }} />
                      <div className="content-body">
                        <div className="mt-3">
                          {passed_schedule === 0 &&
                          failed_schedule === 0 &&
                          incomplete_schedule === 0 ? (
                            <div className="mt-4 text-center">
                              <img src={sadEmoji} alt="empty" />
                              <div
                                style={{
                                  color: "rgb(0, 0, 0)",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                                className="mt-2"
                              >
                                Sorry!
                              </div>
                              <div class="mt-2 mb-5">
                                There are no schedule found.
                              </div>
                            </div>
                          ) : (
                            <Doughnut
                              data={data}
                              options={options}
                              width={675}
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>

              <Row className="widget-for-24">
                <Col xs={4} md={4}>
                  <div style={{ marginRight: "10px" }}>
                    <section
                      className="box widget-left-right-10 text-center"
                      style={{ padding: "13px 0" }}
                    >
                      <div
                        style={{
                          margin: "20px 15px 15px 15px",
                          fontSize: "40px",
                          fontFamily: "roboto-regular",
                          color: "#565656",
                        }}
                      >
                        {`${this.props.scheduleData.failed_schedule}`}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontFamily: "roboto-regular",
                          color: "#000000",
                          marginBottom: "-5px",
                        }}
                      >
                        Failed Schedules
                      </div>
                    </section>
                  </div>
                </Col>

                <Col xs={4} md={4}>
                  <div style={{ marginRight: "10px" }}>
                    <section
                      className="box widget-left-right-10 text-center"
                      style={{ padding: "13px 0" }}
                    >
                      <div
                        style={{
                          margin: "20px 15px 15px 15px",
                          fontSize: "40px",
                          fontFamily: "roboto-regular",
                          color: "#565656",
                        }}
                      >
                        {`${this.props.scheduleData.passed_schedule}`}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontFamily: "roboto-regular",
                          color: "#000000",
                          marginBottom: "-5px",
                        }}
                      >
                        Passed Schedules
                      </div>
                    </section>
                  </div>
                </Col>

                <Col xs={4} md={4}>
                  <div style={{ marginRight: "10px" }}>
                    <section
                      className="box widget-left-right-10 text-center"
                      style={{ padding: "13px 0" }}
                    >
                      <div
                        style={{
                          margin: "20px 15px 15px 15px",
                          fontSize: "40px",
                          fontFamily: "roboto-regular",
                          color: "#565656",
                        }}
                      >
                        {`${this.props.scheduleData.incomplete_schedule}`}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontFamily: "roboto-regular",
                          color: "#000000",
                          marginBottom: "-5px",
                        }}
                      >
                        Incomplete Schedules
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

export default ScheduleChart;
