import React, {  Component } from "react";
// import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import Button from "../../common/button/button";
import Modal from "./model";
import "./serverModal.css";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import {resetServerError} from '../../../store/Actions'

class ServerErrorModal extends Component {
  //eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  handleClose = ()=>{
      this.props.dispatch(resetServerError())
  }

  render() {
    return (
    <div className='server-modal'>
      <Modal isOpen={this.props.ServerError.isModal} style={{ width: 350 }}>
        <div className="server-modal-body">
          <div className="emoji-side">
            <div>
              <FontAwesomeIcon
                size="5x"
                icon={faFrown}
                style={{ color: "#ffffff" }}
              />
              <div className='status-code'>{this.props.ServerError.statusCode}</div>
            </div>
          </div>
          <div className="content-side">
            <div className="content-box">Sorry, we could not reach the Ledme service.</div>
            <div>
              <button
                full={true}
                style={{ backgroundColor: "red" }}
                onClick={this.handleClose}
                className="server-modal-btn"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ServerError}) => {
  return {
      ServerError
  };
};

export default connect(mapStateToProps)(ServerErrorModal);
