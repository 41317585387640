import React from "react";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import Button from "../button/button";
class TextInputwithbutton extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    

    return (
      <InputGroup
        style={this.props.style}
        className={`topbar-search ${this.props.search ? "open" : "open"} ${
          this.props.hasError ? "error" : "noerror"
        } `}
      >
        {this.props.isLoading ? (
          <div
            className="loader-container"
            style={{ height: "100%", width: "100%" }}
          >
            <div
              className="spinner-border spinner-border-sm text-secondary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}

        <InputGroupAddon addonType="append">
          {this.props.children}
        </InputGroupAddon>
        <Input
          type={this.props.type}
          pattern={this.props.pattern}
          ref={this.props.ref}
          disabled={this.props.disabled}
          value={this.props.value}
          name={this.props.name}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          id={this.props.id}
        />
        {this.props.endIcon && (
          <InputGroupAddon addonType="append">
            {this.props.endIcon}
          </InputGroupAddon>
        )}
        {this.props.button ? (
          <Button
            style={{ opacity: this.props.btnDisable ? 0.5 : 1 }}
            disabled={this.props.btnDisable}
            autobutton={true}
            onSubmit={this.handleSubmit}
            onClick={this.props.onButtonClick}
          >
            {this.props.btnLable}
          </Button>
        ) : null}
      </InputGroup>
    );
  }
}

export default TextInputwithbutton;
