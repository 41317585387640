import * as ActionTypes from "../Action-types/index";

const initialstate = {
  isModal: false,
};

const Auth = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SET_PLAN_EXPIRE_MODAL:
      return setServerError(state, payload);
    default:
      return state;
  }
};

const setServerError = (state, payload) => {
  state = Object.assign({
    isModal: payload.isModal,
  });
  return state;
};

//eslint-disable-next-line
const resetServerError = (state, payload) => {
  state = Object.assign({ isModal: false });
  return state;
};

export default Auth;
