import React from "react";
import { Row, Col, CustomInput } from "reactstrap";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextInput/textinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faUser, faBox } from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import api from "../../../../apiServices/index";
import VideoTutorial from "./VideoTutorial";
import ContactUs from "./ContactUs";
import VideoTutorialTable from "./VideoTutorialTable";
import "./AppRegisterUser.css";

class CreateAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credential: {
        company_id: null,
        config_id: null,
        default_plan_id: null,
        app_registration: false,
        user_guide_link: "",
        faq_link: "",
      },
      isLoading: false,
      apiSuccess: false,
      apiError: false,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    let { credential } = this.state;
    const { value, name } = e.target;
    credential[name] = value;
    this.setState({ credential, apiError: false, apiSuccess: false });
  };

  componentWillMount() {
    // this.state.addField.map((item) => {
    //   if (item.title === "" && item.video_link === "") {
    //     this.setState({ isError: true });
    //   }
    //   return null;
    // });

    this.getCompanyId();
  }

  getCompanyId = () => {
    let { credential } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/getConfig")
      .then((response) => {
        const { data, status } = response;
        credential.company_id =
          data.data[0] &&
          data.data[0].amature_company_id &&
          data.data[0].amature_company_id;
        credential.config_id = data.data[0] && data.data[0]._id;
        credential.default_plan_id =
          data.data[0] &&
          data.data[0].default_plan_id &&
          data.data[0].default_plan_id;
        credential.app_registration =
          data.data[0] &&
          data.data[0].app_registration &&
          data.data[0].app_registration;
        credential.user_guide_link =
          data.data[0] &&
          data.data[0].user_guide_link &&
          data.data[0].user_guide_link;
        credential.faq_link =
          data.data[0] && data.data[0].faq_link && data.data[0].faq_link;
        credential.android_registration =
          data.data[0] &&
          data.data[0].android_registration &&
          data.data[0].android_registration;
        credential.android_registration_link =
          data.data[0] &&
          data.data[0].android_registration_link &&
          data.data[0].android_registration_link;

        if (status === 200) {
          this.setState({ isLoading: false, credential });
        }
      })
      .catch((error) => {
        const data = error && error.response && error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  handleSubmit = () => {
    this.setState({ apiError: false, apiSuccess: false, isLoading: true });
    //eslint-disable-next-line
    let { credential } = this.state;

    api
      .putWithToken(`/updateConfig/${this.state.credential.config_id}`, {
        amature_company_id: this.state.credential.company_id,
        default_plan_id: this.state.credential.default_plan_id,
        app_registration: this.state.credential.app_registration,
        user_guide_link: this.state.credential.user_guide_link,
        faq_link: this.state.credential.faq_link,
        android_registration: this.state.credential.android_registration,
        android_registration_link:
          this.state.credential.android_registration_link,
      })
      .then((response) => {
        //eslint-disable-next-line
        const { data, status } = response;

        if (status === 200) {
          this.setState({ apiSuccess: true, isLoading: false });
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={6} md={6}>
              <div className="col-xl-12">
                {this.state.isLoading ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "95%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    Default Settings
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleFormSubmit}>
                        <Row style={{ marginTop: "10px" }}>
                          <Col>
                            <div>Default Company ID</div>
                            <div>
                              <TextInput
                                placeholder={"Enter company ID"}
                                search={false}
                                onChange={this.handleChange}
                                value={this.state.credential.company_id}
                                name="company_id"
                              >
                                <FontAwesomeIcon
                                  onClick={this.handleEdit}
                                  size="1x"
                                  icon={faBuilding}
                                />
                              </TextInput>
                            </div>
                          </Col>
                          <Col>
                            <div>Default Package ID</div>
                            <div>
                              <TextInput
                                placeholder={"Enter Package ID"}
                                search={false}
                                onChange={this.handleChange}
                                value={this.state.credential.default_plan_id}
                                name="default_plan_id"
                              >
                                <FontAwesomeIcon
                                  onClick={this.handleEdit}
                                  size="1x"
                                  icon={faBox}
                                />
                              </TextInput>
                            </div>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col>
                            <div>User Guide Link</div>
                            <div>
                              <TextInput
                                placeholder={"Enter company ID"}
                                search={false}
                                onChange={this.handleChange}
                                value={this.state.credential.user_guide_link}
                                name="user_guide_link"
                              ></TextInput>
                            </div>
                          </Col>
                          <Col>
                            <div>FAQ Link</div>
                            <div>
                              <TextInput
                                placeholder={"Enter Package ID"}
                                search={false}
                                onChange={this.handleChange}
                                value={this.state.credential.faq_link}
                                name="faq_link"
                              ></TextInput>
                            </div>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col>
                            <div>Android App Registration</div>
                            <div
                              style={{
                                paddingLeft: "130px",
                                paddingTop: "25px",
                              }}
                            >
                              <CustomInput
                                type="switch"
                                id="exampleCustomSwitch"
                                name="app_registration"
                                checked={
                                  this.state.credential.android_registration
                                }
                                onChange={() => {
                                  this.setState({
                                    credential: {
                                      app_registration:
                                        this.state.credential.app_registration,
                                      amature_company_id:
                                        this.state.credential.company_id,
                                      default_plan_id:
                                        this.state.credential.default_plan_id,
                                      config_id:
                                        this.state.credential.config_id,
                                      company_id:
                                        this.state.credential.company_id,
                                      user_guide_link:
                                        this.state.credential.user_guide_link,
                                      faq_link: this.state.credential.faq_link,
                                      android_registration:
                                        !this.state.credential
                                          .android_registration,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div>Android App Link</div>
                            <div>
                              <TextInput
                                placeholder={"Enter android app link"}
                                search={false}
                                onChange={this.handleChange}
                                value={
                                  this.state.credential
                                    .android_registration_link
                                }
                                name="android_registration_link"
                              ></TextInput>
                            </div>
                          </Col>
                          <Col>
                            <div>IOS App Registration</div>
                            <div
                              style={{
                                paddingLeft: "130px",
                                paddingTop: "25px",
                              }}
                            >
                              <CustomInput
                                type="switch"
                                id="exampleCustomSwitch"
                                name="app_registration"
                                checked={this.state.credential.app_registration}
                                onChange={() => {
                                  this.setState({
                                    credential: {
                                      app_registration:
                                        !this.state.credential.app_registration,
                                      amature_company_id:
                                        this.state.credential.company_id,
                                      default_plan_id:
                                        this.state.credential.default_plan_id,
                                      config_id:
                                        this.state.credential.config_id,
                                      company_id:
                                        this.state.credential.company_id,
                                      user_guide_link:
                                        this.state.credential.user_guide_link,
                                      faq_link: this.state.credential.faq_link,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "14px", marginBottom: "5px" }}
                          >
                            IOS App Registration
                          </div>
                        </div> */}

                        <div
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            textAlign: "left",
                          }}
                        >
                          <Button
                            disabled={
                              this.state.credential.company_id === "" ||
                              this.state.credential.default_plan_id === "" ||
                              this.state.credential.user_guide_link === "" ||
                              this.state.credential.faq_link === ""
                            }
                            onClick={this.handleSubmit}
                            full={true}
                          >
                            UPDATE
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <p
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.apiError && (
                      <div className="error-message-box">
                        {this.state.apiErrorData.error}
                      </div>
                    )}
                  </p>
                  <p
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.apiSuccess && (
                      <div className="success-message-box">
                        Default settings updated successfully.
                      </div>
                    )}
                  </p>
                </section>
              </div>
            </Col>
            <Col xs={6} md={6}>
              <VideoTutorial />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              <ContactUs />
            </Col>
            <Col xs={6} md={6}>
              <VideoTutorialTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateAdmin;
