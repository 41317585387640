import React from "react";
import "./textinput.css";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";

class TextInput extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ marginLeft: "-10px" }}
        className="topbar-search-form"
      >
        <InputGroup
          className={`topbar-search ${this.props.search ? "open" : "open"}  ${
            this.props.hasError ? "error" : "noerror"
          }`}
        >
          <InputGroupAddon addonType="append">
            {this.props.icon ? (
              <img
                src={this.props.icon}
                style={{ height: "15px", width: "15px" }}
                alt="icon"
              ></img>
            ) : null}
            {this.props.children}
          </InputGroupAddon>
          <Input
            disabled={this.props.disabled}
            type={this.props.type}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            name={this.props.name}
            accept={this.props.accept}
            style={this.props.style}
          />
        </InputGroup>
      </form>
    );
  }
}

export default TextInput;
