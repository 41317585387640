import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  AdminTypeString,
  SuperAdminString
} from "../contants/constantVariable";

class Main extends Component {
  render() {
    let userType = localStorage.getItem(AdminTypeString);
    let path = userType === SuperAdminString ? "/super_admin" : "/admin";
    return <Redirect to={path} />;
  }
}

export default Main;
