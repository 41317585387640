import React from "react";

class PackageLoader extends React.Component {
  render() {
    const { isLoading } = this.props;

    return isLoading ? (
      <div
        className="loader-container"
        style={{ height: "100%", width: "95%" }}
      >
        <div className="spinner-border text-secondary Loader" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : null;
  }
} 

export default PackageLoader