import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/zest-admin.css";
import "./assets/fonts/simple-line-icons.css";
import TermsAndCondition from "./views/termsandcondition/terms";
import { PageNotFound } from "./views/404/404";
import Login from "./views/auth/login/login.js";
import client from "./views/user_type/client/index";
import varifyEmail from "./views/auth/varifyEmail/varifyPassword.js";
import resetpassword from "./views/auth/reset-password/resetpassword";
import main from "./views/index";
import Admin from "./views/user_type/Admin/index";
import AdminProfile from "./views/auth/firstTimeAdminLogin/firstTimeAdminLogin";
import VerifyAdmin from "./views/auth/verifyAdmin/verifyAdmin";
import EmailResetSuccess from "./views/auth/Emailvarifysuccess/emailVerifySuccess";
import VerifyAdminLink from "./views/auth/verifyAdminLinkSent/verifyAdminLinkSent";
import RegisterProfessional from "./views/auth/registerAsProfessional/registerAsProfessional";
import ServerErrorModal from "./component/common/model/serverErrorModal";
import ForgotPassword from "./views/auth/forgot-password/forgot-password";
// import PlanExpireModal from './component/common/model/PlanExpireModal'
import {
  AdminAuthRoute,
  SuperAdminAuthRoute,
} from "./contants/authRoute/authRoute";
import { io } from "socket.io-client";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { apiUrl } from "./contants/defaultvalues";
import OneSignal from "react-onesignal";

let socket = io(apiUrl, {
  transports: ["websocket", "polling", "flashsocket"],
});

function notifyMe(details) {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    var options = {
      icon: "https://my.ledme.ie/static/media/newlogo.eeec6dae.png",
      body: details.message,
      dir: "ltr",
    };
    new Notification(details.title + "\n", options);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission(function (permission) {
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
      if (permission === "granted") {
        var options = {
          icon: "https://getcontentapp.s3.eu-central-1.wasabisys.com/notification-icon-Vector.png",
          body: details.message,
          dir: "ltr",
        };
        new Notification(details.title + "\n", options);
      }
    });
  }
}

socket.on("sendNotification", (details) => {
  notifyMe(details);
});

class App extends Component {
  componentDidMount() {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SINGAL_APP_ID,
    });
  }

  render() {
    return (
      <div className="h-100">
        <ServerErrorModal />
        <React.Fragment>
          <Router>
            <Switch>
              <Route path="/" exact component={main} />
              <SuperAdminAuthRoute path="/super_admin" component={client} />
              <AdminAuthRoute path="/admin" component={Admin} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/verify-email" component={varifyEmail} />
              <Route exact path="/admin-profile" component={AdminProfile} />
              <Route
                exact
                path="/register-professional"
                component={RegisterProfessional}
              />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route
                exact
                path="/verify-admin:token?"
                component={VerifyAdmin}
              />
              <Route
                exact
                path="/reset-password:token?"
                component={resetpassword}
              />
              <Route
                exact
                path="/email-reset-success"
                component={EmailResetSuccess}
              />
              <Route
                exact
                path="/verify-link-admin"
                component={VerifyAdminLink}
              />
              <Route
                path="/terms-and-condition"
                exact
                component={TermsAndCondition}
              />
              <Route path="/404" exact component={PageNotFound} />
              <Redirect to="/404" />
            </Switch>
          </Router>
        </React.Fragment>
      </div>
    );
  }
}

export default App;
