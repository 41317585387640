import React from "react";
import { Row, Col } from "reactstrap";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import TextInput from "../../../../component/common/TextInput/textinput";

class CreateAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <img src={surveysIcon} alt="survey" /> Create Admin
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px"
                      }}
                    >
                      <form>
                        <TextInput
                          search={false}
                          icon={surveysIcon}
                          placeholder=""
                          height=""
                          width=""
                        />
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateAdmin;
