import React from "react";
import Button from "../../component/common/button/button";
import { connect } from "react-redux";
import { toggleQuotationLoader } from "../../store/Actions";
import api from "../../apiServices/index";
//eslint-disable-next-line
import { data } from "currency-codes";
// import ErrorModal from "../../component/common/model/ErrorModal"
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

class ChangeQuotationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: this.props.label,
      ErrorMessage: "",
    };
  }

  startLoader = () => {
    this.props.dispatch(toggleQuotationLoader(true));
  };

  stopLoader = () => {
    this.props.dispatch(toggleQuotationLoader(false));
  };

  toastMeassages = (variant = "error", message) => (
    <p style={{ marginTop: "20px" }}>
      <div
        className={
          variant === "success" ? "success-message-box" : "error-message-box"
        }
      >
        {message}
      </div>
    </p>
  );

  ErrorMessage = (message) => (
    <p style={{ marginTop: "20px" }}>
      <div className="error-message-box">{message}</div>
    </p>
  );

  handleUpdate = () => {
    const data = this.props.data;

    let body = {
      is_report_generated: this.state.label ? false : true,
    };

    this.startLoader();
    // this.setState({ isLoading: true });
    if (data.scheduleFitting !== 0) {
      api
        .putWithToken(`/schedule/${data.suvey_id}`, body)
        .then((response) => {
          if (response.status === 200) {
            if (this.state.label === false) {
              this.setState({ isLoading: false, label: true }, () => {
                api
                  .getWithToken(`/schedule/report/${data.suvey_id}`)
                  .then((response) => {
                    this.stopLoader();
                  })
                  .catch((error) => {
                    const err =
                      error &&
                      error.response &&
                      error.response.data &&
                      error.response.data.message;
                    NotificationManager.error(err, "", 1000);
                    this.stopLoader();
                  });
              });
            } else {
              this.setState({ label: false });
              this.stopLoader();
            }
          }
        })
        .catch((error) => {
          this.stopLoader();
          this.setState({ isLoading: false });
        });
    } else {
      this.stopLoader();
      // this.setState({ ErrorMessage: "Please Enter New Survey Data" });
    }
  };

  render() {
    return (
      <div className="d-flex flex-row">
        <div style={{ width: 150 }}>
          <Button
            onClick={this.handleUpdate}
            // disabled={this.state.label}
            small
          >
            {this.state.label ? "YES" : "NO"}
          </Button>
        </div>
        <div>
          {this.state.label ? (
            <div>
              Quotation E-Mail has been sent to {this.props.data.email}.
            </div>
          ) : (
            <div>No Report Generated , Please Generate Report.</div>
          )}
          {this.state.ErrorMessage !== "" ? (
            <div style={{ color: "red" }}>{this.state.ErrorMessage}</div>
          ) : null}
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(ChangeQuotationStatus);
