import React from "react";
import { InputGroup, Input } from "reactstrap";

class TextField extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <InputGroup
        className={`topbar-search ${this.props.search ? "open" : "open"}  ${
          this.props.hasError ? "error" : "noerror"
        }`}
      >
        <Input
          disabled={this.props.disabled}
          type={this.props.type}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          name={this.props.name}
        />
      </InputGroup>
    );
  }
}

export default TextField;
