import * as ActionTypes from "../Action-types/index";

const initialstate = {};

const LoadedCompanyData = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SET_LOADED_COMPANY_DATA:
      return setLoadedCompanyData(state, payload);
    case ActionTypes.RESET_LOADED_COMPANY_DATA:
      return resetLoadedCompanyData(state, payload);
    default:
      return state;
  }
};

const setLoadedCompanyData = (state, payload) => {
  state = Object.assign({ ...payload });
  return state;
};

const resetLoadedCompanyData = (state, payload) => {
  state = Object.assign({});
  return state;
};

export default LoadedCompanyData;
