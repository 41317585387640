import React from "react";
import { Row, Col } from "reactstrap";
import { CustomersData } from "../../../../contants/CustomersData/CustomersData";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../apiServices/index";

class AllCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: CustomersData,
      isLoading: true,
    };
  }

  componentWillMount() {
    let { data } = this.state;
    api
      .getWithToken("/dashboard/all/companies")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          data.data = responseData;
          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box " style={{ minHeight: "68vh" }}>
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <FontAwesomeIcon
                            style={{ marginRight: "2px" }}
                            size="1x"
                            icon={faUser}
                          />{" "}
                          COMPANIES STATISTIC
                        </header>
                      }
                      NoDataMessage={"No companies data found"}
                      RowEdit={false}
                      RowDelete={false}
                      data={this.state.data}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AllCustomers;
