import React from "react";
import { Line } from "react-chartjs-2";

import { MyChartConfiguration } from "./chartdata";

const chartColor = "#FFFFFF";

class Chartjsline extends React.Component {
  //eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    let mydashboardShippedProductsChart = {
      data: canvas => {
        var ctx = canvas.getContext("2d");
        var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, "rgba(26,179,148,0.5)");
        gradientStroke.addColorStop(1, chartColor);
        var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
        gradientFill.addColorStop(0, "rgba(26,179,148, 0.5)");
        gradientFill.addColorStop(1, "rgba(26,179,148, 0.5)");
        return {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec"
          ],
          datasets: [
            {
              label: "",
              borderColor: "#25B9D7",
              pointBorderColor: "#fff",
              pointBackgroundColor: "#25B9D7",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              fill: true,
              backgroundColor: gradientFill,
              borderWidth: 2,
              data: this.props.chartData
            }
          ]
        };
      },
      options: MyChartConfiguration
    };
    return (
      <div className="row">
        <div className="col-lg-12">
          <div
            className="chart-area"
            style={{
              height: 270 + "px",
              marginLeft: "5px",
              marginBottom: "-5px"
            }}
          >
            <Line
              data={mydashboardShippedProductsChart.data}
              options={mydashboardShippedProductsChart.options}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Chartjsline;
