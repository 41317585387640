import React from "react";
import "./header.css";
import { Navbar, Container } from "reactstrap";

class TransparentHeader extends React.Component {
  render() {
    return (
      <Navbar expand="lg" className={"navbar-absolute-trans fixed-top"}>
        <Container fluid>
          <hr />
          <div className="navbar-wrapper">
            <div className="header-sc-1">
              <div className="header-sc-1-1"></div>
            </div>
          </div>
        </Container>
      </Navbar>
    );
  }
}
export default TransparentHeader;
