import React from "react";
import { Row, Col } from "reactstrap";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextInput/textinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLock,
  faBuilding,
  faUser,
  faEyeSlash,
  faEye,
  faCarBattery,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import countryList from "react-select-country-list";
import MaterialTable from "material-table";
import getSymbolFromCurrency from "currency-symbol-map";
import LocaleCurrency from "locale-currency";
import ReactCountryFlag from "react-country-flag";
import {Input} from "reactstrap";

import api from "../../../../apiServices/index";
import TextInputWithButton from "../../../../component/common/textinputwithbutton/textinputwithbutton";

class CreateAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.options = countryList().getData();
    this.state = {
      credential: {
        username: "",
        company_name: "",
        password: "",
        confirmPassword: "",
      },
      UsernameLoader: false,
      PasswordLoader: false,
      isLoading: false,
      apiSuccess: false,
      apiError: false,
      showPassword: false,

      LoaderForMultipleUser: false,
      options: this.options,
      countryVal: null,
      country: "",
      region: "",
      currency_symbol: "",
      kwh_rate: "",

      page: 0,
      countryArr: [],
      columns: [
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Currency Symbol",
          field: "currency_symbol",
        },
        {
          title: "Flag",
          field: "flag",
          render: (item) => (
            <img src={item.flag} alt="" border="3" height="50" width="50" />
          ),
        },
        {
          title: "KWH Rate",
          field: "kwh_rate",
        },
      ],
    };
    this.flagsRef = React.createRef();
  }

  getCountries = () => {
    api
      .getWithToken("/country")
      .then((res) => {
        var country = {};
        var countryArr = [];
        const response = res && res.data && res.data.data;
        // res && res.data && res.data.data[0] && res.data.data[0].country;

        //eslint-disable-next-line
        response.map((val) => {
          country = {
            name: val.name,
            currency_symbol: val.currency_symbol,
            flag: val.flag,
            kwh_rate: val.kwh_rate,
            id: val.country_id,
          };
          countryArr.push(country);
        });

        this.setState({ data: response, countryArr: countryArr });
      })
      .catch((err) => {
        console.log("--err---");
      });
  };

  componentDidMount() {
    this.getCountries();
  }

  changeHandler = (countryVal) => {
    {
      const [sample] = this.state.options.filter(
        (country) => country.value === countryVal.target.value
      );
      this.setState({
        country: sample.label,
        countryVal: countryVal.target.value,
        apiErrorC: false,
        apiSuccessC: false,
      });
    }
    // this.setState({ countryVal: countryVal.target.value });
    const CurrencyCode = LocaleCurrency.getCurrency(countryVal.target.value);
    const symbol = getSymbolFromCurrency(CurrencyCode);
    this.setState({ currency_symbol: symbol });
  };
  handleKWHRate = (e) => {
    this.setState({
      kwh_rate: e.target.value,
      apiErrorC: false,
      apiSuccessC: false,
    });
  };

  handleCountryChange = () => {
    this.setState({
      apiErrorC: false,
      apiSuccessC: false,
      LoaderForMultipleUser: true,
    });
    //eslint-disable-next-line
    var country = [
      {
        name: this.state.country,
        currency_symbol: this.state.currency_symbol,
        kwh_rate: this.state.kwh_rate,
      },
    ];
    const data = {
      name: this.state.country,
      currency_symbol: this.state.currency_symbol,
      kwh_rate: this.state.kwh_rate,
    };

    api
      .postWithToken("/country/add", data)
      .then((res) => {
        const { status } = res;
        this.setState({
          countryVal: "",
          currency_symbol: "",
          kwh_rate: "",
        });
        if (status === 200) {
          this.setState({ apiSuccessC: true, LoaderForMultipleUser: false });
        }

        this.getCountries();
      })
      .catch((err) => {
        const data = err.response.data;
        this.setState({
          apiErrorC: true,
          //eslint-disable-next-line
          apiErrorC: data,
          LoaderForMultipleUser: false,
        });
      });
  };

  handleDelete = (data) => {
    api
      .deleteWithToken(`/country/${data.id}`)
      .then((res) => {
        this.getCountries();
      })
      .catch((err) => {
        //eslint-disable-next-line
        const data = err.response.data;
      });

    // api.deleteWithToken(`/country/${data._id}`)
  };

  handleChangeCount = (e) => {
    let { noOfUsers } = this.state;
    const { value, name } = e.target;
    noOfUsers[name] = value;
    this.setState({ noOfUsers, apiSuccessM: false, userCountError: false });
  };

  handleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    let { credential } = this.state;
    const { value, name } = e.target;
    credential[name] = value;
    this.setState({ credential, apiError: false, apiSuccess: false });
  };

  HandleAutoGenerate = (field) => {
    let { credential } = this.state;
    if (field === "username") {
      this.setState({ UsernameLoader: true });
    } else {
      this.setState({ PasswordLoader: true });
    }

    api
      .getParamsWithToken("/random/generator", {
        password_required: field === "username",
        username_required: field === "password",
        prefix: "user",
        count: "1",
      })
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          credential[field] = data.data[0][field];
          this.setState({
            credential,
            UsernameLoader: false,
            PasswordLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ UsernameLoader: false, PasswordLoader: false });
      });
  };

  handleSubmit = () => {
    let { credential } = this.state;
    this.setState({ apiError: false, apiSuccess: false, isLoading: true });
    credential.username = credential.username.trim();
    credential.company_name = credential.company_name.trim();
    credential.confirmPassword = credential.password;

    api
      .postWithToken("", credential)
      .then((response) => {
        //eslint-disable-next-line
        const { data, status } = response;
        if (status === 200) {
          this.setState({ apiSuccess: true, isLoading: false });
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={4} md={4}>
              <div className="col-xl-12">
                {this.state.isLoading ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "95%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    Create company
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleFormSubmit}>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "10px", marginBottom: "5px" }}
                          >
                            Company Name
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <TextInput
                              placeholder={"Ex: Philips Lighting"}
                              search={false}
                              onChange={this.handleChange}
                              value={this.state.credential.company_name}
                              name="company_name"
                            >
                              <FontAwesomeIcon
                                onClick={this.handleEdit}
                                size="1x"
                                icon={faBuilding}
                              />
                            </TextInput>
                          </div>
                        </div>
                        <Row>
                          <Col md={12} xs={12}>
                            <div
                              style={{ marginTop: "10px", marginRight: "0px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                User Name
                              </div>
                              <div>
                                <TextInputWithButton
                                  placeholder={"Ex: Philips_5456"}
                                  isLoading={this.state.UsernameLoader}
                                  search={false}
                                  onChange={this.handleChange}
                                  value={this.state.credential.username}
                                  name="username"
                                  button={true}
                                  btnLable="Auto Generate"
                                  onButtonClick={() => {
                                    this.HandleAutoGenerate("username", true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faUser}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={12} xs={12}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Password
                              </div>
                              <div>
                                <TextInputWithButton
                                  placeholder={"Ex: 9646@Philips"}
                                  isLoading={this.state.PasswordLoader}
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  button={true}
                                  search={false}
                                  onChange={this.handleChange}
                                  value={this.state.credential.password}
                                  btnLable="Auto Generate"
                                  name="password"
                                  onButtonClick={() => {
                                    this.HandleAutoGenerate("password");
                                  }}
                                >
                                  {this.state.showPassword && (
                                    <FontAwesomeIcon
                                      onClick={this.handleShowPassword}
                                      size="1x"
                                      icon={faEye}
                                    />
                                  )}
                                  {!this.state.showPassword && (
                                    <FontAwesomeIcon
                                      onClick={this.handleShowPassword}
                                      size="1x"
                                      icon={faEyeSlash}
                                    />
                                  )}
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            textAlign: "left",
                          }}
                        >
                          <Button
                            disabled={
                              this.state.credential.username === "" ||
                              this.state.credential.password === "" ||
                              this.state.credential.company_name === ""
                            }
                            onClick={this.handleSubmit}
                            full={true}
                          >
                            CREATE
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <p
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.apiError && (
                      <div className="error-message-box">
                        {this.state.apiErrorData.code === 2
                          ? this.state.apiErrorData.error
                          : this.state.apiErrorData.data}
                      </div>
                    )}
                  </p>
                  <p style={{ marginTop: "20px" }}>
                    {this.state.apiSuccess && (
                      <div className="success-message-box">
                        Company created successfully.
                      </div>
                    )}
                  </p>
                </section>
              </div>
            </Col>

            <Col xs={8} md={8}>
              <div className="col-xl-12">
                {this.state.LoaderForMultipleUser ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "98%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faFlag}
                    />{" "}
                    Add Country
                  </header>
                  <hr />
                  <div
                    className="content-body"
                    style={{ marginBottom: "20px" }}
                  >
                    <div
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleFormSubmit}>
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                margin: "10px 0px 5px 10px",
                              }}
                            >
                              Country Name
                            </div>
                            <div
                              style={{
                                margin: "10px 0px 0px 10px",
                                paddingRight: "5px",
                              }}
                            >
                              <Input
                                type="select"
                                id="country"
                                name="options"
                                style={{ width: 180 }}
                                onChange={(e) => this.changeHandler(e)}
                                value={this.state.countryVal}
                              >
                                {this.state.options &&
                                  this.state.options.map((country, key) => {
                                    return (
                                      <option key={key} value={country.value}>
                                        {country.label}
                                      </option>
                                    );
                                  })}

                                {/* <CountryDropdown
                                // type="text"
                                search={false}
                                onChange={this.handleChangeCount}
                                value={this.state.noOfUsers.count}
                                name="count"
                              > */}
                                <FontAwesomeIcon
                                  onClick={this.handleEdit}
                                  size="1x"
                                  icon={faFlag}
                                />
                              </Input>
                              {/* </CountryDropdown> */}
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                margin: "10px 0px 5px 10px",
                              }}
                            >
                              Currency Symbol
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                paddingRight: "5px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextInput
                                type="text"
                                search={false}
                                onChange={this.handleChangeCount}
                                value={this.state.currency_symbol}
                                disabled={true}
                                name="count"
                              >
                                <FontAwesomeIcon
                                  onClick={this.handleEdit}
                                  size="1x"
                                  icon={faFlag}
                                />
                              </TextInput>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                margin: "10px 0px 5px 10px",
                              }}
                            >
                              Kwh Rate
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                paddingRight: "5px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextInput
                                type="number"
                                onChange={this.handleKWHRate}
                                value={this.state.kwh_rate}
                                search={false}
                                name="count"
                              >
                                <FontAwesomeIcon
                                  onClick={this.handleEdit}
                                  size="1x"
                                  icon={faCarBattery}
                                />
                              </TextInput>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                margin: "10px 0px 5px 10px",
                              }}
                            >
                              Coutry Flag
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                paddingRight: "5px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <ReactCountryFlag
                                countryCode={
                                  this.state.countryVal
                                    ? this.state.countryVal
                                    : "AF"
                                }
                                style={{
                                  width: 80,
                                  height: 40,
                                }}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                                title={
                                  this.state.countryVal
                                    ? this.state.countryVal
                                    : "AF"
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                          <Button
                            onClick={this.handleCountryChange}
                            full={true}
                            disabled={
                              this.state.countryVal === "" ||
                              this.state.kwh_rate === "" ||
                              this.state.currency_symbol === ""
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </form>

                      <p style={{ marginTop: "20px" }}>
                        {this.state.apiSuccessC && (
                          <div className="success-message-box">
                            Country added successfully.
                          </div>
                        )}
                      </p>

                      <p
                        style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        {this.state.apiErrorC && (
                          <div className="error-message-box">
                            {this.state.apiErrorC.error}
                          </div>
                        )}
                      </p>

                      {/* <p
                        style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        {this.state.userCountError && (
                          <div className="error-message-box">
                            Minimum 2 And Maximum 200 Users Are Creatable.
                          </div>
                        )}
                      </p> */}
                    </div>
                  </div>
                </section>
              </div>

              <div className="col-xl-12 mt-4">
                {this.state.LoaderForMultipleUser ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "98%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faFlag}
                    />{" "}
                    LIST OF COUNTRIES AVERAGE KWH RATE
                  </header>
                  <hr />
                  <div
                    className="content-body custom-pagination"
                    style={{ marginBottom: "20px" }}
                  >
                    <MaterialTable
                      editable={{
                        onRowDelete: this.props.RowDelete
                          ? (oldData) =>
                              new Promise((resolve) => {
                                setTimeout(() => {
                                  this.props.handleDelete(oldData);
                                  resolve();
                                }, 600);
                              })
                          : null,
                      }}
                      title={
                        <header className="panel_header text-uppercase">
                          ALL COUNTRY
                        </header>
                      }
                      page={2}
                      localization={{
                        body: {
                          emptyDataSourceMessage:
                            "Users have not  any country  yet",
                          editRow: {
                            deleteText: this.props.deleteTextMsg
                              ? this.props.deleteTextMsg
                              : "Are you sure delete this country?",
                          },

                          filterRow: {
                            filterTooltip: "Filter",
                          },
                        },
                      }}
                      //eslint-disable-next-line
                      editable={{
                        onRowDelete: (oldData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              this.handleDelete(oldData);
                              resolve();
                            }, 600);
                          }),
                      }}
                      RowDelete={true}
                      columns={this.state.columns}
                      data={this.state.countryArr}
                      options={{
                        headerStyle: {
                          backgroundColor: "#1AB394",
                          color: "#FFF",
                        },
                      }}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateAdmin;
