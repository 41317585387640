/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col } from "reactstrap";
import "../login/login.css";
import Logo from "../../../assets/img/newlogo.png";
// import api from "../../../apiServices/index";

// import ReeValidate from "ree-validate";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLightbulb,
//   faTimesCircle,
//   faCheckCircle
// } from "@fortawesome/free-regular-svg-icons";
import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    {this.state.isLoading ? (
                      <div className="loader-container">
                        <div
                          className="spinner-border text-secondary LoginpageLoader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : null}

                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>
                    <form method="POST">
                      <p>
                        <div
                          style={{
                            color: "black",
                            textAlign: "center",
                            fontSize: "14px",
                            fontFamily: "roboto-regular",
                            opacity: "0.5",
                          }}
                        >
                          Via
                        </div>
                      </p>
                      <p style={{ marginTop: 20 }}>
                        <label>
                          <input
                            style={{ fontFamily: "roboto-regular" }}
                            type="button"
                            className="btn btn-accent btn-block"
                            value="E-Mail"
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          <input
                            style={{ fontFamily: "roboto-regular" }}
                            type="button"
                            className="btn btn-accent btn-block"
                            value="Mobile number"
                          />
                        </label>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
