import React from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { toggleQuotationLoader } from "../../../../store/Actions";
import api from "../../../../apiServices/index";
import {
  AdminString,
  AdminTypeString,
  SuperAdminString,
} from "../../../../contants/constantVariable";
import { getScheduleReportsData } from "../../../../contants/ScheduleReportsData/ScheduleReportsData";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import ErrorModal from "../../../../component/common/model/ErrorModal";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";

class ScheduleReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      isErrorModal: false,
    };
  }

  startLoader = () => {
    this.props.dispatch(toggleQuotationLoader(true));
  };

  stopLoader = () => {
    this.props.dispatch(toggleQuotationLoader(false));
  };

  componentWillMount() {
    const ScheduleReportData = getScheduleReportsData();
    this.setState({ data: ScheduleReportData }, () => {
      this.getAllSchedules();
    });
  }

  handleUpdate = (data) => {
    if (data.is_report_generated === "true") {
      let body = {
        is_report_generated: data.is_report_generated,
      };

      this.startLoader();

      api
        .putWithToken(`/schedule/${data.schedule_id}`, body)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ isLoading: false }, () => {
              api
                .getWithToken(`/schedule/report/${data.schedule_id}`)
                .then((res) => {
                  this.stopLoader();
                })
                .catch((error) => {
                  this.stopLoader();
                });
              this.getAllSchedules();
            });
          }
        })
        .catch((error) => {
          this.stopLoader();
          this.setState({ isLoading: false });
        });
    }
  };

  generateReport = (data) => {
    this.startLoader();
    api
      .getWithTokenInBlobRes(`/schedule/report/${data.suvey_id}`)
      .then((res) => {
        const file = new Blob([res.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        this.stopLoader();
        window.open(fileURL);
      })
      .catch((error) => {
        this.setState({ isErrorModal: true });
        this.stopLoader();
      });
  };

  getAllSchedules = () => {
    let { data } = this.state;

    let filteredData = [];
    this.startLoader();
    api
      .getWithToken("/schedule/recent")
      .then((res) => {
        const resData = res && res.data && res.data.data;
        if (res.status === 200) {
          resData.map((row) => {
            if (
              localStorage.getItem(AdminTypeString) === SuperAdminString &&
              row.is_completed &&
              row.is_report_generated
            ) {
              filteredData.push(row);
            } else if (
              localStorage.getItem(AdminTypeString) === AdminString ||
              row.is_completed ||
              row.is_report_generated
            ) {
              filteredData.push(row);
            }
            return null;
          });
          data.data = filteredData;
          this.setState({ data, isLoading: false }, () => {
            this.stopLoader();
          });
        }
      })
      .catch((error) => {
        this.stopLoader();
      });
  };

  handleCloseModal = () => {
    this.setState({ isErrorModal: false });
  };

  render() {
    const { isErrorModal } = this.state;

    return (
      <div className="quotation-box">
        <ErrorModal
          isModal={isErrorModal}
          handleClose={this.handleCloseModal}
          message="Could not download the report. first generate the report."
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section
                  className="box quotation-box"
                  style={{ height: "68% !important" }}
                >
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      getAllSurveys={this.getAllSurveys}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img src={surveysIcon} alt="survey" /> Emergency
                          Lighting Reports
                        </header>
                      }
                      isLoading={this.props.isQuotationLoad}
                      RowEdit={false}
                      RowDelete={false}
                      NoDataMessage={"No any reports generated yet"}
                      data={this.state.data}
                      {...this.props}
                      handleUpdate={this.handleUpdate}
                      generateReport={this.generateReport}
                      actions={true}
                      isPdf={true}
                      isView={false}
                      toolbar={true}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isQuotationLoad: state.QuotationLoader.isQuotationLoad,
  };
};

export default connect(mapStateToProps)(ScheduleReport);
