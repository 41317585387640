import React from "react";
import { Row, Col } from "reactstrap";
import { companaiesData } from "../../../../contants/companaiesData/companaiesData";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import api from "../../../../apiServices/index";
import DeleteModal from "../../../../component/common/model/DeleteError";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: companaiesData,
      isLoading: false,
      isModal: false,
      errorData: {
        code: 0,
        error: "",
      },
    };
  }

  componentWillMount() {
    this.getAllCompaniesdata();
  }

  getAllCompaniesdata = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/dashboard/all/companies ")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          data.data = responseData;
          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/admin/${data._id}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getAllCompaniesdata();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          this.setState({ errorData: error.response.data, isModal: true });
        }
      });
  };

  handleUpdate = (data) => {
    let body = {
      plan_expiry: data.plan_expiry,
      plan_id: data.plan_id,
      company_name: data.company_name,
      mobile_no: data.mobile_no,
      phone_no: data.phone_no,
      password: data.password,
      is_active: data.active,
      company_address: data.company_address,
    };
    this.setState({ isLoading: true });
    api
      .putWithToken(`/admin/${data._id}`, body)
      .then((response) => {
        if (response.status === 200) {
          this.getAllCompaniesdata();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div>
        <DeleteModal
          message={this.state.errorData.error}
          isModal={this.state.isModal}
          handleClose={() => {
            this.setState({ isModal: false });
          }}
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box ">
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img src={surveysIcon} alt="survey" /> COMPANIES
                          PROFILE
                        </header>
                      }
                      deleteTextMsg={
                        "Are you sure want to delete this company?"
                      }
                      handleUpdate={this.handleUpdate}
                      handleDelete={this.handleDelete}
                      isLoading={this.state.isLoading}
                      isDownload={true}
                      PdfFile={"All_Companies_List"}
                      RowEdit={true}
                      RowDelete={true}
                      NoDataMessage={"No companies data found"}
                      data={this.state.data}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Companies;
