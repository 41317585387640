import React, { Component } from "react";
import DropzoneComponent from "react-dropzone-component";
import "dropzone/dist/min/dropzone.min.css";
import "./Dropzone.scss";
import {
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiUrl } from "../../../contants/defaultvalues";
import { Bearer } from "../../../apiServices";

var ReactDOMServer = require("react-dom/server");

const getdropzoneComponentConfig = (fitting_id, imageField, scheduleId) => {
  if (scheduleId) {
    let postUrl = `${apiUrl}/api/adminUser/schedule/fitting/${scheduleId}`;
    return { postUrl };
  } else {
    let postUrl = `${apiUrl}/api/adminUser/fitting_image/${fitting_id}/${imageField}`;
    return {
      postUrl,
    };
  }
};

var dropzoneConfig = {
  maxFiles: 1,
  thumbnailHeight: 160,
  maxFilesize: 2,
  acceptedFiles: "image/jpeg,image/jpg,image/png,image/gif",
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <FontAwesomeIcon
              size="sm"
              icon={faTimesCircle}
              style={{ color: "#c43d4b" }}
            />
          </div>
          <div className="dz-success-mark">
            <FontAwesomeIcon
              size="sm"
              icon={faCheckCircle}
              style={{ color: "#576a3d" }}
            />
          </div>
          <div className="preview-container">
            {/*  eslint-disable-next-line jsx-a11y/alt-text */}
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            {" "}
            <span data-dz-name />{" "}
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        {" "}
        <FontAwesomeIcon size="1x" icon={faTrashAlt} />
      </a>
    </div>
  ),
  // headers: { "My-Awesome-Header": "header value" },
  headers: { authorization: Bearer + localStorage.getItem("access_token") },
};

export default class DropzoneExample extends Component {
  clear() {
    this.myDropzone.removeAllFiles(true);
  }

  render() {
    return (
      <DropzoneComponent
        config={getdropzoneComponentConfig(
          this.props.fittingID,
          this.props.imageField,
          this.props.scheduleId
        )}
        djsConfig={dropzoneConfig}
        eventHandlers={{
          init: (dropzone) => {
            this.myDropzone = dropzone;
          },
          success: (file) => this.props.onUploadImage(file),
        }}
      />
    );
  }
}
