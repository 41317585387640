import * as ActionTypes from "../Action-types/index";

const initialstate = {
    isQuotationLoad:false
};

const QuotationLoader = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.TOGGLE_LOADER:
      return toggleLoader(state, payload);
    default:
      return state;
  }
};

const toggleLoader = (state, payload) => {
  state = Object.assign({ isQuotationLoad :payload});
  return state;
};

export default QuotationLoader;
