import React from "react";
import { Col, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import TextInput from "../../../../component/common/TextInput/textinput";
import Button from "../../../../component/common/button/button";
import api from "../../../../apiServices/index";

class ProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        new_fitting_name: "",
        new_fitting_power: "",
        new_quantity: "",
        rrp: "",
        installation_price: "",
        accessories_plan_cost: "",
      },
      new_picture_id: "",
      isLoading: false,
      successMessage: false,
      errorMessage: false,
    };
  }

  renderLabel = (name) => (
    <div style={{ marginLeft: 15, color: "black" }}>{name}</div>
  );

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  imageHandler = (e) => {
    let { new_picture_id } = this.state;
    const Image = e.target.files[0];

    new_picture_id = Image;
    this.setState({ new_picture_id });
    if (Image && !Image.name.match(/\.(jpg|jpeg|png|PNG)$/)) {
      this.setState({
        errorMessage: true,
        apiErrorData:
          "Selected file formate is invalid. Please select jpg, jpeg or png  file.",
      });
    } else {
      this.setState({ apiErrorData: "", errorMessage: false });
    }
  };

  handleSubmit = () => {
    this.setState({
      successMessage: false,
      errorMessage: false,
      isLoading: true,
    });
    const { data } = this.state;
    let productData = new FormData();
    productData.append("new_fitting_name", data.new_fitting_name);
    productData.append("new_fitting_power", data.new_fitting_power);
    productData.append("new_quantity", data.new_quantity);
    productData.append("rrp", data.rrp);
    productData.append("installation_price", data.installation_price);
    productData.append("accessories_plan_cost", data.accessories_plan_cost);
    productData.append("new_picture_id", this.state.new_picture_id);
    api
      .postWithToken("/product/add", productData)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          this.setState({
            successMessage: true,
            isLoading: false,
          });
        }
        window.location.reload(true);
        this.props.handleClose();
      })
      .catch((error) => {
        const err =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error;
        this.setState(
          {
            errorMessage: true,
            apiErrorData: err,
            isLoading: false,
          },
          () => {}
        );
      });
  };

  render() {
    return (
      <Col sm={6} md={4} xl={3}>
        <Modal isOpen={this.props.isOpen} centered={true}>
          <form onSubmit={this.handleSubmit}>
            {this.state.isLoading ? (
              <div
                className="loader-container"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="spinner-border text-secondary Loader"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            <ModalHeader toggle={this.props.handleClose}>
              Add Product
            </ModalHeader>
            <ModalBody>
              <div className="mt-4">
                {this.renderLabel("Fitting Name")}
                <div style={{ marginLeft: 15, marginRight: 20 }}>
                  <TextInput
                    placeholder={"Enter new fitting name"}
                    search={false}
                    value={this.state.data.new_fitting_name}
                    onChange={this.handleChange}
                    name="new_fitting_name"
                    style={{ color: "black" }}
                  />
                </div>
              </div>
              <div className="mt-4">
                {this.renderLabel("Fitting Power")}
                <div style={{ marginLeft: 15, marginRight: 20 }}>
                  <TextInput
                    type="number"
                    placeholder={"Enter new fitting power"}
                    search={false}
                    value={this.state.data.new_fitting_power}
                    onChange={this.handleChange}
                    name="new_fitting_power"
                    style={{ color: "black" }}
                  />
                </div>
              </div>
              <div className="mt-4">
                {this.renderLabel("Fitting Quantity")}
                <div style={{ marginLeft: 15, marginRight: 20 }}>
                  <TextInput
                    type="number"
                    placeholder={"Enter new fitting quantity"}
                    search={false}
                    value={this.state.data.new_quantity}
                    onChange={this.handleChange}
                    name="new_quantity"
                    style={{ color: "black" }}
                  />
                </div>
              </div>
              <div className="mt-4">
                {this.renderLabel("RRP Price")}
                <div style={{ marginLeft: 15, marginRight: 20 }}>
                  <TextInput
                    type="number"
                    placeholder={"Enter new rrp price"}
                    search={false}
                    value={this.state.data.rrp}
                    onChange={this.handleChange}
                    name="rrp"
                    style={{ color: "black" }}
                  />
                </div>
              </div>
              <div className="mt-4">
                {this.renderLabel("Installation Price")}
                <div style={{ marginLeft: 15, marginRight: 20 }}>
                  <TextInput
                    type="number"
                    placeholder={"Enter installation price"}
                    search={false}
                    value={this.state.data.installation_price}
                    onChange={this.handleChange}
                    name="installation_price"
                    style={{ color: "black" }}
                  />
                </div>
              </div>

              <div className="mt-4">
                {this.renderLabel("Accessories Plan Cost")}
                <div style={{ marginLeft: 15, marginRight: 20 }}>
                  <TextInput
                    type="number"
                    placeholder={"Enter accessories plan cost"}
                    search={false}
                    value={this.state.data.accessories_plan_cost}
                    onChange={this.handleChange}
                    name="accessories_plan_cost"
                    style={{ color: "black" }}
                  />
                </div>
              </div>

              <div className="mt-4">
                {this.renderLabel("New Fitting Picture")}
                <div
                  style={{ marginLeft: 15, marginRight: 20 }}
                  className="mt-2"
                >
                  <TextInput
                    type="file"
                    placeholder={"Enter new fitting picture"}
                    search={false}
                    value={this.state.data.new_picture_id}
                    onChange={this.imageHandler}
                    name="new_picture_id"
                    accept="image/png,image/jpeg,image/jpg"
                    style={{ color: "black" }}
                  />
                </div>
              </div>

              <p
                style={{
                  marginTop: "20px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {this.state.errorMessage && (
                  <div className="error-message-box">
                    {this.state.apiErrorData}
                  </div>
                )}
              </p>
              <p
                style={{
                  marginTop: "20px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {this.state.successMessage && (
                  <div className="success-message-box">
                    Product added successfully
                  </div>
                )}
              </p>
            </ModalBody>
            <ModalFooter className="mt-3">
              <button
                onClick={this.props.handleClose}
                className="my-theme-button btn btn-secondary"
                color="secondary"
              >
                Close
              </button>

              <Button
                full={true}
                disabled={this.state.errorMessage ? true : false}
              >
                Save changes
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </Col>
    );
  }
}

export default ProductModal;
