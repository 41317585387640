import React from "react";
import { Row, Col } from "reactstrap";
import Button from "../../../../../component/common/button/button";
import TextInput from "../../../../../component/common/TextInput/textinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import api from "../../../../../apiServices/index";
import {
  faLock,
  // faBuilding,
  faUser,
  faEnvelope,
  faMapMarkerAlt,
  // faMobile,
  faPhone,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import ReeValidate from "ree-validate";
import TextInputWithButton from "../../../../../component/common/textinputwithbutton/textinputwithbutton";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

class CreateItem extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new ReeValidate({
      email: "email",
      phone_no: "min:10|max:15",
    });

    this.state = {
      creadiantial: {
        company_address: "",
        company_name: "",
        email: "",
        fullname: "",
        mobile_no: "",
        phone_no: "",
        username: "",
        old_password: "",
        new_password: "",
      },
      apiSuccess: false,
      isLoading: false,
      isPhoneValid: true,
      errors: this.validator.errors,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ apiSuccess: false, isLoading: true });
    let { creadiantial } = this.state;

    let isPhoneValid = isValidPhoneNumber(creadiantial.mobile_no);
    this.setState({ isPhoneValid });

    if (isPhoneValid) {
      creadiantial.company_address = creadiantial.company_address.trim();
      creadiantial.company_name = creadiantial.company_name.trim();
      creadiantial.email = creadiantial.email.trim();
      creadiantial.fullname = creadiantial.fullname.trim();
      creadiantial.mobile_no = creadiantial.mobile_no.trim();
      creadiantial.phone_no = creadiantial.phone_no.trim();
      creadiantial = this.DeleteKey(creadiantial, "username");

      this.validator.validateAll(creadiantial).then((success) => {
        if (success) {
          api
            .putWithToken("/update", creadiantial)
            .then((response) => {
              const { status } = response;
              if (status === 200) {
                this.setState({ apiSuccess: true, isLoading: false });
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
            });
        } else {
          this.setState({ errors, isLoading: false });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }

    const { errors } = this.validator;
    //eslint-disable-next-line
    const { history } = this.props;
    errors.remove();
  };

  DeleteKey = (object, key) => {
    delete object[key];
    return object;
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;
    creadiantial[name] = value;
    errors.remove(name);
    this.setState({
      creadiantial,
      apiSuccess: false,
    });
  };

  componentWillMount() {
    let { creadiantial } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/myProfile")
      .then((response) => {
        const { data } = response.data;
        //eslint-disable-next-line
        Object.keys(data).map((key, index) => {
          if (creadiantial.hasOwnProperty(key)) {
            creadiantial[key] = data[key];
          }
        });
        this.setState({ creadiantial, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={8} md={8}>
              <div className="col-xl-12">
                {this.state.isLoading ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "98%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    Profile
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleSubmit}>
                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Company Name
                              </div>
                              <div>
                                <TextInputWithButton
                                  search={false}
                                  placeholder=""
                                  height=""
                                  width=""
                                  name="company_name"
                                  value={this.state.creadiantial.company_name}
                                  onChange={this.handleChange}
                                  button={false}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faEnvelope}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Full Name
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  width=""
                                  name="fullname"
                                  onChange={this.handleChange}
                                  value={this.state.creadiantial.fullname}
                                >
                                  <FontAwesomeIcon size="1x" icon={faUserTie} />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                E-Mail
                              </div>
                              <div>
                                <TextInputWithButton
                                  disabled={true}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="email"
                                  value={this.state.creadiantial.email}
                                  button={false}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faEnvelope}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Address
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="company_address"
                                  value={
                                    this.state.creadiantial.company_address
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faMapMarkerAlt}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Mobile No
                              </div>
                              <div className="profile-phone-input">
                                <PhoneInput
                                  placeholder="Enter phone number"
                                  value={this.state.creadiantial.mobile_no}
                                  onChange={(mobile_no) => {
                                    let { creadiantial } = this.state;
                                    creadiantial.mobile_no = mobile_no;
                                    this.setState({ creadiantial });
                                  }}
                                  defaultCountry="IE"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Phone No
                              </div>
                              <div>
                                <TextInputWithButton
                                  type="tel"
                                  placeholder="ex 1234567890"
                                  search={false}
                                  height=""
                                  width=""
                                  onChange={this.handleChange}
                                  button={false}
                                  name={"phone_no"}
                                  value={this.state.creadiantial.phone_no}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faPhone}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "10px", marginBottom: "5px" }}
                          >
                            User Name
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <TextInput
                              disabled={true}
                              search={false}
                              placeholder=""
                              height=""
                              width=""
                              name="username"
                              onChange={this.handleChange}
                              value={this.state.creadiantial.username}
                            >
                              <FontAwesomeIcon
                                onClick={this.handleEdit}
                                size="1x"
                                icon={faUser}
                              />
                            </TextInput>
                          </div>
                        </div>

                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Old Password
                              </div>
                              <div>
                                <TextInputWithButton
                                  search={false}
                                  placeholder="Enter Current Password"
                                  height=""
                                  width=""
                                  onChange={this.handleChange}
                                  name="old_password"
                                  value={this.state.creadiantial.old_password}
                                  button={false}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faLock}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                New Password
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  search={false}
                                  placeholder="Enter New Password"
                                  height=""
                                  width=""
                                  name="new_password"
                                  value={this.state.creadiantial.new_password}
                                  onChange={this.handleChange}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faLock}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                          <Button
                            disabled={
                              this.state.creadiantial.fullname === "" ||
                              this.state.creadiantial.new_password === "" ||
                              this.state.creadiantial.old_password === "" ||
                              this.state.creadiantial.email === "" ||
                              this.state.creadiantial.company_address === "" ||
                              this.state.creadiantial.company_name === "" ||
                              this.state.creadiantial.phone_no === "" ||
                              this.state.creadiantial.mobile_no === ""
                            }
                            type="submit"
                            onSubmit={this.handleSubmit}
                            full={true}
                          >
                            SAVE
                          </Button>
                        </div>
                      </form>
                      <p style={{ marginTop: "20px" }}>
                        {this.state.apiSuccess && (
                          <div className="success-message-box">
                            Profile updated successfully.
                          </div>
                        )}
                      </p>
                      <p style={{ marginTop: "20px" }}>
                        {!this.state.isPhoneValid && (
                          <div className="error-message-box">
                            Invalid Mobile No.
                          </div>
                        )}
                      </p>
                      <p style={{ marginTop: "20px" }}>
                        {errors.has("phone_no") && (
                          <div className="error-message-box">
                            Invalid Phone No.
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.adminProfile,
  };
};

export default connect(mapStateToProps)(CreateItem);
