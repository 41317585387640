import React from "react";
import Render from "../../component/common/commonRender/commonRender";
import ImageView from "./ImageView";
import moment from "moment-timezone";
import noQrImage from "../../assets/img/no_qr.png";

export const LoadedScheduleData = {
  Header: [
    {
      title: "Fitting Image",
      field: "fitting_image",
      editable: "never",
      render: (rowData) => (
        <ImageView
          rowData={rowData}
          imageField={0}
          modalTitle="Fitting Image"
          pictures={rowData.fitting_image}
        />
      ),
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => <Render>{rowData.description}</Render>,
    },
    {
      title: "Location",
      field: "location",
      render: (rowData) => <Render>{rowData.location}</Render>,
    },
    {
      title: "Schedule Number",
      field: "schedule_number",
      type: "numeric",
      render: (rowData) => <Render>{rowData.schedule_number}</Render>,
    },
    {
      title: "Status",
      field: "status",
      lookup: {
        /* eslint-disable */
        ["Fitting Status Failed"]: "Fitting Status Failed",
        ["Fitting Status Not Tested Yet!"]: "Fitting Status Not Tested Yet!",
        ["Fitting Status Passed"]: "Fitting Status Passed",
        /* eslint-enable */
      },
      render: (rowData) => (
        <Render>
          {rowData.status === "Fitting Status Failed" ? (
            <span className="badge badge-danger">{rowData.status}</span>
          ) : rowData.status === "Fitting Status Not Tested Yet!" ? (
            <span className="badge badge-warning">{rowData.status}</span>
          ) : (
            <span className="badge badge-success">{rowData.status}</span>
          )}
        </Render>
      ),
    },
    {
      title: "Last Tested",
      field: "last_test_date",
      // type: "date",
      editable: "never",
      render: (rowData) => (
        <Render>
          {moment(rowData.last_test_date)
            .tz("Europe/Dublin")
            .format("L, h:mm:ss a")}
        </Render>
      ),
    },
    {
      title: "Test Duration",
      field: "last_test_duration",
      lookup: {
        /* eslint-disable */
        ["30 Minutes"]: "30 Minutes",
        ["60 Minutes"]: "60 Minutes",
        ["90 Minutes"]: "90 Minutes",
        ["120 Minutes"]: "120 Minutes",
        ["180 Minutes"]: "180 Minutes",
        /* eslint-enable */
      },
      render: (rowData) => (
        <div>
          {rowData && rowData.last_test_duration === "30 Minutes" ? (
            <Render>{"30 Minutes"}</Render>
          ) : null}
          {rowData && rowData.last_test_duration === "60 Minutes" ? (
            <Render>{"60 Minutes"}</Render>
          ) : null}
          {rowData && rowData.last_test_duration === "90 Minutes" ? (
            <Render>{"90 Minutes"}</Render>
          ) : null}
          {rowData && rowData.last_test_duration === "120 Minutes" ? (
            <Render>{"120 Minutes"}</Render>
          ) : null}
          {rowData && rowData.last_test_duration === "180 Minutes" ? (
            <Render>{"180 Minutes"}</Render>
          ) : null}
        </div>
      ),
    },
    {
      title: "Next Test",
      field: "next_test_due_date",
      // type: "date",
      editable: "never",
      render: (rowData) => (
        <Render>
          {moment(rowData.next_test_due_date)
            .tz("Europe/Dublin")
            .format("L, h:mm:ss a")}
        </Render>
      ),
    },
    {
      title: "Next Test Duration",
      field: "next_test_duration",
      lookup: {
        /* eslint-disable */
        ["30 Minutes"]: "30 Minutes",
        ["60 Minutes"]: "60 Minutes",
        ["90 Minutes"]: "90 Minutes",
        ["120 Minutes"]: "120 Minutes",
        ["180 Minutes"]: "180 Minutes",
        /* eslint-enable */
      },
      render: (rowData) => (
        <div>
          {rowData && rowData.next_test_duration === "30 Minutes" ? (
            <Render>{"30 Minutes"}</Render>
          ) : null}
          {rowData && rowData.next_test_duration === "60 Minutes" ? (
            <Render>{"60 Minutes"}</Render>
          ) : null}
          {rowData && rowData.next_test_duration === "90 Minutes" ? (
            <Render>{"90 Minutes"}</Render>
          ) : null}
          {rowData && rowData.next_test_duration === "120 Minutes" ? (
            <Render>{"120 Minutes"}</Render>
          ) : null}
          {rowData && rowData.next_test_duration === "180 Minutes" ? (
            <Render>{"180 Minutes"}</Render>
          ) : null}
        </div>
      ),
    },
    {
      title: "Reminder Set",
      field: "is_remember",
      lookup: { true: "Yes", false: "No" },
      editable: "onUpdate",
      render: (rowData) => {
        return rowData.is_remember ? (
          <div style={{ color: "green" }}>Yes</div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        );
      },
    },
    {
      title: "Notes",
      field: "message",
      render: (rowData) => <Render>{rowData.message}</Render>,
    },
    {
      title: "NFC tagged",
      field: "nfc_tagged",
      editable: "never",
      render: (rowData) => <Render>{rowData.nfc_tagged}</Render>,
    },
    {
      title: "NFC tag ID",
      field: "nfc_tag_id",
      editable: "never",
      render: (rowData) => <Render>{rowData.nfc_tag_id}</Render>,
    },
    {
      title: "Qrc Attached",
      field: "qr_code_image",
      lookup: { true: "Yes", false: "No" },
      editable: "never",
      render: (rowData) => {
        return rowData &&
          rowData.qr_code_image &&
          rowData.qr_code_image !== "" ? (
          <div style={{ color: "green" }}>Yes</div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        );
      },
    },
    {
      title: "Qrc Image",
      field: "qr_code_image",
      lookup: { true: "Yes", false: "No" },
      editable: "never",
      render: (rowData) => (
        <ImageView
          modalTitle="QRC Image"
          imageField={1}
          rowData={rowData}
          pictures={
            rowData && rowData.qr_code_image && rowData.qr_code_image !== ""
              ? rowData.qr_code_image
              : noQrImage
          }
          qrImage={true}
        />
      ),
    },
  ],
  data: [],
};
