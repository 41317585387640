import React from "react";
import { Row, Col } from "reactstrap";
import { getAllSurveysData } from "../../../../contants/AllSurveysData/AllSurveysData";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import MeterialTable from "../../../../component/meterialTable/meterialTable";
// import SurveyTable from "../../../../component/meterialTable/surveyTable";
import api from "../../../../apiServices/index";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

class AllServeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activityData: {
        avarage_kwh_saved: 0,
        avarage_savings_per_customer: 0,
        chart_data: [],
        maximum_kwh_price: 0,
        minimal_kwh_price: 0,
        new_surveys: 0,
        total_companies: 0,
        total_customers: 0,
        total_energy_savings: 0,
        total_fitting: 0,
        total_savings: 0,
        total_surveys: 0,
        total_users: 0,
      },
      isLoading: false,
      sweetAlert: false,
    };
  }

  toggalSweetAlert = () => {
    this.setState({ sweetAlert: !this.state.sweetAlert });
  };

  onConfirm = () => {
    this.setState({ sweetAlert: false });
    // this.getContactUs();
  };

  getActivityOverviewData = () => {
    api
      .getWithToken("/dashboard/activity/overview")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          if (response.data.data === "No Survey data found") {
          } else {
            this.setState({ activityData: responseData }, () => {
              this.getAllSurveys();
            });
          }
        }
      })
      .catch((error) => {});
  };

  componentWillMount() {
    const AllSurveysData = getAllSurveysData();
    const columns = getAllSurveysData();
    this.setState({ data: AllSurveysData, columns });
    this.getActivityOverviewData();
  }

  getAllSurveys = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/dashboard/recent/surveys")
      .then((response) => {
        let responseData = response.data.data;
        if (response.status === 200) {
          //eslint-disable-next-line
          responseData.map((object, i) => {
            if (this.state.activityData.new_surveys > i) {
              object.is_recent_survey = true;
            }
          });
          data.data = responseData;

          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/survey/${data.suvey_id}`)
      .then((response) => {
        //eslint-disable-next-line
        const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getActivityOverviewData();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleUpdate = (data) => {
    if (Number(data.tax_percentage) < 1) {
      this.setState({
        sweetAlert: true,
        message: "Tax percentage should be between 1 to 100",
      });
    } else if (Number(data.tax_percentage) > 100) {
      this.setState({
        sweetAlert: true,
        message: "Tax percentage should be between 1 to 100",
      });
    } else if (Number(data.discount_percentage) < 1) {
      this.setState({
        sweetAlert: true,
        message: "Saved Discount should be between 1 to 100",
      });
    } else if (Number(data.discount_percentage) > 100) {
      this.setState({
        sweetAlert: true,
        message: "Saved Discount should be between 1 to 100",
      });
    }
    // else if (Number(data.carbon_discounts) < 1) {
    //   this.setState({
    //     sweetAlert: true,
    //     message: "Carbon Discount should be between 1 to 100",
    //   });
    // } else if (Number(data.carbon_discounts) > 100) {
    //   this.setState({
    //     sweetAlert: true,
    //     message: "Carbon Discount should be between 1 to 100",
    //   });
    // }
    else {
      data.is_completed = data.is_completed.toString();
      data.is_project_completed = data.is_project_completed.toString();
      let body = {
        name: data.survey_name,
        operational_hours_per_year: data.operational_hours_per_year,
        rate_per_kwh: data.rate_per_kwh,
        company_name: data.survey_company_name,
        address: data.survey_address,
        is_completed: data.is_completed === "true" ? true : false,
        data_fill: data.data_fill === "true" ? true : false,
        is_submitted: data.is_completed === "true" ? true : false,
        is_project_completed:
          data.is_project_completed === "true" ? true : false,
        tax_percentage: data.tax_percentage,
        carbon_discounts: data.carbon_discounts,
        discount_percentage: data.discount_percentage,
      };
      this.setState({ isLoading: true });
      api
        .postWithToken(`/survey/${data.suvey_id}`, body)
        .then((response) => {
          //eslint-disable-next-line
          const responseData = response.data.data;
          if (response.status === 200) {
            this.setState({ isLoading: false }, () => {
              this.getAllSurveys();
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box ">
                  <div className="content-body custom-pagination">
                    <MeterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img src={surveysIcon} alt="survey" />
                          {" Lighting Surveys"}
                        </header>
                      }
                      deleteTextMsg={"Are you sure want to delete this survey?"}
                      NoDataMessage={"Users have not created any survey yet"}
                      RowEdit={true}
                      RowDelete={true}
                      RowClickable={true}
                      handleUpdate={this.handleUpdate}
                      handleDelete={this.handleDelete}
                      data={this.state.data}
                      {...this.props}
                      toolbar={true}
                    />

                    {this.state.sweetAlert && (
                      <SweetAlert
                        custom
                        btnSize="lg"
                        show={this.toggalSweetAlert}
                        danger
                        title={
                          this.state.apiError
                            ? this.state.apiErrorData.error
                            : this.state.message
                        }
                        onConfirm={this.onConfirm}
                      ></SweetAlert>
                    )}
                    {/* <SurveyTable /> */}
                  </div>
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

export default connect(mapStateToProps)(AllServeys);
