import React from "react";
import { Row, Col } from "reactstrap";
import "../login/login.css";
import Logo from "../../../assets/img/newlogo.png";
import ReeValidate from "ree-validate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLightbulb,
  faTimesCircle,
  // faCheckCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import api from "../../../apiServices/index";
import { NavLink } from "react-router-dom";
// import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      password: "min:6",
      confirmPassword: "min:6",
    });

    this.state = {
      creadiantial: {
        password: "",
        confirmPassword: "",
        isSubmitting: false,
      },
      apiData: {
        code: 0,
        data: "",
      },
      apiErrorData: {
        code: 0,
        error: "",
      },
      errors: this.validator.errors,
      matchError: false,
      isLoading: false,
      apiResponse: false,
      apierrorResponse: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  handleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  handleShowConfirmPassword = () =>
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;
    creadiantial[name] = value;
    errors.remove(name);
    this.setState({ creadiantial, isSubmitting: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      matchError: false,
      apierrorResponse: false,
      isLoading: true,
      apiResponse: false,
    });
    let token = this.props.location.search;
    token = token.split("?token=")[1];
    let { creadiantial } = this.state;
    const { errors } = this.validator;
    //eslint-disable-next-line
    const { history } = this.props;
    errors.remove();
    this.validator.validateAll(creadiantial).then((success) => {
      if (success) {
        this.setState({ isSubmitting: true });
        if (creadiantial.password !== creadiantial.confirmPassword) {
          this.setState({ matchError: true, isLoading: false });
        } else {
          setTimeout(() => {
            const url = "/change_password";

            const data = {
              newPassword: creadiantial.password,
              verifyPassword: creadiantial.confirmPassword,
              token,
            };
            api
              .postWithTokenInUrl(url, data)
              .then((response) => {
                const data = response.data;
                this.setState(() => {
                  return { apiResponse: true, isLoading: false, apiData: data };
                });
              })
              .catch((error) => {
                this.setState({
                  apierrorResponse: true,
                  apiErrorData: error.response.data,
                  isLoading: false,
                });
              });
          }, 500);
        }
      } else {
        this.setState({ errors, isLoading: false });
      }
    });
  };
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    {this.state.isLoading ? (
                      <div className="loader-container">
                        <div
                          className="spinner-border LoginpageLoader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : null}
                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>

                    <form onSubmit={this.handleSubmit} method="POST">
                      <p style={{ marginTop: "10px", marginBottom: "0px" }}>
                        <label htmlFor="user_login">
                          New Password
                          <br />
                          <div
                            className={
                              errors.has("password")
                                ? "error-border"
                                : "input-border"
                            }
                          >
                            <input
                              onChange={this.handleChange}
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              name="password"
                              placeholder="Ex: test@123"
                              id="user_name"
                              className="form-control"
                            />
                          </div>
                          <FontAwesomeIcon
                            className=""
                            style={{ color: "red", opacity: "0" }}
                            size="lg"
                            icon={faTimesCircle}
                          />
                          {/* {this.state.creadiantial.password != "" ? (
                            errors.has("password") ? (
                              <FontAwesomeIcon
                                className="float-right close-icon"
                                style={{ color: ErrorColor }}
                                size="lg"
                                icon={faTimesCircle}
                              />
                            ) : this.state.isSubmitting ? (
                              <FontAwesomeIcon
                                className="float-right close-icon"
                                style={{ color: SucessColor }}
                                size="lg"
                                icon={faCheckCircle}
                              />
                            ) : null
                          ) : null} */}
                          {this.state.showPassword && (
                            <FontAwesomeIcon
                              className="float-right close-icon icon-cursor-pointer"
                              // style={{ color: SucessColor }}
                              size="lg"
                              onClick={this.handleShowPassword}
                              icon={faEye}
                            />
                          )}
                          {!this.state.showPassword && (
                            <FontAwesomeIcon
                              className="float-right close-icon icon-cursor-pointer"
                              // style={{ color: SucessColor }}
                              size="lg"
                              onClick={this.handleShowPassword}
                              icon={faEyeSlash}
                            />
                          )}
                        </label>
                      </p>

                      <p style={{ marginBottom: "0px" }}>
                        <label htmlFor="user_pass">
                          Confirm Password
                          <br />
                          <div
                            className={
                              errors.has("confirmPassword")
                                ? "error-border"
                                : "input-border"
                            }
                          >
                            <input
                              type={
                                this.state.showConfirmPassword
                                  ? "text"
                                  : "password"
                              }
                              placeholder="Ex: test@123"
                              name="confirmPassword"
                              onChange={this.handleChange}
                              id="user_pass"
                              className="input"
                              size="20"
                            />
                          </div>
                          <FontAwesomeIcon
                            className=""
                            style={{ color: "red", opacity: "0" }}
                            size="lg"
                            icon={faTimesCircle}
                          />
                          {/* {this.state.creadiantial.confirmPassword != "" ? (
                            errors.has("confirmPassword") ? (
                              <FontAwesomeIcon
                                className="float-right close-icon"
                                style={{ color: ErrorColor }}
                                size="lg"
                                icon={faTimesCircle}
                              />
                            ) : this.state.isSubmitting ? (
                              <FontAwesomeIcon
                                className="float-right close-icon"
                                style={{ color: SucessColor }}
                                size="lg"
                                icon={faCheckCircle}
                              />
                            ) : null
                          ) : null} */}
                          {this.state.showConfirmPassword && (
                            <FontAwesomeIcon
                              className="float-right close-icon icon-cursor-pointer"
                              // style={{ color: SucessColor }}
                              size="lg"
                              onClick={this.handleShowConfirmPassword}
                              icon={faEye}
                            />
                          )}
                          {!this.state.showConfirmPassword && (
                            <FontAwesomeIcon
                              className="float-right close-icon icon-cursor-pointer"
                              // style={{ color: SucessColor }}
                              size="lg"
                              onClick={this.handleShowConfirmPassword}
                              icon={faEyeSlash}
                            />
                          )}
                        </label>
                      </p>
                      <p className="submit">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <label
                            style={{
                              justifyContent: "center",
                              textAlign: "center",
                              // backgroundColor: "red",
                            }}
                          >
                            <input
                              disabled={
                                this.state.creadiantial.password === "" ||
                                this.state.creadiantial.confirmPassword === ""
                              }
                              style={{ fontFamily: "roboto-regular" }}
                              type="submit"
                              onSubmit={this.handleSubmit}
                              name="wp-submit"
                              id="wp-submit"
                              className="btn btn-accent "
                              value="Change Password"
                            />
                          </label>
                        </div>
                      </p>
                    </form>
                    <p>
                      {errors.has("password") ||
                      errors.has("confirmPassword") ? (
                        <div className="error-message-box">
                          Please enter minimum 6 character password.
                        </div>
                      ) : null}
                    </p>
                    <p>
                      {this.state.matchError && (
                        <div className="error-message-box">
                          Password and confirm password does not match , please
                          enter again.
                        </div>
                      )}
                    </p>
                    <p>
                      {this.state.apierrorResponse && (
                        <div className="error-message-box">
                          {this.state.apiErrorData.error}
                        </div>
                      )}
                    </p>
                    <p>
                      {this.state.apiResponse && (
                        <div className="success-message-box">
                          <div>{this.state.apiData.data}</div>
                          <NavLink to="/login">
                            <div style={{ color: "blue" }}>Login</div>
                          </NavLink>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
