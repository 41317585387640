//eslint-disable-next-line
import React, { Component } from "react";
import Sidebar from "../container/navs/sidebar";
import Header from "../component/common/header/mainHeader";
import { withRouter } from "react-router-dom";
import "./index.css";

import {
  topbarStyle,
  menuStyle,
  menuType,
  topbarType,
  navWidth
} from "../contants/pagelayoutstyle";

import TransparentHeader from "../component/common/header/TransparentHeader";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuColor: menuStyle,
      topbarColor: topbarStyle,
      menuType: menuType,
      topbarType: topbarType
    };
    this.menuSettings = this.menuSettings.bind(this);
    this.topbarSettings = this.topbarSettings.bind(this);
  }

  menuSettings(val1, val2) {
    this.setState({
      menuColor: val1,
      menuType: val2
    });
  }
  topbarSettings(val1, val2) {
    this.setState({
      topbarColor: val1,
      topbarType: val2
    });
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    //eslint-disable-next-line
    const { match } = this.props;
    return (
      <div
        className="wrapper"
        ref="themeWrapper"
        data-menu={this.state.menuColor}
        data-topbar={this.state.topbarColor}
        data-menutype={this.state.menuType}
        data-topbartype={this.state.topbarType}
      >
        <Sidebar {...this.props} />
        <Header {...this.props} navtype={navWidth} />;
        <TransparentHeader />
        <div className="main-panel" ref="mainPanel">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withRouter(Layout);
