import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect({ data, handleChange, value }) {
  const classes = useStyles();
  return (
    <Select
      value={value}
      style={{ width: "100%" }}
      className={classes.root}
      onChange={handleChange}
      variant="outlined"
    >
      {data.map((item, key) => {
        if (!item.default && item.is_active) {
          return (
            <MenuItem key={key} value={item._id}>
              {item.name}
            </MenuItem>
          );
        }
        return null;
      })}
    </Select>
  );
}
