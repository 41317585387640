import React from "react";
import { Row, Col } from "reactstrap";
import "../login/login.css";
import Logo from "../../../assets/img/newlogo.png";

// import {
//   faLightbulb,
//   faTimesCircle
// } from "@fortawesome/free-regular-svg-icons";

class EmailResetSuccess extends React.Component {
  handleSubmit = (event) => {
    event.preventDefault();
    const { history } = this.props;
    history.push("/login");
  };
  render() {
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>

                    <form
                      name="loginform"
                      id="loginform"
                      action="#!"
                      method="post"
                    >
                      <p>
                        <h1 style={{ opacity: 1 }}>CONGRATULATIONS!</h1>
                        <div
                          style={{
                            color: "black",
                            textAlign: "center",
                            fontSize: "14px",
                            fontFamily: "roboto-regular",
                            opacity: "0.5",
                          }}
                        >
                          Confirmation link has been sent to your E-Mail. Please
                          click on the link to confirm your password reset.
                        </div>
                      </p>
                      <p className="submit">
                        <input
                          onClick={this.handleSubmit}
                          style={{ fontFamily: "roboto-regular" }}
                          type="button"
                          name="wp-submit"
                          id="wp-submit"
                          className="btn btn-accent btn-block"
                          value="OK"
                        />
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EmailResetSuccess;
