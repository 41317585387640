import React from "react";
import { Col, Row } from "reactstrap";
import Button from "../../../../component/common/button/button";
import ProductModal from "./ProductModal";
import api from "../../../../apiServices/index";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import { getallProductsData } from "../../../../contants/AllProductsData/allProductsData";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import SweetAlert from "react-bootstrap-sweetalert";

class DynamicProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      data: [],
      isLoading: false,
      errorMessage: false,
      successMessage: false,
      sweetAlert: false,
    };
  }

  toggalSweetAlert = () => {
    this.setState({ sweetAlert: !this.state.sweetAlert });
  };

  handleShow = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false, title: "", url: "", apiError: false });
  };

  getProducts = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/product")
      .then((res) => {
        const resData = res && res.data && res.data.data;
        data.data = resData;
        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  componentWillMount() {
    const productData = getallProductsData();
    this.setState({ data: productData }, () => {
      this.getProducts();
    });
  }

  handleUpdate = (rowData) => {
    this.setState({
      successMessage: false,
      errorMessage: false,
      isLoading: true,
    });

    let productData = new FormData();
    productData.append("new_fitting_name", rowData.new_fitting_name);
    productData.append("new_fitting_power", rowData.new_fitting_power);
    productData.append("new_quantity", rowData.new_quantity);
    productData.append("rrp", rowData.rrp);
    productData.append("installation_price", rowData.installation_price);
    productData.append("accessories_plan_cost", rowData.accessories_plan_cost);
    productData.append("new_picture_id", rowData.new_picture_id);
    api
      .putWithToken(`/product?product_id=${rowData.product_id}`, productData)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          this.setState({
            successMessage: true,
            isLoading: false,
          });
        }
        this.getProducts();
      })
      .catch((error) => {
        const err =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error;
        this.setState(
          {
            errorMessage: true,
            apiErrorData: err,
            isLoading: false,
            sweetAlert: true,
          },
          () => {}
        );
      });
  };

  handleDelete = (rowData) => {
    this.setState({
      successMessage: false,
      errorMessage: false,
      isLoading: true,
    });

    api
      .deleteWithToken(`/product?product_id=${rowData.product_id}`)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          this.setState({
            successMessage: true,
            isLoading: false,
          });
        }
        this.getProducts();
      })
      .catch((error) => {
        const err =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error;
        this.setState(
          {
            errorMessage: true,
            apiErrorData: err,
            isLoading: false,
            sweetAlert: true,
          },
          () => {}
        );
      });
  };

  onConfirm = () => {
    this.setState({ sweetAlert: false });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box">
                  <div className="content-body custom-pagination mt-1">
                    <div style={{ textAlign: "right" }}>
                      <Button full={true} onClick={this.handleShow}>
                        Add Product +
                      </Button>
                    </div>

                    <ProductModal
                      isOpen={this.state.modalOpen}
                      handleClose={this.handleClose}
                      getProducts={this.getProducts}
                    />

                    <div className="mt-3">
                      <MaterialTable
                        isLoading={this.state.isLoading}
                        TableTitle={
                          <header className="panel_header text-uppercase">
                            <img src={surveysIcon} alt="survey" /> Products
                          </header>
                        }
                        toolbar={true}
                        NoDataMessage={"No any product data found"}
                        data={this.state.data}
                        RowEdit={true}
                        handleUpdate={this.handleUpdate}
                        RowDelete={true}
                        deleteTextMsg={
                          "Are you sure want to delete this product?"
                        }
                        handleDelete={this.handleDelete}
                        search={true}
                        {...this.props}
                      />

                      {this.state.sweetAlert && (
                        <SweetAlert
                          custom
                          btnSize="lg"
                          show={this.toggalSweetAlert}
                          danger
                          title={this.state.apiErrorData}
                          onConfirm={this.onConfirm}
                        ></SweetAlert>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DynamicProduct;
