import * as ActionType from "../Action-types";

export function toggleQuotationLoader(payload) {
  return {
    type: ActionType.TOGGLE_LOADER,
    payload,
  };
}

export function setPlanExpireModal(payload) {
  return {
    type: ActionType.SET_PLAN_EXPIRE_MODAL,
    payload,
  };
}

export function setServerError(payload) {
  return {
    type: ActionType.SET_SERVER_ERROR,
    payload,
  };
}

export function resetServerError() {
  return {
    type: ActionType.RESET_SERVER_ERROR,
  };
}

export function setSearchValue(payload) {
  return {
    type: ActionType.SET_SEARCH_VALUE,
    payload,
  };
}

export function resetSearchValue(payload) {
  return {
    type: ActionType.RESET_SEARCH_VALUE,
    payload,
  };
}

export function setAdminProfileData(payload) {
  return {
    type: ActionType.SET_ADMIN_PROFILE_DATA,
    payload,
  };
}



export function setParamsId(payload) {
  return {
    type: ActionType.SET_PARAMS_ID,
    payload,
  };
}

export function ResetParamsId() {
  return {
    type: ActionType.RESET_PARAMS_ID,
  };
}

export function setLoadedCompanyData(payload) {
  return {
    type: ActionType.SET_LOADED_COMPANY_DATA,
    payload,
  };
}

export function resetLoadedCompanyData() {
  return {
    type: ActionType.RESET_LOADED_COMPANY_DATA,
  };
}
