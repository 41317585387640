import * as ActionTypes from "../Action-types/index";

const initialstate = {
  searchValue: "",
  radioButtonValue: ""
};

const Auth = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SET_SEARCH_VALUE:
      return setSearchValue(state, payload);
    case ActionTypes.RESET_SEARCH_VALUE:
      return resetSearchValue(state, payload);
    default:
      return state;
  }
};

const setSearchValue = (state, payload) => {
  state = Object.assign({
    searchValue: payload.searchValue,
    radioButtonValue: payload.RadioButtonValue1
  });
  return state;
};

const resetSearchValue = (state, payload) => {
  state = Object.assign({ searchValue: "", radioButtonValue: "" });
  return state;
};

export default Auth;
