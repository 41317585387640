import React from "react";
import { Row, Col } from "reactstrap";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextInput/textinput";

class EditCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }
  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <img src={surveysIcon} alt="survey" /> edit companies
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "10px", marginBottom: "5px" }}
                          >
                            Company Name
                          </div>
                          <TextInput
                            search={false}
                            icon={surveysIcon}
                            placeholder=""
                            height=""
                            width=""
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "10px", marginBottom: "5px" }}
                          >
                            Address
                          </div>
                          <TextInput
                            search={false}
                            icon={surveysIcon}
                            placeholder=""
                            height=""
                            width=""
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "10px", marginBottom: "5px" }}
                          >
                            E-mail
                          </div>
                          <TextInput
                            search={false}
                            icon={surveysIcon}
                            placeholder=""
                            height=""
                            width=""
                          />
                        </div>
                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Mobile No:
                              </div>
                              <TextInput
                                search={false}
                                icon={surveysIcon}
                                placeholder=""
                                height=""
                                width=""
                              />
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Phone No
                              </div>
                              <TextInput
                                search={false}
                                icon={surveysIcon}
                                placeholder=""
                                height=""
                                width=""
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                User Name
                              </div>
                              <TextInput
                                search={false}
                                icon={surveysIcon}
                                placeholder=""
                                height=""
                                width=""
                              />
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Password
                              </div>
                              <TextInput
                                search={false}
                                icon={surveysIcon}
                                placeholder=""
                                height=""
                                width=""
                              />
                            </div>
                          </Col>
                        </Row>

                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                          Status{" "}
                          <input
                            style={{
                              marginLeft: "20px",
                              verticalAlign: "middle",
                            }}
                            type="radio"
                          />{" "}
                          active
                        </div>
                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                          <Button small={true}>SAVE</Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EditCompanies;
