import * as ActionTypes from "../Action-types/index";

const initialstate = {
  isModal:false,
  statusCode:'',
};

const Auth = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SET_SERVER_ERROR:
      return setServerError(state, payload);
    case ActionTypes.RESET_SERVER_ERROR:
      return resetServerError(state, payload);
    default:
      return state;
  }
};

const setServerError = (state, payload) => {
  state = Object.assign({
    isModal: payload.isModal,
    statusCode: payload.statusCode
  });
  return state;
};

const resetServerError = (state, payload) => {
  state = Object.assign({ isModal:false , statusCode: "" });
  return state;
};

export default Auth;
