import React from "react";
import { Row, Col } from "reactstrap";
import { LoadedSurveyData } from "../../../../contants/LoadedSurveyData/LoadedSurveyData";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import Button from "../../../../component/common/button/button";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import api from "../../../../apiServices/index";
import * as Actions from "../../../../store/Actions/index";
import { connect } from "react-redux";
// import Model from "../../../../component/common/model/model";

class LoadedSurveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subHeaderHeight: 0,
      showMore: false,
      columnAdded: false,
      data: LoadedSurveyData,
      isLoading: false,
    };
  }

  componentWillMount() {
    this.getLoadedSurveysData();
  }

  getLoadedSurveysData = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    let id = this.props.match.params.id;
    this.props.dispatch(Actions.setParamsId(id));
    api
      .getWithToken(`/all/surveys/${id}/fittings`)
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          data.data = responseData;

          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  componentWillUnmount() {
    this.props.dispatch(Actions.ResetParamsId());
    this.props.dispatch(Actions.resetLoadedCompanyData());
  }

  componentDidMount() {}

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/fitting/${data._id}`)
      .then((response) => {
        // const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getLoadedSurveysData();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleUpdate = (data) => {
    let body = {
      name: data.name,
      type:
        data.type === "Linear fitting" ? "Linear fitting" : "Circular fitting",
      length: data.length,
      width: data.width,
      height: data.height,
      diameter: data.diameter,
      cut_hole_size: data.cut_hole_size,
      quantity: data.quantity,
      bulb_quantity_per_fitting: data.bulb_quantity_per_fitting,
      operating_time_per_year: data.operating_time_per_year,
      power: data.power,
      old_watt: data.old_watt,
      location: data.location,
      new_fitting_name: data.new_fitting_name,
      emergency_fitting: data.emergency_fitting,
      new_fitting_power: data.new_fitting_power,
      new_quantity: data.new_quantity,
      fitting_cost: data.fitting_cost,
      link_reference: data.link_reference,
      labour_cost: data.labour_cost,
      accessories_plan_cost: data.accessories_plan_cost,
      verify: data.verify,
      rrp: data.rrp,
      new_operating_time_per_year: data.new_operating_time_per_year,
    };

    this.setState({ isLoading: true });
    api
      .postWithToken(`/survey/${data.survey_id}/fitting/${data._id}`, body)
      .then((response) => {
        // const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getLoadedSurveysData();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  columnAdded = () => {
    this.setState({ columnAdded: true });
  };

  showMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    //eslint-disable-next-line
    let { subHeaderHeight } = this.state;
    // eslint-disable-next-line
    subHeaderHeight = subHeaderHeight + 92;
    return (
      <div>
        <div className="content" style={{ marginTop: "110px" }}>
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box " style={{ height: "150%" }}>
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img src={surveysIcon} alt="survey" /> Fittings
                        </header>
                      }
                      NoDataMessage={"Users have not created any Fittings yet"}
                      columnAdded={this.columnAdded}
                      HandleshowMore={this.showMore}
                      RowEdit={true}
                      RowDelete={true}
                      handleDelete={this.handleDelete}
                      handleUpdate={this.handleUpdate}
                      showMore={this.state.showMore}
                      data={this.state.data}
                      toolbar={true}
                      {...this.props}
                    />

                    <div
                      className="more-link-container"
                      style={{ marginTop: "10px" }}
                    >
                      <div style={{ opacity: "0" }}></div>
                      {!this.state.showMore && !this.state.columnAdded ? (
                        <Button
                          long={true}
                          onClick={this.showMore}
                          className="more-link"
                        >
                          {this.state.showMore ? "Hide" : "Show More"}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(LoadedSurveys);
