const menuStyle = "black";
const menuType = "dark";

const topbarStyle = "white";

const topbarType = "light";

const navWidth = "default";

const chatType = "overlap";

const chatWidth = "closed";

export {
  menuStyle,
  topbarStyle,
  menuType,
  topbarType,
  navWidth,
  chatWidth,
  chatType
};
