import React from "react";
import { Row, Col } from "reactstrap";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextInput/textinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faUser,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import {} from "@fortawesome/free-regular-svg-icons";
import api from "../.././../../apiServices/index";

import TextInputWithButton from "../../../../component/common/textinputwithbutton/textinputwithbutton";

class CreateUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      creadeatial: {
        username: "",
        password: "",
      },
      noOfUsers: {
        count: null,
      },

      isPasswordLoading: false,
      isUserNameLoading: false,
      LoaderForMultipleUser: false,
      LoaderForSingleUser: false,
      apiSuccess: false,
      userError: false,
      apiError: false,
      apiErrorC: false,
      apiSuccessC: false,

      apiErrorM: false,
      apiSuccessM: false,
      userCountError: false,
      showPassword: false,
      countryArr: [],

      page: 0,
    };

    this.flagsRef = React.createRef();
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  handleSingleUserSubmit = () => {
    let { creadeatial } = this.state;

    creadeatial.username = creadeatial.username.trim();
    this.setState({
      apiError: false,
      apiSuccess: false,
      LoaderForSingleUser: true,
    });
    api
      .postWithToken("/appUser", creadeatial)
      .then((response) => {
        const {  status } = response;
        if (status === 200) {
          this.setState({ apiSuccess: true, LoaderForSingleUser: false });
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, LoaderForSingleUser: false },
          () => {}
        );
      });
  };

  handleChangeCount = (e) => {
    let { noOfUsers } = this.state;
    const { value, name } = e.target;
    noOfUsers[name] = value;
    this.setState({ noOfUsers, apiSuccessM: false, userCountError: false });
  };

  handleMultipleUserSubmit = () => {
    this.setState({
      apiSuccessM: false,
      LoaderForMultipleUser: true,
      apiErrorM: false,
      userCountError: false,
    });
    const { noOfUsers } = this.state;

    if (noOfUsers.count >= 2 && noOfUsers.count <= 200) {
      api
        .getParamsWithToken("/bulk/user", noOfUsers)
        .then((response) => {
          const {  status } = response;
          if (status === 200) {
            this.setState({ apiSuccessM: true, LoaderForMultipleUser: false });
          }
        })
        .catch((error) => {
          const data = error.response.data;
          this.setState(
            {
              apiErrorM: true,
              apiErrorMData: data,
              LoaderForMultipleUser: false,
            },
            () => {}
          );
        });
    } else {
      this.setState({ LoaderForMultipleUser: false, userCountError: true });
    }
  };

  handleChange = (e) => {
    let { creadeatial } = this.state;
    const { value, name } = e.target;
    creadeatial[name] = value;
    this.setState({ creadeatial, apiError: false, apiSuccess: false });
  };

  HandleAutoGenerate = (field) => {
    if (field === "username") {
      this.setState({ isUserNameLoading: true });
    } else {
      this.setState({ isPasswordLoading: true });
    }

    let { creadeatial } = this.state;
    api
      .getParamsWithToken("/random/generator", {
        password_required: field === "username",
        username_required: field === "password",
        prefix: "user",
        count: "1",
      })
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          creadeatial[field] = data.data[0][field];
          this.setState({
            creadeatial,
            isPasswordLoading: false,
            isUserNameLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isUserNameLoading: false, isPasswordLoading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={4} md={4}>
              <div className="col-xl-12">
                {this.state.LoaderForSingleUser ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "98%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon size="1x" icon={faUser} /> Create single
                    user
                  </header>
                  <hr />
                  <div
                    className="content-body"
                    style={{
                      marginBottom:
                        this.state.apiSuccess || this.state.apiError
                          ? "20px"
                          : "0px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleFormSubmit}>
                        <Row>
                          <Col md={12} xs={12}>
                            <div
                              style={{ marginTop: "10px", marginRight: "0px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Username
                              </div>
                              <div>
                                <TextInputWithButton
                                  isLoading={this.state.isUserNameLoading}
                                  style={{ display: "none" }}
                                  search={false}
                                  value={this.state.creadeatial.username}
                                  onChange={this.handleChange}
                                  name="username"
                                  id="username"
                                  onButtonClick={() => {}}
                                  button={true}
                                ></TextInputWithButton>

                                <TextInputWithButton
                                  isLoading={this.state.isUserNameLoading}
                                  search={false}
                                  value={this.state.creadeatial.username}
                                  onChange={this.handleChange}
                                  name="username"
                                  id="username"
                                  onButtonClick={() => {
                                    this.HandleAutoGenerate("username", true);
                                  }}
                                  btnLable="Auto Generate"
                                  button={true}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faUser}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={12} xs={12}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Password
                              </div>
                              <div>
                                <TextInputWithButton
                                  search={false}
                                  isLoading={this.state.isPasswordLoading}
                                  value={this.state.creadeatial.password}
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  name="password"
                                  onChange={this.handleChange}
                                  btnLable="Auto Generate"
                                  onButtonClick={() => {
                                    this.HandleAutoGenerate("password");
                                  }}
                                  button={true}
                                  endIcon={
                                    this.state.showPassword ? (
                                      <FontAwesomeIcon
                                        onClick={this.handleShowPassword}
                                        size="1x"
                                        style={{ marginRight: 10 }}
                                        icon={faEye}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        onClick={this.handleShowPassword}
                                        size="1x"
                                        style={{ marginRight: 10 }}
                                        icon={faEyeSlash}
                                      />
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faLock}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                          <Button
                            onClick={this.handleSingleUserSubmit}
                            full={true}
                            disabled={
                              this.state.creadeatial.username === "" ||
                              this.state.creadeatial.password === ""
                            }
                          >
                            CREATE
                          </Button>
                        </div>
                      </form>
                    </div>

                    <p
                      style={{
                        marginTop: "20px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {this.state.apiError && (
                        <div className="error-message-box">
                          {this.state.apiErrorData.error}
                        </div>
                      )}
                    </p>

                    <p
                      style={{
                        marginTop: "20px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {this.state.apiSuccess && (
                        <div className="success-message-box">
                          User created successfully.
                        </div>
                      )}
                    </p>
                  </div>
                </section>
              </div>

              <div className="col-xl-12">
                {this.state.LoaderForMultipleUser ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "98%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    Create multiple users
                  </header>
                  <hr />
                  <div
                    className="content-body"
                    style={{ marginBottom: "20px" }}
                  >
                    <div
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleFormSubmit}>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{
                              marginLeft: "10px",
                              marginBottom: "5px",
                            }}
                          >
                            No of users
                          </div>
                          <div
                            style={{
                              marginLeft: "10px",
                              paddingRight: "5px",
                            }}
                          >
                            <TextInput
                              type="number"
                              search={false}
                              onChange={this.handleChangeCount}
                              value={this.state.noOfUsers.count}
                              name="count"
                            >
                              <FontAwesomeIcon
                                onClick={this.handleEdit}
                                size="1x"
                                icon={faUser}
                              />
                            </TextInput>
                          </div>
                        </div>

                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                          <Button
                            disabled={this.state.noOfUsers.count == null}
                            onClick={this.handleMultipleUserSubmit}
                            full={true}
                          >
                            CREATE
                          </Button>
                        </div>
                      </form>

                      <p style={{ marginTop: "20px" }}>
                        {this.state.apiSuccessM && (
                          <div className="success-message-box">
                            Users created successfully.
                          </div>
                        )}
                      </p>

                      <p
                        style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        {this.state.apiErrorM && (
                          <div className="error-message-box">
                            {this.state.apiErrorMData.error}
                          </div>
                        )}
                      </p>

                      <p
                        style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        {this.state.userCountError && (
                          <div className="error-message-box">
                            Minimum 2 And Maximum 200 Users Are Creatable.
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateUsers;
