import React from "react";
import Render from "../../component/common/commonRender/commonRender";
// import { AdminTypeString, AdminString } from "../constantVariable";

export const getallVideoTutorial = () => {
  return {
    Header: [
      {
        title: "Title",
        field: "title",
        validate: (rowData) => "Title is required",
        render: (rowData) => <Render>{rowData.title}</Render>,
      },
      {
        title: "Video Link",
        field: "video_link",
        editable: "never",
        render: (rowData) => <Render>{rowData.video_link}</Render>,
      },
    ],
    data: [],
  };
};
