import React from "react";
import { Nav } from "reactstrap";
import "./sidebar.css";
// import Button from "../../component/common/button/button";
import dashboardactiveIcon from "../../assets/icons/Group 21.png";
import AllsurveysunactiveIcon from "../../assets/icons/Group2.png";
import AllsurveysactiveIcon from "../../assets/icons/allsurveysgreen.png";
import CustomerunactiveIcon from "../../assets/icons/Group3.png";
import CustomeractiveIcon from "../../assets/icons/user-green.png";
import SchedualanactiveIcon from "../../assets/icons/Schedualunactive.png";
import SchedualactiveIcon from "../../assets/icons/Schedualactive.png";
import QuatationunactiveIcon from "../../assets/icons/Group4.png";
import QuatationactiveIcon from "../../assets/icons/quatationgreen.png";
import VideoActive from "../../assets/icons/videoactive.png";
import VideoUnactive from "../../assets/icons/videounactive.png";
// import TermsInactiveIcon from "../../assets/icons/termsinactive.png";
// import TermsactiveIcon from "../../assets/icons/termsactive.png";
import dashboardunactiveIcon from "../../assets/icons/dashboardunactive.png";
import {
  // AdminString,
  AdminTypeString,
  SuperAdminString,
} from "../../contants/constantVariable";
import api from "../../apiServices/index";
import Navitem from "../../component/common/navitem/navitem";
import SweetAlert from "react-bootstrap-sweetalert";
import Logo from "../../assets/img/newlogo.png";
import LogoutIcon from "../../../src/assets/icons/exit.png";
import { connect } from "react-redux";
import { Button } from "reactstrap";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyLogo: "",
      LogoutModal: false,
    };
    this.logout_modal = this.logout_modal.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentWillMount = async () => {
    api
      .getWithToken("/myProfile")
      .then((response) => {
        const data = response.data.data.company_logo;
        this.setState({ CompanyLogo: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleLogoClick = () => {
    const { history } = this.props;
    history.push(`/${localStorage.getItem("main_route")}/dashboard`);
  };

  logout_modal() {
    this.setState((prevState) => ({
      LogoutModal: !prevState.LogoutModal,
    }));
  }

  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  handleLogout = () => {
    const { history } = this.props;
    localStorage.setItem("access_token", "");
    localStorage.setItem("admin_type", "");
    localStorage.setItem("U_ID", "");
    localStorage.setItem("username", "");
    localStorage.setItem("email", "");
    localStorage.clear();
    history.push("/login");
  };

  render() {
    return (
      <div className="sidebar menubar" data-color="black">
        <div className="logo">
          <img
            onClick={this.handleLogoClick}
            src={this.state.CompanyLogo ? this.state.CompanyLogo : Logo}
            // src={this.state.CompanyLogo}
            style={{ textAlign: "center", width: "200px" }}
            alt="company-logo"
          />
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav className="navigation" style={{ marginBottom: "30px" }}>
            <Navitem
              icon={dashboardunactiveIcon}
              aicon={dashboardactiveIcon}
              {...this.props}
              linkName={"dashboard"}
              routeName="dashboard"
              linkTo={`/${localStorage.getItem("main_route")}/dashboard`}
              color="white"
              acolor="#1ab394"
            />
            {localStorage.getItem(AdminTypeString) !== SuperAdminString ? (
              <Navitem
                icon={VideoUnactive}
                aicon={VideoActive}
                {...this.props}
                linkTo={`/${localStorage.getItem("main_route")}/Tutorial`}
                routeName={"Tutorial"}
                linkName={"Tutorial"}
                color="white"
                acolor="#1ab394"
              />
            ) : null}

            <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
              &nbsp;
            </div>
            <div>
              <div className="sub-header-title">Surveys</div>
              <Navitem
                aicon={AllsurveysactiveIcon}
                icon={AllsurveysunactiveIcon}
                {...this.props}
                linkTo={`/${localStorage.getItem(
                  "main_route"
                )}/lighting surveys`}
                linkName={"Lighting Surveys"}
                routeName="lighting surveys"
                childScreen="loaded-surveys"
                color="white"
                acolor="#1ab394"
              />

              <Navitem
                aicon={SchedualactiveIcon}
                icon={SchedualanactiveIcon}
                {...this.props}
                linkTo={`/${localStorage.getItem(
                  "main_route"
                )}/emergency lighting schedules`}
                linkName={"Emergency Lighting Schedules"}
                routeName="emergency lighting schedules"
                childScreen="loaded-schedules"
                color="white"
                acolor="#1ab394"
              />
              {localStorage.getItem(AdminTypeString) === SuperAdminString ? (
                <Navitem
                  icon={CustomerunactiveIcon}
                  aicon={CustomeractiveIcon}
                  linkName={"Companies Statistic"}
                  routeName="Companies Statistic"
                  {...this.props}
                  linkTo={`/${localStorage.getItem(
                    "main_route"
                  )}/Companies Statistic`}
                  color="white"
                  acolor="#1ab394"
                />
              ) : null}

              <Navitem
                icon={CustomerunactiveIcon}
                aicon={CustomeractiveIcon}
                linkName={"Users Statistic"}
                routeName="Users Statistic"
                {...this.props}
                linkTo={`/${localStorage.getItem(
                  "main_route"
                )}/Users Statistic`}
                color="white"
                acolor="#1ab394"
              />
            </div>

            <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
              &nbsp;
            </div>

            <div>
              <div className="sub-header-title">Quotations/Reports</div>
              <Navitem
                icon={QuatationunactiveIcon}
                aicon={QuatationactiveIcon}
                {...this.props}
                linkName={"lighting quotations"}
                routeName="lighting quotations"
                linkTo={`/${localStorage.getItem(
                  "main_route"
                )}/lighting quotations`}
                color="white"
                acolor="#1ab394"
              />

              <Navitem
                icon={AllsurveysunactiveIcon}
                aicon={AllsurveysactiveIcon}
                {...this.props}
                linkName={"Emergency Lighting Reports"}
                routeName="emergency lighting reports"
                linkTo={`/${localStorage.getItem(
                  "main_route"
                )}/emergency lighting reports`}
                color="white"
                acolor="#1ab394"
              />
            </div>
            <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
              &nbsp;
            </div>

            {localStorage.getItem(AdminTypeString) === SuperAdminString ? (
              <div>
                <div>
                  <div className="sub-header-title">Companies</div>
                  <Navitem
                    icon={AllsurveysunactiveIcon}
                    aicon={AllsurveysactiveIcon}
                    linkName={"Companies Profile"}
                    routeName="Companies Profile"
                    {...this.props}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/Companies Profile`}
                    color="white"
                    acolor="#1ab394"
                  />
                  <Navitem
                    icon={CustomerunactiveIcon}
                    aicon={CustomeractiveIcon}
                    {...this.props}
                    routeName="create company"
                    linkName={"create company"}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/create company`}
                    color="white"
                    acolor="#1ab394"
                  />
                </div>

                <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
                  &nbsp;
                </div>

                <div>
                  <div className="sub-header-title">Settings</div>
                  <Navitem
                    icon={AllsurveysunactiveIcon}
                    aicon={AllsurveysactiveIcon}
                    linkName={"Settings"}
                    routeName={"Default Settings"}
                    {...this.props}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/Default Settings`}
                    color="white"
                    acolor="#1ab394"
                  />
                </div>

                <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
                  &nbsp;
                </div>

                <div>
                  <div className="sub-header-title">Packages</div>
                  <Navitem
                    icon={AllsurveysunactiveIcon}
                    aicon={AllsurveysactiveIcon}
                    routeName={"Create Package"}
                    linkName={"Create Package"}
                    {...this.props}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/Create Package`}
                    color="white"
                    acolor="#1ab394"
                  />
                  {/* <Navitem
                    icon={AllsurveysunactiveIcon}
                    aicon={AllsurveysactiveIcon}
                    routeName={"Package config"}
                    linkName={"Package config"}
                    {...this.props}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/Package config`}
                  /> */}
                </div>

                <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
                  &nbsp;
                </div>

                <div>
                  <div className="sub-header-title">Products</div>
                  <Navitem
                    icon={AllsurveysunactiveIcon}
                    aicon={AllsurveysactiveIcon}
                    routeName={"all products"}
                    linkName={"all products"}
                    {...this.props}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/all products`}
                    color="white"
                    acolor="#1ab394"
                  />
                  {/* <Navitem
                    icon={CustomerunactiveIcon}
                    aicon={CustomeractiveIcon}
                    {...this.props}
                    linkName={"create item"}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/create item`}
                  /> */}
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <div className="sub-header-title">Users</div>
                  <Navitem
                    icon={CustomerunactiveIcon}
                    aicon={CustomeractiveIcon}
                    {...this.props}
                    routeName={"User Profile"}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/User Profile`}
                    linkName={"User Profile"}
                    color="white"
                    acolor="#1ab394"
                  />
                  <Navitem
                    icon={CustomerunactiveIcon}
                    aicon={CustomeractiveIcon}
                    {...this.props}
                    linkTo={`/${localStorage.getItem(
                      "main_route"
                    )}/create users`}
                    routeName={"create users"}
                    linkName={"create user"}
                    color="white"
                    acolor="#1ab394"
                  />
                </div>

                <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
                  &nbsp;
                </div>
                <div className="sub-header-title">Company</div>
                <Navitem
                  icon={CustomerunactiveIcon}
                  aicon={CustomeractiveIcon}
                  {...this.props}
                  linkTo={`/${localStorage.getItem(
                    "main_route"
                  )}/Company Profile`}
                  routeName={"Company Profile"}
                  linkName={"Company Profile"}
                  color="white"
                  acolor="#1ab394"
                />

                <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
                  &nbsp;
                </div>
                <div className="sub-header-title">Packages & Plans</div>
                <Navitem
                  icon={AllsurveysunactiveIcon}
                  aicon={AllsurveysactiveIcon}
                  {...this.props}
                  linkTo={`/${localStorage.getItem(
                    "main_route"
                  )}/Current Package`}
                  routeName={"Current Package"}
                  linkName={"Current Package"}
                  color="white"
                  acolor="#1ab394"
                />
                <Navitem
                  icon={AllsurveysunactiveIcon}
                  aicon={AllsurveysactiveIcon}
                  {...this.props}
                  linkTo={`/${localStorage.getItem("main_route")}/Invoices`}
                  routeName={"Invoices"}
                  linkName={"Invoices"}
                  color="white"
                  acolor="#1ab394"
                />

                {/* <div className="sidebar-hr-line" style={{ marginTop: "10px" }}>
                  &nbsp;
                </div> */}
                {/* <div className="sub-header-title"></div> */}
                {/* <Navitem
                  icon={TermsInactiveIcon}
                  aicon={TermsactiveIcon}
                  {...this.props}
                  linkTo={`/${localStorage.getItem(
                    "main_route"
                  )}/terms & condition`}
                  linkName={"terms & condition"}
                /> */}
              </div>
            )}
          </Nav>
          <div>
            <Button
              onClick={this.logout_modal}
              style={{
                backgroundColor: "#FFF",
                height: "35px",
                width: "150px",
                color: "#1ab394",
              }}
              // full={true}
            >
              LOG OUT
            </Button>

            <SweetAlert
              custom
              btnSize="lg"
              show={this.state.LogoutModal}
              showCancel
              confirmBtnText="Yes"
              cancelBtnText="No"
              customIcon={LogoutIcon}
              title={
                <span style={{ fontSize: 20 }} className="text-center">
                  Are you sure you want to Logout?
                </span>
              }
              onCancel={() => this.logout_modal(false)}
              onConfirm={this.handleLogout}
            ></SweetAlert>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ParamsId: state.ParamsId,
  };
};

export default connect(mapStateToProps)(Sidebar);
