import React from "react";
import { Row, Col } from "reactstrap";

import Logo from "../../../assets/img/newlogo.png";
import ReeValidate from "ree-validate";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLightbulb,
//   faTimesCircle,
//   faCheckCircle,
// } from "@fortawesome/free-regular-svg-icons";
import api from "../../../apiServices/index";
import { connect } from "react-redux";
// import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";
// import { blue } from "@material-ui/core/colors";
// import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import AdminFormInput from "../firstTimeAdminLogin/AdminFormInputs";
import { CountryDropdown } from "react-country-region-selector";
// import cc from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";
import LocaleCurrency from "locale-currency";

class RegisterProfessional extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "email",
      password: "min:6",
      confirm_password: "min:6",
      //eslint-disable-next-line
      ["Mobile No"]: "min:5|max:18|numeric",
      //eslint-disable-next-line
      ["Phone No"]: "min:5|max:18|numeric",
    });

    this.state = {
      creadiantial: {
        company_name: "",
        fullname: "",
        email: "",
        company_address: "",
        //eslint-disable-next-line
        ["Mobile No"]: "",
        //eslint-disable-next-line
        ["Phone No"]: "",
        username: "",
        password: "",
        confirm_password: "",
        country: "IE",
        currency_code: "EUR",
        currency_symbol: "€",
      },

      fields: {
        company_name: false,
        fullname: false,
        email: false,
        company_address: false,
        //eslint-disable-next-line
        ["Mobile No"]: false,
        //eslint-disable-next-line
        ["Phone No"]: false,
        username: false,
        password: false,
        confirm_password: false,
      },

      showPassword: false,
      showConfirmPassword: false,

      Ischecked: false,
      apiError: false,
      apiErrorData: {
        code: 0,
        error: "",
      },
      apiError1: false,
      isSubmitting: false,
      errors: this.validator.errors,
      isLoading: false,
      isMobileValid: true,
      isPhoneValid: true,
    };
  }

  handleCountyChange = (country) => {
    let { creadiantial } = this.state;
    creadiantial.country = country;

    const CurrencyCode = LocaleCurrency.getCurrency(country);

    creadiantial.currency_code = CurrencyCode;
    const symbol = getSymbolFromCurrency(CurrencyCode);

    creadiantial.currency_symbol = symbol;
    this.setState({ creadiantial });
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;
    let { fields } = this.state;
    fields[name] = true;
    creadiantial[name] = value;
    errors.remove(name);
    this.setState({
      creadiantial,
      fields,
      apiError: false,
      apiError1: false,
      // isSubmitting:false,
    });
  };

  trimValues = () => {
    let { creadiantial } = this.state;
    creadiantial.company_name = creadiantial.company_name.trim();
    creadiantial.fullname = creadiantial.fullname.trim();
    creadiantial.email = creadiantial.email.trim();
    creadiantial.company_address = creadiantial.company_address.trim();
    creadiantial["Mobile No"] = creadiantial["Mobile No"].trim();
    creadiantial["Phone No"] = creadiantial["Phone No"].trim();
    creadiantial.username = creadiantial.username.trim();
    return creadiantial;
  };

  checkNumbers = () => {
    const { creadiantial } = this.state;
    let isMobileValid = isValidPhoneNumber(creadiantial.mobile_no);
    let isPhoneValid = isValidPhoneNumber(creadiantial.phone_no);
    this.setState({ isMobileValid, isPhoneValid });

    if (isMobileValid && isPhoneValid) {
      return true;
    } else {
      return false;
    }
  };

  setFieldChangeFalse = () => {
    let { fields } = this.state;
    fields.company_name = false;
    fields.fullname = false;
    fields.email = false;
    fields.company_address = false;
    fields["Mobile No"] = false;
    fields["Phone No"] = false;
    fields.username = false;
    fields.password = false;
    fields.confirm_password = false;

    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true, isLoading: true });
    this.setFieldChangeFalse();
    let creadiantial = this.trimValues();
    // let isNumbersValid = this.checkNumbers();
    const { errors } = this.validator;
    const { history } = this.props;

    this.validator.validateAll(creadiantial).then((success) => {
      if (success) {
        const body = {
          company_name: creadiantial.company_name,
          fullname: creadiantial.fullname,
          email: creadiantial.email,
          company_address: creadiantial.company_address,
          mobile_no: creadiantial["Mobile No"],
          phone_no: creadiantial["Phone No"],
          username: creadiantial.username,
          password: creadiantial.password,
          confirmPassword: creadiantial.confirm_password,
          country: creadiantial.country,
          currency_code: creadiantial.currency_code,
          currency_symbol: creadiantial.currency_symbol,
        };
        api
          .postWithoutToken("/admin/register", body)
          .then((response) => {
            console.log(response);
            const { status } = response;
            if (status === 200) {
              history.push("/verify-link-admin");
            }
          })
          .catch((error) => {
            const data = error.response.data;
            console.log("data",data);
            this.setState(
              { apiError: true, isLoading: false, apiErrorData: data },
              () => {}
            );
          });
      } else {
        this.setState({ errors, isLoading: false });
      }
    });
  };

  handleChangeCheckbox = () => {
    this.setState({ Ischecked: !this.state.Ischecked });
  };

  handleTermLink = (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    const { history, match } = this.props;
    let url = window.location.href;
    url = url.split("/register-professional")[1];
    window.open(`${url}/terms-and-condition`);
  };

  ErrorMessage = (message) => (
    <p style={{ marginTop: "20px" }}>
      <div className="error-message-box">{message}</div>
    </p>
  );

  registerBtnCondition = () => {
    return (
      this.state.creadiantial.company_name === "" ||
      this.state.creadiantial.fullname === "" ||
      this.state.creadiantial.email === "" ||
      this.state.creadiantial.company_address === "" ||
      this.state.creadiantial["Mobile No"] === "" ||
      this.state.creadiantial["Phone No"] === "" ||
      this.state.creadiantial.username === "" ||
      this.state.creadiantial.password === "" ||
      this.state.creadiantial.confirm_password === "" ||
      this.state.Ischecked === false
    );
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    {this.state.isLoading ? (
                      <div className="loader-container-2">
                        <div
                          className="spinner-border text-secondary LoginpageLoader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : null}

                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>
                    <h2
                      style={{
                        textAlign: "center",
                        opacity: "0.5",
                        textTransform: "uppercase",
                      }}
                    >
                      register as professional
                    </h2>
                    <form onSubmit={this.handleSubmit} method="POST">
                      <AdminFormInput
                        value={this.state.creadiantial.company_name}
                        handleChange={this.handleChange}
                        label={"Company Name"}
                        type="text"
                        placeholder="Ex: Led Me"
                        name="company_name"
                        id="company_name"
                        isFieldChange={this.state.fields.company_name}
                        isSubmitting={this.state.isSubmitting}
                      />
                      <AdminFormInput
                        value={this.state.creadiantial.fullname}
                        handleChange={this.handleChange}
                        label={"Full Name"}
                        type="text"
                        placeholder="Ex: John Wick"
                        name="fullname"
                        id="fullname"
                        isFieldChange={this.state.fields.fullname}
                        isSubmitting={this.state.isSubmitting}
                      />
                      <AdminFormInput
                        value={this.state.creadiantial.email}
                        handleChange={this.handleChange}
                        label={"E-Mail"}
                        type="text"
                        placeholder="Ex: test@yahoo.com"
                        name="email"
                        id="email"
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields.email}
                        hasError={errors.has("email")}
                      />
                      <AdminFormInput
                        value={this.state.creadiantial.company_address}
                        handleChange={this.handleChange}
                        label={"Address"}
                        type="text"
                        placeholder="Ex: 2071 Green Avenue Fremont CA - 94539"
                        name="company_address"
                        id="company_addres"
                        isFieldChange={this.state.fields.company_address}
                        isSubmitting={this.state.isSubmitting}
                      />
                      <p>
                        <label>
                          Country
                          <CountryDropdown
                            classes="mb-4 mt-1 country-picker form-control"
                            valueType="short"
                            labelType="full"
                            name="Country"
                            value={this.state.creadiantial.country}
                            onChange={(val) => {
                              this.handleCountyChange(val);
                            }}
                          />
                        </label>
                      </p>
                      <AdminFormInput
                        value={this.state.creadiantial["Mobile No"]}
                        handleChange={this.handleChange}
                        label={"Mobile No"}
                        type="text"
                        placeholder="Ex:1234567890"
                        name="Mobile No"
                        id="Mobile No"
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields["Mobile No"]}
                        hasError={errors.has("Mobile No")}
                      />
                      {/* <AdminFormInput
                        value={this.state.creadiantial.mobile_no}
                        handleChange={(mobile_no) => {
                          let { creadiantial } = this.state;
                          let { fields } = this.state;
                          if (mobile_no === undefined) {
                            creadiantial.mobile_no = "";
                          } else {
                            creadiantial.mobile_no = mobile_no;
                          }
                          fields.mobile_no = true;
                          this.setState({
                            creadiantial,
                            fields,
                            isMobileValid: true,
                          });
                        }}
                        label={"Mobile No"}
                        isPhoneInput={true}
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields.mobile_no}
                        hasError={!this.state.isMobileValid}
                      /> */}
                      <AdminFormInput
                        value={this.state.creadiantial["Phone No"]}
                        handleChange={this.handleChange}
                        label={"Phone No"}
                        type="text"
                        placeholder="Ex:1234567890"
                        name="Phone No"
                        id="Phone No"
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields["Phone No"]}
                        hasError={errors.has("Phone No")}
                      />
                      {/* <AdminFormInput
                        value={this.state.creadiantial.phone_no}
                        handleChange={(phone_no) => {
                          let { creadiantial } = this.state;
                          let { fields } = this.state;
                          if (phone_no === undefined) {
                            creadiantial.phone_no = "";
                          } else {
                            creadiantial.phone_no = phone_no;
                          }
                          fields.phone_no = true;
                          this.setState({
                            creadiantial,
                            fields,
                            isPhoneValid: true,
                          });
                        }}
                        label={"Phone No"}
                        isPhoneInput={true}
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields.phone_no}
                        hasError={!this.state.isPhoneValid}
                      /> */}
                      <AdminFormInput
                        value={this.state.creadiantial.username}
                        handleChange={this.handleChange}
                        label={"User Name"}
                        type="text"
                        placeholder="Ex: John"
                        name="username"
                        id="username"
                        isFieldChange={this.state.fields.username}
                        isSubmitting={this.state.isSubmitting}
                      />
                      <AdminFormInput
                        value={this.state.creadiantial.password}
                        handleChange={this.handleChange}
                        label={"Password"}
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Ex: 123456"
                        name="password"
                        id="password"
                        isFieldChange={this.state.fields.password}
                        isSubmitting={this.state.isSubmitting}
                        isPasswordField={true}
                        showPassword={this.state.showPassword}
                        handleShowPassword={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        hasError={errors.has("password")}
                      />
                      <AdminFormInput
                        value={this.state.creadiantial.confirm_password}
                        handleChange={this.handleChange}
                        label={"Confirm Password"}
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        placeholder="Ex: 123456"
                        name="confirm_password"
                        id="confirm_password"
                        isFieldChange={this.state.fields.confirm_password}
                        isSubmitting={this.state.isSubmitting}
                        hasError={errors.has("confirm_password")}
                        isPasswordField={true}
                        showPassword={this.state.showConfirmPassword}
                        handleShowPassword={() => {
                          this.setState({
                            showConfirmPassword:
                              !this.state.showConfirmPassword,
                          });
                        }}
                      />
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          value={"checkbox1"}
                          checked={this.state.Ischecked}
                          key={"test1"}
                          onChange={this.handleChangeCheckbox}
                          name={"checkbox1"}
                          label={"dispaly name"}
                          ref="check_me"
                        />

                        <label
                          className="custom-control-label"
                          for="customCheck1"
                        >
                          By clicking, you agree to Ledme's &nbsp;
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            style={{ display: "inline-flex" }}
                            href=""
                            onClick={this.handleTermLink}
                          >
                            <p style={{ color: "blue" }}>
                              Terms and Conditions
                            </p>
                          </a>
                        </label>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                            // backgroundColor: "red",
                          }}
                        >
                          <p className="submit">
                            <input
                              disabled={this.registerBtnCondition()}
                              style={{ fontFamily: "roboto-regular" }}
                              onSubmit={this.handleSubmit}
                              type="submit"
                              name="wp-submit"
                              id="wp-submit"
                              className="btn btn-accent"
                              value="Register"
                            />
                          </p>
                        </label>
                      </div>
                    </form>

                    {errors.has("email") &&
                      this.ErrorMessage(
                        "Wrong E-Mail Format. Please enter valid E-mail."
                      )}

                    {errors.has("password") || errors.has("confirm_password")
                      ? this.ErrorMessage(
                          "Please enter minimum 6 character password."
                        )
                      : null}

                    {errors.has("Mobile No") &&
                      this.ErrorMessage(errors.first("Mobile No"))}

                    {errors.has("Phone No") &&
                      this.ErrorMessage(errors.first("Phone No"))}

                    {/* 
                    {!this.state.isMobileValid &&
                      this.ErrorMessage("Invalid Mobile No.")}

                    {!this.state.isPhoneValid &&
                      this.ErrorMessage("Invalid Phone No.")} */}

                    {this.state.apiError &&
                      this.ErrorMessage(this.state.apiErrorData.error)}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(RegisterProfessional);
