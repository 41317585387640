import React from "react";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import moment from "moment";



class CustomDatePicker extends React.Component {
  render() {
    const { selectedDate, onChange } = this.props;

    return (
      <TextField
        type="date"
        defaultValue={selectedDate}
        onChange={(e) => {
          let date = new Date(e.target.value);
          onChange(date);
        }}
        InputProps={{ inputProps: { min: moment(new Date()).format("YYYY-MM-DD") } }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
}

export default CustomDatePicker;
