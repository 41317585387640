import React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import Dashboard from "./dashboard/index";
import Layout from "../../../layout/ClientAppLayout";
import AllSurveys from "../client/AllSurveys/AllSurveys";
// import AllCustomers from "../client/allcustomers/allcustomers";
import QuatationReports from "../client/quatationReport/quatationreports";
import LoadedSurveys from "../client/Loaded  Surveys/LoadedSurveys";
import AllUsers from "../client/allusers/index";
import CreateUsers from "./createUsers/createUsers";
import Users from "./users/users";
import Profile from "./profile/profile";
import Terms from "./termsandcondition/termsandcondition";
import CurrentPackage from "./CurrentPackage";
import PlanExpireModal from "../../../component/common/model/PlanExpireModal";
import Invoices from "./Invoices";
import VideoTutorial from "../client/videoTutorial/VideoTutorial";
import AllSchedules from "../client/AllSchedules/AllSchedules";
import LoadedSchedules from "../client/Loaded Schedules/LoadedSchedule";
import ScheduleReport from "../client/scheduleReport/scheduleReport";

class AdminRoutes extends React.Component {
  render() {
    const { match, history } = this.props;
    return (
      <Layout>
        <PlanExpireModal history={history} />
        <Switch>
          <Redirect
            exact
            from={`${match.url}/`}
            to={`${match.url}/dashboard`}
          />
          <Route path={`${match.url}/dashboard`} exact component={Dashboard} />
          <Route
            path={`${match.url}/lighting surveys`}
            exact
            component={AllSurveys}
          />
          <Route
            path={`${match.url}/emergency lighting schedules`}
            exact
            component={AllSchedules}
          />
          <Route
            path={`${match.url}/Users Statistic`}
            exact
            component={AllUsers}
          />
          <Route
            path={`${match.url}/lighting quotations`}
            exact
            component={QuatationReports}
          />
          <Route
            path={`${match.url}/emergency lighting reports`}
            exact
            component={ScheduleReport}
          />
          <Route exact path={`${match.url}/User Profile`} component={Users} />
          <Route
            exact
            path={`${match.url}/create users`}
            component={CreateUsers}
          />
          <Route
            path={`${match.url}/loaded-surveys/:id?`}
            exact
            component={LoadedSurveys}
          />
          <Route
            path={`${match.url}/loaded-schedules/:id?`}
            exact
            component={LoadedSchedules}
          />
          <Route
            path={`${match.url}/Company Profile`}
            exact
            component={Profile}
          />
          <Route
            path={`${match.url}/Current Package`}
            exact
            component={CurrentPackage}
          />
          <Route path={`${match.url}/Invoices`} exact component={Invoices} />
          <Route
            path={`${match.url}/terms & condition`}
            exact
            component={Terms}
          />
          <Route
            path={`${match.url}/Tutorial`}
            exact
            component={VideoTutorial}
          />

          <Redirect to="/404" />
        </Switch>
      </Layout>
    );
  }
}

export default withRouter(AdminRoutes);
