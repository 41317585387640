import React from "react";
import { Row, Col } from "reactstrap";
import "../login/login.css";
import Logo from "../../../assets/img/newlogo.png";

// import {
//   faLightbulb,
//   faTimesCircle,
// } from "@fortawesome/free-regular-svg-icons";
import api from "../../../apiServices/index";

class VerifyAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apiError: false,
      apiErrorData: {
        code: null,
        error: "",
      },
      apiResponseData: {
        code: null,
        data: "",
      },
    };
  }

  componentWillMount() {
    let token = this.props.location.search;
    token = token.split("?token=")[1];

    api
      .getWithoutToket(`/verify/token/${token}`)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          this.setState({ apiResponseData: data });
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState({ apiError: true, apiErrorData: data }, () => {});
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { history } = this.props;
    history.push("/login");
  };
  render() {
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row m-2">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-sm-3 col-sm-6 col-12 col-md-6 col-xl-4"
                  >
                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>

                    <form
                      name="loginform"
                      id="loginform"
                      action="#!"
                      method="post"
                    >
                      <p>
                        <h1 style={{ opacity: 1 }}>THANK YOU!</h1>
                        <div
                          style={{
                            color: "black",
                            textAlign: "center",
                            fontSize: "14px",
                            fontFamily: "roboto-regular",
                            opacity: "0.5",
                          }}
                        >
                          {this.state.apiResponseData.data}
                        </div>
                      </p>
                      <p className="submit">
                        <input
                          onClick={this.handleSubmit}
                          style={{ fontFamily: "roboto-regular" }}
                          type="button"
                          name="wp-submit"
                          id="wp-submit"
                          className="btn btn-accent "
                          value="Login"
                        />
                      </p>
                    </form>
                    <p>
                      {this.state.apiError && (
                        <div className="error-message-box">
                          {this.state.apiErrorData.error}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default VerifyAdmin;
