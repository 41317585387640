import React from "react";
import { Col, Row } from "reactstrap";
import ScheduleTable from "../../../../component/meterialTable/ScheduleTable";
import api from "../../../../apiServices/index";
import { getAllScheduleData } from "../../../../contants/AllSchedulesData/AllSchedulesData";
import Calendar from "../../../../assets/icons/calendar.png";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

class AllSchedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      activityData: {
        new_schedules: 0,
        total_schedules: 0,
      },
      sweetAlert: false,
    };
  }

  toggalSweetAlert = () => {
    this.setState({ sweetAlert: !this.state.sweetAlert });
  };

  onConfirm = () => {
    this.setState({ sweetAlert: false });
  };

  getActivityOverviewData = () => {
    api
      .getWithToken("/dashboard/activity/overview")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          if (response.data.data === "No Survey data found") {
          } else {
            this.setState({ activityData: responseData }, () => {
              this.getAllSchedules();
            });
          }
        }
      })
      .catch((error) => {});
  };

  getAllSchedules = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/schedule/recent")
      .then((res) => {
        const resData = res && res.data && res.data.data;
        if (res.status === 200) {
          resData.map((object, i) => {
            if (this.state.activityData.new_schedules > i) {
              object.is_recent_schedule = true;
            }
            return null;
          });
        }
        data.data = resData;
        this.setState({ data, isLoading: false }, () => {});
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  componentWillMount() {
    const AllScheduleData = getAllScheduleData();
    const columns = getAllScheduleData();
    this.setState({ data: AllScheduleData, columns });
    this.getActivityOverviewData();
  }

  handleUpdate = (data) => {
    if (Number(data.discount_percentage) < 1) {
      this.setState({
        sweetAlert: true,
        message: "Customer Discount should be between 1 to 100",
      });
    } else if (Number(data.discount_percentage) > 100) {
      this.setState({
        sweetAlert: true,
        message: "Customer Discount should be between 1 to 100",
      });
    } else if (Number(data.local_tax) < 1) {
      this.setState({
        sweetAlert: true,
        message: "Local Tax should be between 1 to 100",
      });
    } else if (Number(data.local_tax) > 100) {
      this.setState({
        sweetAlert: true,
        message: "Local Tax should be between 1 to 100",
      });
    } else {
      data.is_completed = data.is_completed.toString();
      data.is_project_completed = data.is_project_completed.toString();
      let body = {
        schedule_name: data.schedule_name,
        customer_name: data.customer_name,
        address: data.address,
        is_completed: data.is_completed === "true" ? true : false,
        data_fill: data.data_fill === "true" ? true : false,
        is_submitted: data.is_completed === "true" ? true : false,
        is_project_completed:
          data.is_project_completed === "true" ? true : false,
        discount_percentage: data.discount_percentage,
        testing_fee: data.testing_fee,
        local_tax: data.local_tax,
        upgrade_estimation: data.upgrade_estimation,
      };
      this.setState({ isLoading: true });
      api
        .putWithToken(`/schedule/${data.suvey_id}`, body)
        .then((res) => {
          this.setState({ is_loading: false }, () => {
            this.getAllSchedules();
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/schedule/${data.suvey_id}`)
      .then((res) => {
        this.setState({ isLoading: false }, () => {
          this.getActivityOverviewData();
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box">
                  <div className="content-body custom-pagination">
                    <ScheduleTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img
                            src={Calendar}
                            alt="schedule"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          {" Emergency Lighting Schedules"}
                        </header>
                      }
                      deleteTextMsg={
                        "Are you sure want to delete this schedule?"
                      }
                      NoDataMessage={"Users have not created any schedule yet"}
                      RowEdit={true}
                      RowDelete={true}
                      RowClickable={true}
                      handleUpdate={this.handleUpdate}
                      handleDelete={this.handleDelete}
                      data={this.state.data}
                      {...this.props}
                      toolbar={true}
                    />

                    {this.state.sweetAlert && (
                      <SweetAlert
                        custom
                        btnSize="lg"
                        show={this.toggalSweetAlert}
                        danger
                        title={
                          this.state.apiError
                            ? this.state.apiErrorData.error
                            : this.state.message
                        }
                        onConfirm={this.onConfirm}
                      ></SweetAlert>
                    )}
                  </div>
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {};

export default connect(mapStateToProps)(AllSchedules);
