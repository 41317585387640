import React, {  Component } from "react";
// import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import Button from "../../common/button/button";
import Modal from "./model";
import "./serverModal.css";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { setPlanExpireModal } from "../../../store/Actions";

class PlanExpireModal extends Component {
  //eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  handleClose = () => {
      const {history} = this.props
      this.props.dispatch(setPlanExpireModal({ isModal: false }))
      history.push(`/${localStorage.getItem("main_route")}/Current Package`)
  };

  render() {
    return (
      <div className="server-modal">
        <Modal isOpen={this.props.planExpire.isModal} style={{ width: 350 }}>
          <div className="server-modal-body">
            <div className="emoji-side">
              <div>
                <FontAwesomeIcon
                  size="5x"
                  icon={faFrown}
                  style={{ color: "#ffffff" }}
                />
              </div>
            </div>
            <div className="content-side">
              <div className="content-box">
                Sorry, Your plan is expired.Please purchase the plan.
              </div>
              <div>
                <button
                  full={true}
                  style={{ backgroundColor: "red" }}
                  onClick={this.handleClose}
                  className="server-modal-btn"
                >
                  Purchase
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ planExpire }) => {
  console.log("hi this is props of plan expired.")
  console.log(planExpire)
  return {
    planExpire,
  };
};

export default connect(mapStateToProps)(PlanExpireModal);
