import React from "react";
import Button from "../../component/common/button/button";
import Render from "../../component/common/commonRender/commonRender";
// import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddressModal from "./AddressModal";

import {
  AdminTypeString,
  AdminString,
  // SuperAdminString
} from "../constantVariable";

export const getAllScheduleData = () => {
  return {
    Header: [
      {
        title: "Company Name",
        field: "company_name",
        hidden: localStorage.getItem(AdminTypeString) === AdminString,
        editable: "never",
        render: (rowData) => (
          <div>
            {rowData.is_recent_schedule ? (
              <Button squre={true}>New</Button>
            ) : null}

            {"   "}
            <Render>{rowData.company_name}</Render>
          </div>
        ),
      },
      {
        title: "First Name",
        field: "first_name",
        editable: "never",
        render: (rowData) => (
          <div>
            {localStorage.getItem(AdminTypeString) === AdminString ? (
              rowData.is_recent_survey ? (
                <Button squre={true}>New</Button>
              ) : null
            ) : null}
            {"   "}
            <Render>{rowData.first_name}</Render>
          </div>
        ),
      },
      {
        title: "Last Name",
        field: "last_name",
        render: (rowData) => <Render>{rowData.last_name}</Render>,
        editable: "never",
      },
      {
        title: "E-Mail",
        render: (rowData) => <Render>{rowData.email}</Render>,
        field: "email",
        editable: "never",
      },
      {
        title: "Schedule Name",
        field: "schedule_name",
        render: (rowData) => <Render>{rowData.schedule_name}</Render>,
      },
      {
        title: "Customer Name",
        field: "customer_name",
        render: (rowData) => <Render>{rowData.customer_name}</Render>,
      },
      {
        title: "Last Test Status",
        field: "schedule_status",
        editable: "never",
        render: (rowData) => (
          <Render>
            {rowData.schedule_status === "Fitting Status Failed" ? (
              <span className="badge badge-danger">
                {rowData.schedule_status}
              </span>
            ) : rowData.schedule_status === "Fitting Status Not Tested Yet!" ? (
              <span className="badge badge-warning">
                {rowData.schedule_status}
              </span>
            ) : rowData.schedule_status === "Fitting Status Passed" ? (
              <span className="badge badge-success">
                {rowData.schedule_status}
              </span>
            ) : (
              "-"
            )}
          </Render>
        ),
      },
      {
        title: "Schedule Address",
        field: "address",
        editable: "never",
        render: (rowData) =>
          rowData.address ? (
            <AddressModal
              isEdit={true}
              scheduleId={rowData.suvey_id}
              surveyAddress={rowData.address}
              manualAddress={rowData.address}
            />
          ) : (
            "-"
          ),
      },
      {
        title: "Fitting Quantity",
        field: "scheduleFitting",
        render: (rowData) => <Render>{rowData.scheduleFitting}</Render>,
        editable: "never",
      },
      {
        title: "Schedule Date and Time",
        field: "created_at",
        editable: "never",
        render: (rowData) => <Render>{rowData.created_at}</Render>,
      },
      {
        title: "Upgrade Estimation",
        field: "upgrade_estimation",
        type: "number",
        render: (rowData) => <Render>{rowData.upgrade_estimation}</Render>,
      },
      {
        title: "Testing Fee",
        field: "testing_fee",
        type: "number",
        render: (rowData) => <Render>{rowData.testing_fee}</Render>,
      },
      {
        title: "Local Tax %",
        field: "local_tax",
        type: "number",
        render: (rowData) => <Render>{rowData.local_tax}</Render>,
      },
      {
        title: "Customer Discount %",
        field: "discount_percentage",
        type: "number",
        render: (rowData) => <Render>{rowData.discount_percentage}</Render>,
      },
      // {
      //   title: "Verified Schedules",
      //   field: "schedule_verified",
      //   render: (rowData) =>
      //     rowData.schedule_verified ? (
      //       <div style={{ color: "green" }}>Yes</div>
      //     ) : (
      //       <div style={{ color: "red" }}>No</div>
      //     ),
      //   editable: "schedule_verified",
      // },
      // {
      //   title: "Finalized Fitting",
      //   field: "data_fill",
      //   lookup: { true: "Yes", false: "No" },
      //   render: (rowData) => {
      //     return rowData.data_fill ? (
      //       <div style={{ color: "green" }}>Yes</div>
      //     ) : (
      //       <div style={{ color: "red" }}>No</div>
      //     );
      //   },
      // },
      // {
      //   title: "Schedule Completed",
      //   field: "is_completed",
      //   lookup: { true: "Yes", false: "No" },
      //   render: (rowData) => {
      //     return rowData.is_completed ? (
      //       <div style={{ color: "green" }}>Yes</div>
      //     ) : (
      //       <div style={{ color: "red" }}>No</div>
      //     );
      //   },
      // },
      // {
      //   title: "Project Completed",
      //   field: "is_project_completed",
      //   lookup: { true: "Yes", false: "No" },
      //   render: (rowData) => {
      //     return rowData.is_project_completed ? (
      //       <div style={{ color: "green" }}>Yes</div>
      //     ) : (
      //       <div style={{ color: "red" }}>No</div>
      //     );
      //   },
      // },
    ],
    data: [],
  };
};
