import React from "react";
import { Table } from "reactstrap";
import "./detailedOverviewTabel.css";
// import {
//   AdminTypeString,
//   AdminString,
//   SuperAdminString
// } from "../../contants/constantVariable";

class DetailOvervieTable extends React.Component {
  render() {
    const { activityData } = this.props;
    const currencySymbol =
      localStorage.getItem("currency_symbol") &&
      localStorage.getItem("currency_symbol") !== "" &&
      localStorage.getItem("currency_symbol") !== null &&
      localStorage.getItem("currency_symbol") !== undefined
        ? localStorage.getItem("currency_symbol")
        : "€";
    return (
      <div className="detail-overview-table">
        <Table>
          <tbody>
            <tr>
              <td className="detail-over-view-td">Total fittings</td>
              <td className="detailtablenumber">
                {activityData.total_fitting}{" "}
              </td>
            </tr>
            <tr>
              <td style={{ color: "#1ab394", fontSize: "12px" }}>
                Total Savings
              </td>
              <td className="detailtablenumber">
                {currencySymbol} {activityData.total_savings.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td style={{ color: "#1ab394", fontSize: "12px" }}>
                Total Energy Savings
              </td>
              <td className="detailtablenumber">
                {activityData.total_energy_savings.toFixed(2)} KWh
              </td>
            </tr>
            <tr>
              <td style={{ color: "#1ab394", fontSize: "12px" }}>
                Average Saving per customer
              </td>
              <td className="detailtablenumber">{`${currencySymbol} ${
                activityData.avarage_savings_per_customer
                  ? activityData.avarage_savings_per_customer.toFixed(2)
                  : 0
              }/m`}</td>
            </tr>
            <tr>
              <td style={{ color: "#1ab394", fontSize: "12px" }}>
                Average Saving per surveys
              </td>
              <td className="detailtablenumber">{`${currencySymbol} ${
                activityData.average_savings_per_survey
                  ? activityData.average_savings_per_survey.toFixed(2)
                  : 0
              }/m`}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DetailOvervieTable;
