import React from "react";
import { Row, Col } from "reactstrap";
import "../login/login.css";
import Logo from "../../../assets/img/newlogo.png";
import api from "../../../apiServices/index";

import ReeValidate from "ree-validate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLightbulb,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";

class VarifyPassword extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "email",
    });

    this.state = {
      creadiantial: {
        email: "",
      },
      apiError: false,
      isSubmitting: false,
      errors: this.validator.errors,
      isLoading: false,
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;
    creadiantial[name] = value;
    errors.remove(name);
    this.setState({ creadiantial, apiError: false, isSubmitting: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, apiError: false });
    let { creadiantial } = this.state;
    creadiantial.email = creadiantial.email.trim();
    const { errors } = this.validator;
    const { history } = this.props;
    errors.remove();
    this.validator.validateAll(creadiantial).then((success) => {
      if (success) {
        this.setState({ isSubmitting: true });
        setTimeout(() => {
          api
            .getWithoutToket("/forgot_password/email/" + creadiantial.email)
            .then((response) => {
              if (response.status === 200) {
                history.push("/email-reset-success");
              }
            })
            .catch((errors) => {
              const data = errors.response.data;
              if (data.code === 1) {
                this.setState({ apiError: true, isLoading: false });
              }
            });
        }, 500);
      } else {
        this.setState({ errors, isLoading: false });
      }
    });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    {this.state.isLoading ? (
                      <div className="loader-container">
                        <div
                          className="spinner-border text-secondary LoginpageLoader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : null}
                    <div className="logo-align">
                      <img src={Logo} alt="logo"/>
                    </div>
                    <form onSubmit={this.handleSubmit} method="POST">
                      <p style={{ marginTop: "10px", marginBottom: "0px" }}>
                        <label>
                          E-Mail
                          <br />
                          <div
                            className={
                              errors.has("email")
                                ? "error-border"
                                : "input-border"
                            }
                          >
                            <input
                              type="text"
                              name="email"
                              placeholder="Enter valid email"
                              value={this.state.creadiantial.email}
                              onChange={this.handleChange}
                              id="user_name"
                              className="form-control"
                            />
                          </div>
                          <FontAwesomeIcon
                            className=""
                            style={{ color: ErrorColor, opacity: "0" }}
                            size="lg"
                            icon={faTimesCircle}
                          />
                          {this.state.creadiantial.email !== "" ? (
                            errors.has("email") ? (
                              <FontAwesomeIcon
                                className="float-right close-icon"
                                style={{ color: ErrorColor }}
                                size="lg"
                                icon={faTimesCircle}
                              />
                            ) : this.state.isSubmitting ? (
                              <FontAwesomeIcon
                                className="float-right close-icon"
                                style={{ color: SucessColor }}
                                size="lg"
                                icon={faCheckCircle}
                              />
                            ) : null
                          ) : null}
                        </label>
                      </p>
                      <p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <label
                            style={{
                              justifyContent: "center",
                              textAlign: "center",
                              // backgroundColor: "red",
                            }}
                          >
                            <input
                              disabled={this.state.creadiantial.email === ""}
                              style={{ fontFamily: "roboto-regular" }}
                              onSubmit={this.handleSubmit}
                              type="submit"
                              className="btn btn-accent "
                              value="Reset Password"
                            />
                          </label>
                        </div>
                      </p>
                    </form>
                    <p>
                      {errors.has("email") && (
                        <div className="error-message-box">
                          Wrong E-Mail Format. Please enter valid E-mail.
                        </div>
                      )}
                      {this.state.apiError ? (
                        <div className="error-message-box">
                          There is no account registered with this E-Mail.
                        </div>
                      ) : null}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default VarifyPassword;
