export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const RESET_SEARCH_VALUE = "RESET_SEARCH_VALUE";
export const SET_ADMIN_PROFILE_DATA = "SET_ADMIN_PROFILE_DATA";

export const SET_PARAMS_ID = "SET_PARAMS_ID";
export const RESET_PARAMS_ID = "RESET_PARAMS_ID";

export const SET_LOADED_COMPANY_DATA = "SET_LOADED_COMPANY_DATA";

export const RESET_LOADED_COMPANY_DATA = "RESET_LOADED_COMPANY_DATA";

export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const RESET_SERVER_ERROR = "RESET_SERVER_ERROR";
export const SET_PLAN_EXPIRE_MODAL = "SET_PLAN_EXPIRE_MODAL";
export const RESET_PLAN_EXPIRE_MODAL = "RESET_PLAN_EXPIRE_MODAL";

export const TOGGLE_LOADER = "TOGGLE_LOADER";
