import React from "react";
import { Row, Col, Input } from "reactstrap";
import Button from "../../../../component/common/button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import api from "../../../../apiServices/index";
import "./profile.css";
import {
  faUser,
  faEnvelope,
  faMapMarkerAlt,
  faUserTie,
  faMobile,
  faPhoneAlt,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import TextInputWithButton from "../../../../component/common/textinputwithbutton/textinputwithbutton";
import "react-phone-number-input/style.css";
import PasswordInput from "./passwordInput";
import { CountryDropdown } from "react-country-region-selector";
import getSymbolFromCurrency from "currency-symbol-map";
import ReeValidate from "ree-validate";
import LocaleCurrency from "locale-currency";
// import Label from "reactstrap/lib/Label";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      //eslint-disable-next-line
      ["Mobile No"]: "min:5|max:15|",
      //eslint-disable-next-line
      ["Phone No"]: "min:5|max:15",
    });
    // const formData = new FormData();

    this.state = {
      formData: new FormData(),
      creadiantial: {
        company_address: "",
        company_name: "",
        email: "",
        fullname: "",
        mobile_no: "",
        //eslint-disable-next-line
        ["Mobile No"]: "",
        phone_no: "",
        //eslint-disable-next-line
        ["Phone No"]: "",
        username: "",
        old_password: "",
        new_password: "",
        country: "",
        currency_code: "EUR",
        currency_symbol: "€",
        plan_expiry: "",
        plan_name: "",
        plan_id: "",
        company_logo: "",
      },
      company_logo: "",
      error: "",

      planData: [],
      errors: this.validator.errors,
      MobileNoCountry: "IE",
      apiSuccess: false,
      isLoading: false,
      isMobileNoValid: true,
      isPhoneNoValid: true,

      isOldPasswordValid: true,
      isNewPasswordValid: true,
      isError: false,
      errorMessage: "",

      showOldPassword: false,
      showNewPassword: false,
      responseError: {},
      isChecked: true,
    };
    this.handleChecked = this.handleChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount = async () => {
    let { creadiantial } = this.state;
    this.setState({ isLoading: true });

    api
      .getWithToken("/myProfile")
      .then((response) => {
        const { data } = response.data;
        //eslint-disable-next-line
        Object.keys(data).map((key, index) => {
          if (creadiantial.hasOwnProperty(key)) {
            if (key === "mobile_no") {
              // this.setState({
              //   MobileNoCountry: parsePhoneNumber(data[key]).country,
              // });

              creadiantial["Mobile No"] = data[key];
            } else if (key === "phone_no") {
              // this.setState({
              //   PhoneNoCountry: parsePhoneNumber(data[key]).country,
              // });
              creadiantial["Phone No"] = data[key];
            } else {
              creadiantial[key] = data[key];
            }
          }
        });
        this.setState({ creadiantial, isLoading: false }, () =>
          console.log(this.state.creadiantial)
        );
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  // checkPasswordValid = (password) => {
  //   if (password.length >= 6) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  trimValues = (creadiantial) => {
    this.state.formData.append(
      "company_name",
      creadiantial.company_name.trim()
    );
    this.state.formData.append(
      "company_address",
      creadiantial.company_address.trim()
    );
    this.state.formData.append("email", creadiantial.email.trim());
    this.state.formData.append("fullname", creadiantial.fullname.trim());
    if (creadiantial["Mobile No"]) {
      this.state.formData.append("mobile_no", creadiantial["Mobile No"].trim());
    }
    if (creadiantial["Phone No"]) {
      this.state.formData.append("phone_no", creadiantial["Phone No"].trim());
    }
    this.state.formData.append("company_logo", creadiantial.company_logo);

    this.state.formData.append("country", creadiantial.country);

    return this.state.formData;
  };
  updateProfile = (creadiantial) => {
    this.setState({ isLoading: true });

    api
      .putWithToken("/update", creadiantial)
      .then((response) => {
        window.location.reload(true);
        const { status } = response;
        if (status === 200) {
          localStorage.setItem("currency_symbol", creadiantial.currency_symbol);
          this.setState({ apiSuccess: true, isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          responseError: error && error.response && error.response.data,
        });
      });
  };

  imageHandler = (e) => {
    let { creadiantial } = this.state;
    const Image = e.target.files[0];

    creadiantial.company_logo = Image;
    this.setState({ creadiantial });
    if (!Image.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({
        error:
          "Selected file formate is invalid. Please select jpg, jpeg or png  file.",
      });
    } else {
      this.setState({ error: "" });
    }
  };

  // checkPasswordsChange = () => {
  //   let { creadiantial } = this.state;
  //   if (creadiantial.old_password != "" || creadiantial.new_password != "") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ apiSuccess: false, responseError: {} });
    let { creadiantial } = this.state;
    const { errors } = this.validator;

    let passwordError = false;

    if (creadiantial.old_password && creadiantial.new_password) {
      this.state.formData.append(
        "old_password",
        creadiantial.old_password.trim()
      );
      this.state.formData.append(
        "new_password",
        creadiantial.new_password.trim()
      );
    } else if (creadiantial.old_password || creadiantial.new_password) {
      if (!creadiantial.old_password) {
        passwordError = true;
        this.setState({
          isError: true,
          errorMessage: "Please enter old password",
        });
      } else if (!creadiantial.new_password) {
        passwordError = true;
        this.setState({
          isError: true,
          errorMessage: "Please enter new password",
        });
      } else {
        this.setState({ isError: false, errorMessage: "" });
      }
    } else {
      this.setState({ isError: false, errorMessage: "" });
    }

    if (
      !this.state.isChecked &&
      creadiantial.old_password !== "" &&
      creadiantial.old_password === creadiantial.new_password
    ) {
      passwordError = true;
      this.setState({
        isError: true,
        errorMessage: "Your new password should be different from old password",
      });
    }

    if (
      !this.state.isChecked &&
      creadiantial.old_password <= 6 &&
      creadiantial.old_password !== ""
    ) {
      this.setState({
        isError: true,
        errorMessage: "Please enter minimum 6 character old password.",
      });
    }

    if (
      !this.state.isChecked &&
      creadiantial.new_password <= 6 &&
      creadiantial.new_password !== ""
    ) {
      this.setState({
        isError: true,
        errorMessage: "Please enter minimum 6 character new password.",
      });
    }

    creadiantial = this.trimValues(creadiantial);
    creadiantial = this.DeleteKey(creadiantial, "username");

    if (!passwordError) {
      creadiantial.mobile_no = creadiantial["Mobile No"];
      creadiantial = this.DeleteKey(creadiantial, "Mobile No");

      creadiantial.phone_no = creadiantial["Phone No"];
      creadiantial = this.DeleteKey(creadiantial, "Phone No");
      this.updateProfile(creadiantial);
    } else {
      this.setState({ errors });
    }
  };

  DeleteKey = (object, key) => {
    delete object[key];
    return object;
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;
    creadiantial[name] = value;

    // this.setState({ isChecked: !this.state.isChecked });

    if (creadiantial["old_password"] !== "") {
      this.setState({ isError: false, errorMessage: "" });
    }

    if (creadiantial["new_password"] !== "") {
      this.setState({ isError: false, errorMessage: "" });
    }

    errors.remove(name);

    this.setState({
      creadiantial,
      apiSuccess: false,
      responseError: {},
      isOldPasswordValid: true,
      isNewPasswordValid: true,
    });
  };

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  buttonConditaion = () => {
    return (
      this.state.creadiantial.fullname === "" ||
      this.state.creadiantial.email === "" ||
      this.state.creadiantial.company_address === "" ||
      this.state.creadiantial.company_name === "" ||
      this.state.creadiantial["Phone No"] === "" ||
      this.state.creadiantial["Mobile No"] === ""
    );
  };

  toastMeassages = (variant = "error", message) => (
    <p style={{ marginTop: "20px" }}>
      <div
        className={
          variant === "success" ? "success-message-box" : "error-message-box"
        }
      >
        {message}
      </div>
    </p>
  );

  handleCountyChange = (country) => {
    let { creadiantial } = this.state;
    creadiantial.country = country;

    this.setState({ country: creadiantial.country });
    const CurrencyCode = LocaleCurrency.getCurrency(country);

    creadiantial.currency_code = CurrencyCode;
    const symbol = getSymbolFromCurrency(CurrencyCode);

    creadiantial.currency_symbol = symbol;
    this.setState({ creadiantial });
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={8} md={8}>
              <div className="col-xl-12">
                {this.state.isLoading ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "98%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box ">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    Profile
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleSubmit}>
                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Company Name *
                              </div>
                              <div>
                                <TextInputWithButton
                                  search={false}
                                  placeholder=""
                                  height=""
                                  width=""
                                  name="company_name"
                                  value={this.state.creadiantial.company_name}
                                  onChange={this.handleChange}
                                  button={false}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faEnvelope}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", paddingRight: 10 }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Full Name *
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  width=""
                                  name="fullname"
                                  onChange={this.handleChange}
                                  value={this.state.creadiantial.fullname}
                                >
                                  <FontAwesomeIcon size="1x" icon={faUserTie} />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                E-Mail
                              </div>
                              <div>
                                <TextInputWithButton
                                  disabled={true}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="email"
                                  value={this.state.creadiantial.email}
                                  button={false}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faEnvelope}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", paddingRight: 10 }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Address *
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="company_address"
                                  value={
                                    this.state.creadiantial.company_address
                                  }
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faMapMarkerAlt}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {/* <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", paddingRight: 10 }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                  color: errors.has("Mobile No")
                                    ? "red"
                                    : "#000000",
                                }}
                              >
                                Plan Expiry Date
                              </div>
                              <div>
                                <TextInputWithButton
                                  disabled={true}
                                  button={false}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="plan_expiry"
                                  value={moment(
                                    this.state.creadiantial.plan_expiry
                                  ).format("DD-MM-YYYY")}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faClock}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>

                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", paddingRight: 10 }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                  color: errors.has("Phone No")
                                    ? "red"
                                    : "#000000",
                                }}
                              >
                                Plan
                              </div>
                              <div>
                                <TextInputWithButton
                                  disabled={true}
                                  button={false}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="plan_name"
                                  value={this.state.creadiantial.plan_name}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faBox}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                        </Row> */}

                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", paddingRight: 10 }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                  color: errors.has("Mobile No")
                                    ? "red"
                                    : "#000000",
                                }}
                              >
                                Mobile No *
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  hasError={errors.has("Mobile No")}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="Mobile No"
                                  value={this.state.creadiantial["Mobile No"]}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faMobile}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>

                          {/* <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                  color: this.state.isMobileNoValid
                                    ? "#000000"
                                    : "red",
                                }}
                              >
                                Mobile No *
                              </div>
                              <div
                                className="profile-phone-input"
                                style={{
                                  borderColor: this.state.isMobileNoValid
                                    ? "rgba(55, 71, 79, 0.4)"
                                    : "red",
                                }}
                              >
                                <PhoneInput
                                  defaultCountry={this.state.MobileNoCountry}
                                  onCountryChange={(MobileNoCountry) => {
                                    if (MobileNoCountry !== undefined) {
                                      this.setState({ MobileNoCountry });
                                    }
                                  }}
                                  placeholder="Enter mobile number"
                                  displayInitialValueAsLocalNumber={
                                    this.state.creadiantial.mobile_no
                                  }
                                  value={this.state.creadiantial.mobile_no}
                                  onChange={(mobile_no) => {
                                    console.log(mobile_no);
                                    let { creadiantial } = this.state;
                                    mobile_no !== undefined
                                      ? (creadiantial.mobile_no = mobile_no)
                                      : (creadiantial.mobile_no = "");
                                    this.setState({
                                      creadiantial,
                                      isMobileNoValid: true,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </Col> */}

                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", paddingRight: 10 }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                  color: errors.has("Phone No")
                                    ? "red"
                                    : "#000000",
                                }}
                              >
                                Phone No *
                              </div>
                              <div>
                                <TextInputWithButton
                                  button={false}
                                  hasError={errors.has("Phone No")}
                                  search={false}
                                  placeholder=""
                                  height=""
                                  onChange={this.handleChange}
                                  width=""
                                  name="Phone No"
                                  value={this.state.creadiantial["Phone No"]}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faPhoneAlt}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>

                          {/* <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                  color: this.state.isPhoneNoValid
                                    ? "#000000"
                                    : "red",
                                }}
                              >
                                Phone No *
                              </div>
                              <div
                                className="profile-phone-input"
                                style={{
                                  borderColor: this.state.isPhoneNoValid
                                    ? "rgba(55, 71, 79, 0.4)"
                                    : "red",
                                }}
                              >
                                <PhoneInput
                                  defaultCountry={this.state.PhoneNoCountry}
                                  onCountryChange={(PhoneNoCountry) => {
                                    if (PhoneNoCountry !== undefined) {
                                      this.setState({ PhoneNoCountry });
                                    }
                                  }}
                                  placeholder="Enter phone number"
                                  value={this.state.creadiantial.phone_no}
                                  displayInitialValueAsLocalNumber={
                                    this.state.creadiantial.phone_no
                                  }
                                  onChange={(phone_no) => {
                                    let { creadiantial } = this.state;
                                    phone_no !== undefined
                                      ? (creadiantial.phone_no = phone_no)
                                      : (creadiantial.phone_no = "");
                                    this.setState({
                                      creadiantial,
                                      isPhoneNoValid: true,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </Col> */}
                        </Row>

                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              style={{ marginTop: "10px", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                User Name
                              </div>
                              <div>
                                <TextInputWithButton
                                  search={false}
                                  disabled={true}
                                  placeholder=""
                                  height=""
                                  width=""
                                  name="username"
                                  value={this.state.creadiantial.username}
                                  onChange={this.handleChange}
                                  button={false}
                                >
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faEnvelope}
                                  />
                                </TextInputWithButton>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} xs={6}>
                            <div style={{ marginTop: "10px" }}>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                Country
                              </div>
                              <div className="country-picker-1-container d-flex">
                                <div className="mt-2">
                                  <FontAwesomeIcon
                                    onClick={this.handleEdit}
                                    size="1x"
                                    icon={faFlag}
                                  />
                                </div>
                                <CountryDropdown
                                  classes="country-picker-1 form-control"
                                  name="Country"
                                  valueType="short"
                                  labelType="full"
                                  value={this.state.creadiantial.country}
                                  onChange={(val) => {
                                    this.handleCountyChange(val);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} xs={6}>
                            <Input
                              style={{ marginLeft: 10 }}
                              type="checkbox"
                              onChange={this.handleChecked}
                            />
                            <span style={{ fontWeight: "bold" }}>
                              If you want to change password check here.
                            </span>
                          </Col>
                        </Row>

                        {this.state.isChecked === false ? (
                          <Row>
                            <Col md={6} xs={6}>
                              <PasswordInput
                                style={{ marginTop: 10, marginRight: 10 }}
                                lable={"Old Password"}
                                placeholder={"Enter Current Password"}
                                type={
                                  this.state.old_password ? "text" : "password"
                                }
                                handleChange={this.handleChange}
                                name="old_password"
                                value={this.state.creadiantial.old_password}
                                isShowPassword={this.state.old_password}
                                showPassword={() => {
                                  this.setState({
                                    old_password: !this.state.old_password,
                                  });
                                }}
                                hasError={!this.state.isOldPasswordValid}
                              />
                            </Col>
                            <Col md={6} xs={6}>
                              <PasswordInput
                                style={{ marginTop: 10, paddingRight: 10 }}
                                lable={"New Password"}
                                placeholder={"Enter New Password"}
                                type={
                                  this.state.showNewPassword
                                    ? "text"
                                    : "password"
                                }
                                handleChange={this.handleChange}
                                name="new_password"
                                value={this.state.creadiantial.new_password}
                                isShowPassword={this.state.showNewPassword}
                                showPassword={() => {
                                  this.setState({
                                    showNewPassword:
                                      !this.state.showNewPassword,
                                  });
                                }}
                                hasError={!this.state.isNewPasswordValid}
                              />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        <Row>
                          <Col md={6} xs={6}>
                            <div
                              className="d-flex flex-column"
                              style={{ margin: 10 }}
                            >
                              <label>Company Logo</label>
                              <input
                                type="file"
                                name="company-logo"
                                id="input"
                                accept="image/png,image/jpeg,image/jpg"
                                onChange={(e) => this.imageHandler(e)}
                              ></input>
                              <span style={{ color: "red" }}>
                                {this.state.error ? this.state.error : null}
                              </span>
                            </div>
                            {/* <PasswordInput
                              style={{ marginTop: 10, marginRight: 10 }}
                              lable={"Old Password"}
                              placeholder={"Enter Current Password"}
                              type={
                                this.state.old_password ? "text" : "password"
                              }
                              handleChange={this.handleChange}
                              name="old_password"
                              value={this.state.creadiantial.old_password}
                              isShowPassword={this.state.old_password}
                              showPassword={() => {
                                this.setState({
                                  old_password: !this.state.old_password,
                                });
                              }}
                              hasError={!this.state.isOldPasswordValid}
                            /> */}
                          </Col>
                        </Row>

                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                          <Button
                            disabled={this.buttonConditaion()}
                            type="submit"
                            onSubmit={this.handleSubmit}
                            full={true}
                            style={{
                              opacity: this.buttonConditaion() ? 0.3 : 1,
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </form>
                      {this.state.apiSuccess &&
                        this.toastMeassages(
                          "success",
                          "Profile updated successfully"
                        )}

                      {!this.state.isMobileNoValid &&
                        this.state.isPhoneNoValid &&
                        this.toastMeassages("error", "Invalid Mobile No.")}

                      {this.state.isMobileNoValid &&
                        !this.state.isPhoneNoValid &&
                        this.toastMeassages("error", "Invalid Phone No.")}

                      {!this.state.isMobileNoValid &&
                        !this.state.isPhoneNoValid &&
                        this.toastMeassages(
                          "error",
                          "Invalid Phone No and Mobile No."
                        )}

                      {this.state.responseError &&
                        this.state.responseError.error &&
                        this.toastMeassages(
                          "error",
                          this.state.responseError.error
                        )}

                      {errors.has("Mobile No") &&
                        this.toastMeassages("error", errors.first("Mobile No"))}

                      {errors.has("Phone No") &&
                        this.toastMeassages("error", errors.first("Phone No"))}

                      {(!this.state.isOldPasswordValid ||
                        !this.state.isNewPasswordValid) &&
                        this.toastMeassages(
                          "error",
                          "Please enter minimum 6 character password."
                        )}

                      {this.state.isError &&
                        this.toastMeassages("error", this.state.errorMessage)}
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.adminProfile,
  };
};

export default connect(mapStateToProps)(Profile);
