import React from "react";
import { Row, Col } from "reactstrap";
import api from "../../../../apiServices/index";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import ReactPlayer from "react-player";

class VideoTutorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorialData: [],
      isLoading: false,
    };
  }

  componentWillMount() {
    this.getAllTutorials();
  }

  getAllTutorials = () => {
    this.setState({ isLoading: true });

    api
      .getWithToken("/video_tutorial")
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data;
        this.setState(
          {
            tutorialData: data,
            isLoading: false,
          },
          () => {}
        );
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            {this.state.tutorialData &&
              this.state.tutorialData.map((tutorial, i) => {
                return (
                  <Col xl={4} md={4} lg={4} key={i}>
                    <div className="col-xl-12">
                      <section className="box">
                        <div className="content-body">
                          {tutorial.video_link.split(".")[1] === "youtube" ? (
                            <ReactPlayer
                              url={tutorial.video_link}
                              width="100%"
                              height="400px"
                            />
                          ) : (
                            // <div
                            //   style={{
                            //     height: "400px",
                            //     width: "100%",
                            //   }}
                            // >
                            <Player
                              playsInline
                              src={tutorial.video_link}
                              width={480}
                              height={272}
                            >
                              <BigPlayButton position="center" />
                            </Player>
                            // </div>
                          )}
                          <div
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#1ab394",
                              wordWrap: "break-word",
                            }}
                          >
                            {tutorial.title}
                          </div>
                        </div>
                      </section>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    );
  }
}

export default VideoTutorial;
