import React from "react";
import Render from "../../component/common/commonRender/commonRender";
import ImageView from "../../component/common/imageModal/imageModal";
import noQrImage from "../../assets/img/no_qr.png";

export const LoadedSurveyData = {
  Header: [
    {
      title: "Old Fittings",
      field: "pictures",
      editable: "never",
      render: (rowData) => (
        <ImageView
          rowData={rowData}
          imageField={0}
          modalTitle="Old Fittings"
          pictures={rowData.pictures}
        />
      ),
    },
    {
      title: "Fitting Type",
      field: "type",
      lookup: {
        //eslint-disable-next-line
        ["Linear fitting"]: "Linear fitting",
        //eslint-disable-next-line
        ["Circular fitting"]: "Circular fitting",
      },
      render: (rowData) => (
        <div>
          {rowData && rowData.type === "Linear fitting" ? (
            //
            <Render>Linear Fitting</Render>
          ) : (
            <Render>Circular Fitting</Render>
          )}
        </div>
      ),
    },
    {
      title: "Old Fitting Name",
      field: "name",
      render: (rowData) => <Render>{rowData.name}</Render>,
    },
    {
      title: "Length",
      field: "length",

      render: (rowData) =>
        rowData.length && rowData.type === "Linear fitting" ? (
          <div>{rowData.length}mm</div>
        ) : (
          "-"
        ),
    },
    {
      title: "Height",
      field: "height",

      render: (rowData) =>
        rowData.height && rowData.type === "Linear fitting" ? (
          <div>{rowData.height}mm</div>
        ) : (
          <div>{"-"}</div>
        ),
    },
    {
      title: "Width",
      field: "width",

      render: (rowData) =>
        rowData.width && rowData.type === "Linear fitting" ? (
          <div>{rowData.width}mm</div>
        ) : (
          <div>{"-"}</div>
        ),
    },
    {
      title: "Diameter",
      field: "diameter",
      render: (rowData) =>
        rowData.diameter && rowData.type === "Circular fitting" ? (
          <div>{rowData.diameter}mm</div>
        ) : (
          <div>{"-"}</div>
        ),
    },
    {
      title: "Cut Hole Size",
      field: "cut_hole_size",
      render: (rowData) =>
        rowData.cut_hole_size && rowData.type === "Circular fitting" ? (
          <div>{rowData.cut_hole_size}mm</div>
        ) : (
          <div>{"-"}</div>
        ),
    },

    {
      title: "Bulb Quantity",
      field: "bulb_quantity_per_fitting",
      render: (rowData) =>
        rowData.bulb_quantity_per_fitting &&
        rowData.bulb_quantity_per_fitting ? (
          <div>{rowData.bulb_quantity_per_fitting}</div>
        ) : (
          <div>{"-"}</div>
        ),
    },

    {
      title: "Bulb Watt",
      field: "power",
      render: (rowData) =>
        rowData.power && rowData.power ? (
          <div>{rowData.power}W</div>
        ) : (
          <div>{"-"}</div>
        ),
    },

    {
      title: "Old Watts",
      field: "old_watt",
      editable: "never",
      render: (rowData) =>
        rowData.old_watt ? <div>{rowData.old_watt}W</div> : <div>{"-"}</div>,
    },
    {
      title: "Old Fittings Quantity",
      field: "quantity",
      render: (rowData) => <Render>{rowData.quantity}</Render>,
    },
    {
      title: "Hours Per Year",
      field: "operating_time_per_year",
      render: (rowData) => <Render>{rowData.operating_time_per_year}</Render>,
    },
    {
      title: "Room Type",
      field: "location",
      render: (rowData) => <Render>{rowData.location}</Render>,
    },
    {
      title: "Emergency",
      field: "emergency_fitting",
      lookup: { true: "Yes", false: "No" },
      editable: "onUpdate",
      render: (rowData) => {
        return rowData.emergency_fitting ? (
          <div style={{ color: "green" }}>Yes</div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        );
      },
    },
  ],
  optionalHeader: [
    {
      title: "New Fittings",
      field: "new_picture",
      editable: "never",
      render: (rowData) => (
        <ImageView
          modalTitle="New Fittings"
          imageField={1}
          rowData={rowData}
          pictures={rowData.new_picture}
        />
      ),
    },
    {
      title: "Fitting Type",
      editable: "never",
      field: "type",
      render: (rowData) => <Render>{rowData.type}</Render>,
    },
    {
      title: "New Fitting Name",
      field: "new_fitting_name",
      render: (rowData) => <Render>{rowData.new_fitting_name}</Render>,
    },
    {
      title: "New Watts",
      field: "new_fitting_power",
      render: (rowData) =>
        rowData.power ? (
          <div>{rowData.new_fitting_power}W</div>
        ) : (
          <div>{"-"}</div>
        ),
    },
    {
      title: "New Fittings Quantity",
      field: "new_quantity",
      render: (rowData) => <Render>{rowData.new_quantity}</Render>,
    },
    {
      title: "New Hours Per Year",
      field: "new_operating_time_per_year",
      render: (rowData) => (
        <Render>{rowData.new_operating_time_per_year}</Render>
      ),
    },
    {
      title: "Location",
      field: "location",
      editable: "never",
      render: (rowData) => <Render>{rowData.location}</Render>,
    },
    {
      title: "Emergency",
      field: "emergency_fitting",
      editable: "never",
      render: (rowData) =>
        rowData.emergency_fitting ? (
          <div style={{ color: "green" }}>Yes</div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        ),
    },
    {
      title: "Verified",
      field: "verify",
      lookup: { true: "Yes", false: "No" },
      editable: "onUpdate",
      render: (rowData) => {
        return rowData.verify ? (
          <div style={{ color: "green" }}>Yes</div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        );
      },
    },
    {
      title: "Reference",
      field: "LinkReference",
      render: (rowData) => <Render>{rowData.LinkReference}</Render>,
    },
    // {
    //   title: "Fitting Cost",
    //   field: "fitting_cost",
    //   render: (rowData) => (
    //     <div>
    //       {/* {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"} */}
    //       {rowData.fitting_cost}
    //     </div>
    //   ),
    // },
    {
      title: "Labour Cost",
      field: "labour_cost",
      render: (rowData) => (
        <div>
          {/* {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"} */}
          {rowData.labour_cost}
        </div>
      ),
    },
    {
      title: "Carbon Credit",
      field: "carbon_credit",
      editable: "never",
      render: (rowData) =>
        rowData.carbon_credit !== "" ? (
          <Render>
            {/* {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"} */}
            {rowData.carbon_credit.toFixed(2)}
          </Render>
        ) : (
          "-"
        ),
    },
    {
      title: "Accessories And Plant Cost",
      field: "accessories_plan_cost",
      render: (rowData) =>
        rowData.accessories_plan_cost !== "" ? (
          <Render>
            {/* {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"} */}
            {rowData.accessories_plan_cost}
          </Render>
        ) : (
          "-"
        ),
    },
    {
      title: "RRP Price",
      field: "rrp",
      render: (rowData) =>
        rowData.rrp !== "" ? (
          <Render>
            {/* {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"} */}
            {rowData.rrp ? rowData.rrp : "-"}
          </Render>
        ) : (
          "-"
        ),
    },
    {
      title: "Rate Per KWh",
      field: "rate_per_kwh",
      editable: "never",
      render: (rowData) =>
        rowData.rate_per_kwh ? (
          <Render>{rowData.rate_per_kwh.$numberDecimal}</Render>
        ) : (
          "-"
        ),
    },
    {
      title: "Total KWh Saved",
      field: "total_kwh_saved",
      editable: "never",
      render: (rowData) =>
        rowData.total_kwh_saved !== "" ? (
          <Render>{rowData.total_kwh_saved.toFixed(2)}</Render>
        ) : (
          "-"
        ),
    },
    {
      title: "Qrc Attached",
      field: "qr_code_image",
      lookup: { true: "Yes", false: "No" },
      editable: "never",
      render: (rowData) => {
        return rowData &&
          rowData.qr_code_image &&
          rowData.qr_code_image !== "" ? (
          <div style={{ color: "green" }}>Yes</div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        );
      },
    },
    {
      title: "Qrc Image",
      field: "qr_code_image",
      lookup: { true: "Yes", false: "No" },
      editable: "never",
      render: (rowData) => (
        <ImageView
          modalTitle="New Fittings"
          imageField={1}
          rowData={rowData}
          pictures={
            rowData && rowData.qr_code_image && rowData.qr_code_image !== ""
              ? rowData.qr_code_image
              : noQrImage
          }
          qrImage={true}
        />
      ),
    },
  ],
  data: [],
};
