import React from "react";
import Button from "../../component/common/button/button";
import Render from "../../component/common/commonRender/commonRender";

// import {
//   faBuilding,
//   faFilePdf,
//   faEye
// } from "@fortawesome/free-solid-svg-icons";

import {
  SuperAdminString,
  AdminString,
  AdminTypeString,
} from "../constantVariable";
import ChangeQuotationStatus from "./ChangeQuotationStatus";

export const getScheduleReportsData = () => {
  return {
    Header: [
      {
        title: "Company Name",
        field: "company_name",
        hidden: localStorage.getItem(AdminTypeString) === AdminString,
        editable: "never",
        render: (rowData) =>
          rowData.tableData.id === 0 ||
          rowData.tableData.id === 1 ||
          rowData.tableData.id === 2 ||
          rowData.tableData.id === 3 ||
          rowData.tableData.id === 4 ? (
            <div>
              <Button squre={true}>New</Button>
              {"   "}
              {rowData.company_name === "" ? "-" : rowData.company_name}
            </div>
          ) : (
            <div>
              {rowData.company_name === "" ? "-" : rowData.company_name}
              {"   "}
            </div>
          ),
      },

      {
        title:
          localStorage.getItem(AdminTypeString) === AdminString
            ? "First Name"
            : "User",
        field: "first_name",
        editable: "never",
        render: (rowData) => <Render>{rowData.first_name}</Render>,
      },
      {
        title: "Schedule Name",
        field: "schedule_name",
        editable: "never",
        render: (rowData) => <Render>{rowData.schedule_name}</Render>,
      },
      {
        title: "Total Fittings",
        field: "scheduleFitting",
        editable: "never",
        render: (rowData) => <Render>{rowData.scheduleFitting}</Render>,
      },
      {
        title: "E-Mail",
        field: "email",
        editable: "never",
        render: (rowData) => <Render>{rowData.email}</Render>,
      },
      // {
      //   title: "Generate Report",
      //   field: "is_report_generated",
      //   hidden: localStorage.getItem(AdminTypeString) === SuperAdminString,
      //   lookup: { true: "Yes", false: "NO" },
      //   editable: "onUpdate",
      //   render: rowData => {
      //     return rowData.is_report_generated ? (
      //       <div style={{ color: "green" }}>YES</div>
      //     ) : (
      //       <div style={{ color: "red" }}>NO</div>
      //     );
      //   }
      // },
      {
        title: "Generate Report",
        field: "is_report_generated",
        hidden: localStorage.getItem(AdminTypeString) === SuperAdminString,
        render: (rowData) => (
          <ChangeQuotationStatus
            data={rowData}
            label={rowData.is_report_generated}
          />
        ),
      },
      // {
      //   title: "Status",
      //   field: "Status",
      //   editable: "never",
      // }
    ],
    data: [],
  };
};
