import React from "react";
import Render from "../../component/common/commonRender/commonRender";
// import { AdminTypeString, AdminString } from "../constantVariable";
import TextField from "@material-ui/core/TextField";
import Button from "../../component/common/button/button";

// active_plan: 2
// created_at: "2020-05-05T17:49:47.914Z"
// default: true
// is_active: false
// name: "Free Plan"
// name_lower: "free plan"
// price: 0
// updated_at: "2020-05-13T17:58:48.626Z"
// user_limit: 3

export const getPackagesColumn = () => {
  return {
    Header: [
      {
        title: "Package ID",
        field: "_id",
        editable: "never",
        render: (rowData) => (
          <Render>
            <div className="d-flex">
              {rowData._id}
              {rowData.default ? (
                <div className="ml-1">
                  <Button squre={true}>default</Button>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Render>
        ),
      },
      {
        title: "Package Name",
        field: "name",
        editable: "never",
      },
      {
        title: "User Limit",
        field: "user_limit",
        editable: "never",
        editComponent: (props) => (
          <TextField
            type="number"
            variant="standard"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "Survey Limit",
        field: "survey_limit",
        editable: "never",
        editComponent: (props) => (
          <TextField
            type="number"
            variant="standard"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "Schedule Limit",
        field: "schedule_limit",
        editable: "never",
        editComponent: (props) => (
          <TextField
            type="number"
            variant="standard"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "Price",
        field: "price",
        render: (rowData) => <Render>{`\u20ac ${rowData.price}`}</Render>,
        editable: "never",
        editComponent: (props) => (
          <TextField
            type="number"
            variant="standard"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "Active company",
        field: "active_plan",
        render: (rowData) => <Render>{rowData.active_plan}</Render>,
        editable: "never",
      },
      {
        title: "Package status",
        field: "is_active",
        lookup: { true: "Active", false: "Deactive" },
        render: (rowData) => {
          return rowData.is_active ? (
            <div style={{ color: "green" }}>Active</div>
          ) : (
            <div style={{ color: "red" }}>Deactive</div>
          );
        },
      },
      {
        title: "What you get",
        field: "what_we_get",
        render: (rowData) => (
          <Render>
            <ul style={{ listStyleType: "none" }}>
              {rowData.what_we_get &&
                rowData.what_we_get.map((what) => (
                  <li>&#10004; {what.value}</li>
                ))}
            </ul>
          </Render>
        ),
        editable: "never",
      },
    ],
    data: [],
  };
};

export const getPackagesDurationColumn = () => {
  return {
    Header: [
      {
        title: "ID",
        field: "_id",
        editable: "never",
      },
      {
        title: "Duration name",
        field: "name",
      },
      {
        title: "Multiply factor",
        field: "multiply_factor",
        editable: "never",
      },
      {
        title: "Validity (in days)",
        field: "days",
        editComponent: (props) => (
          <TextField
            type="number"
            variant="standard"
            value={props.value}
            inputProps={{ type: "number" }}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
    ],
    data: [],
  };
};
