import React from "react";
import Render from "../../component/common/commonRender/commonRender";
import CustomDatePicker from "./datePicker";
import PlanPicker from "./PlanPicker";
// import api from "../../apiServices";
import moment from "moment";
import "./company.css";

// getAllPackges()

export const companaiesData = {
  Header: [
    {
      title: "Company Name",
      field: "company_name",
      render: (rowData) => <Render>{rowData.company_name}</Render>,
    },
    {
      title: "E-Mail",
      field: "email",
      editable: "never",
      render: (rowData) => <Render>{rowData.email}</Render>,
    },
    {
      title: "Mobile No",
      field: "mobile_no",
      type: "numeric",
      render: (rowData) => <Render>{rowData.mobile_no}</Render>,
    },
    {
      title: "Phone No",
      field: "phone_no",
      type: "numeric",
      render: (rowData) => <Render>{rowData.phone_no}</Render>,
    },
    {
      title: "Address",
      field: "company_address",
      render: (rowData) => <Render>{rowData.company_address}</Render>,
    },
    {
      title: "Current Package",
      field: "plan_id",
      editComponent: (props) => (
        <PlanPicker
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        />
      ),
      render: (rowData) => (
        <div>{rowData.plan_name ? rowData.plan_name : "-"}</div>
      ),
    },
    {
      title: "Expiry Date",
      field: "plan_expiry",
      headerStyle: { minWidth: 200 },
      editComponent: (props) => (
        <CustomDatePicker
          selectedDate={moment(props.value).format("YYYY-MM-DD")}
          onChange={props.onChange}
        />
      ),
      render: (rowData) => (
        <div>
          {rowData.plan_expiry
            ? moment(rowData.plan_expiry).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "User Name",
      field: "username",
      editable: "never",
      render: (rowData) => <Render>{rowData.username}</Render>,
    },
    {
      title: "Password",
      field: "password",
      render: (rowData) => <Render>{rowData.password}</Render>,
    },
    {
      title: "Status",
      field: "active",
      lookup: { true: "Active", false: "Deactive" },
      editable: "onUpdate",
      render: (rowData) => {
        return rowData.active ? (
          <div style={{ color: "green" }}>Active</div>
        ) : (
          <div style={{ color: "red" }}>Deactive</div>
        );
      },
    },
    {
      title: "Active From",
      field: "created_at",
      editable: "never",
      render: (rowData) => <Render>{rowData.created_at}</Render>,
    },
  ],
  data: [],
};
