import React, { Component } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from "react-stripe-elements";
import Button from "../../../../../component/common/button/button";
// import { faBox } from "@fortawesome/free-solid-svg-icons";

import { StripePublicKey } from "../../../../../contants/defaultvalues";
import api from "../../../../../apiServices/index";
import stripeIcon from "../../../../../assets/icons/stripe-icon.png";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

class _CardForm extends Component {
  state = {
    errorMessage: "",
    isSuccessed: false,
    responseErrorMessage: "",
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.startLoader();
    this.setState({ isSuccessed: false, responseErrorMessage: "" });
    if (this.props.stripe) {
      const cardElement = this.props.elements.getElement("card");
      this.props.stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then((paymentMethod) => {
          //!new
          const data = {
            amount: this.props.amount * 100,
            // source: token.token.id,
            payment_method: paymentMethod.paymentMethod.id,
            receipt_email: localStorage.getItem("email"),
            plan_id: this.props.plan_id,
          };
          api
            .postWithToken("/plan/purchase", data)
            .then((response) => {
              this.setState({ isSuccessed: true });
              this.props.stopLoader();
              localStorage.setItem("plan_expire", false);
              window.location.reload(true);
            })
            .catch((err) => {
              let message = err.response.data.message;
              this.setState({ responseErrorMessage: message });
            });
        });
    } else {
      this.props.stopLoader();
    }
  };

  render() {
    return (
      <div className="CardDemo">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label style={{ width: "100%" }}>
            Card details
            <div
              className="mt-2"
              style={{
                borderBottom: "1px solid rgba(55, 71, 79, 0.4)",
                paddingBottom: 5,
              }}
            >
              <CardElement onChange={this.handleChange} {...createOptions()} />
            </div>
          </label>
          {this.state.errorMessage && (
            <div className="text-danger" role="alert">
              {this.state.errorMessage}
            </div>
          )}
          <div className="mt-4 text-center">
            <Button type="submit" full={true}>
              Pay
            </Button>

            <div  className="mt-3">
              <img src={stripeIcon} alt="stripe-icon" height={30}/>
            </div>

            {this.state.isSuccessed && (
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <div className="success-message-box">
                  Your payment has been successfully processed.
                </div>
              </div>
            )}

            {this.state.responseErrorMessage && (
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <div className="error-message-box">
                  {this.state.responseErrorMessage}
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class CardDemo extends Component {
  render() {
    return (
      <StripeProvider apiKey={StripePublicKey}>
        <Elements>
          <CardForm
            handleResult={this.handleResult}
            amount={this.props.amount}
            planId={this.props.planId}
            planName={this.props.planName}
            days={this.props.days}
            startLoader={this.props.startLoader}
            stopLoader={this.props.stopLoader}
            plan_id={this.props.plan_id}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default CardDemo;
