import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../apiServices/index";
import { CardText, CardSubtitle } from "reactstrap";
import moment from "moment";
import Button from "../../../../component/common/button/button";

class PackageDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      isSuccessed: false,
      responseErrorMessage: "",
    };
  }

  componentWillMount() {
    this.getCurrentPackage();
  }

  getCurrentPackage = () => {
    this.setState({ isLoading: true });
    api.getWithToken("/current_package").then((response) => {
      const responseData = response.data.data;
      this.setState({ isLoading: false });
      if (response.status === 200) {
        this.setState({ data: responseData });
      }
    });
  };

  cancelSubscription = () => {
    this.setState({
      isLoading: true,
      isSuccessed: false,
      responseErrorMessage: "",
    });
    api
      .postWithToken("/plan/cancel")
      .then((response) => {
        // const responseData = response.data.data;
        this.setState({
          isLoading: false,
          isSuccessed: true,
        });
        localStorage.setItem("plan_expire", true);
        window.location.reload(true);
      })
      .catch((err) => {
        let message =
          err && err.response && err.response.data && err.response.data.message;
        this.setState({
          isLoading: false,
          responseErrorMessage: message,
          isSuccessed: false,
        });
      });
  };

  render() {
    const { data } = this.state;
    return (
      <div className="col-xl-12">
        {this.state.isLoading ? (
          <div
            className="loader-container"
            style={{ height: "100%", width: "95%" }}
          >
            <div className="spinner-border text-secondary Loader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <section className="box h-100">
          <header className="panel_header text-uppercase mt-2">
            <FontAwesomeIcon className="mr-1 ml-2" size="1x" icon={faBox} />
            Current Package
          </header>
          <hr className="ml-2 mr-2" />
          <div className="content-body">
            <div className="p-2 ">
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Current Package Name
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.plan_name) || ""}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Total App Users Credit Limit
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.user_limit) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Used Credit
                </CardSubtitle>
                <CardText className="mt-2  text-small">
                  {(data && data.user_count) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Remaining credit
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.remaining_user) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Total App Survey Credit Limit
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.survey_limit) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Used Survey Credit
                </CardSubtitle>
                <CardText className="mt-2  text-small">
                  {(data && data.surveyCount) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Remaining Survey credit
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.remaining_survey) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Total App Schedule Credit Limit
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.schedule_limit) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Used Schedule Credit
                </CardSubtitle>
                <CardText className="mt-2  text-small">
                  {(data && data.scheduleCount) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Remaining Schedule credit
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && data.remaining_schedule) || 0}
                </CardText>
              </div>
              <div className="mb-3">
                <CardSubtitle className="mb-0 text-muted font-weight-light text-small">
                  Expiry Date
                </CardSubtitle>
                <CardText className="mt-2 text-small">
                  {(data && moment(data.plan_expiry).format("DD-MM-YYYY")) ||
                    ""}
                </CardText>
              </div>
              <div className="mb-0">
                {data && data.plan_id !== "" && (
                  <Button
                    full={true}
                    type="submit"
                    onClick={this.cancelSubscription}
                  >
                    Cancel Subscription
                  </Button>
                )}
              </div>

              {this.state.isSuccessed && (
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <div className="success-message-box">
                    Your subscription cancel successfully.
                  </div>
                </div>
              )}

              {this.state.responseErrorMessage && (
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <div className="error-message-box">
                    {this.state.responseErrorMessage}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PackageDetail;
