import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import api from "../../apiServices";

class PlanPicker extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    this.getAllPackges();
  }

  getAllPackges = () => {
    api.getWithToken("/plan").then((response) => {
      const responseData = response.data.data;
      if (response.status === 200) {
        this.setState({ data: responseData });
      }
    });
  };

  render() {
    const { onChange, value } = this.props;
    const { data } = this.state;

    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={onChange}
      >
        {data.map((item, key) => {
          return (
            <MenuItem key={key} value={item._id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
}

export default PlanPicker;
