import React from "react";
import Button from "../../component/common/button/button";
import Render from "../../component/common/commonRender/commonRender";

export const CustomersData = {
  Header: [
    {
      title: "Company ID",
      field: "_id",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData._id}</Render>
    },
    {
      title: "Company Name",
      field: "company_name",
      sortable: true,
      filterable: true,
      render: rowData =>
        rowData.tableData.id === 0 ||
        rowData.tableData.id === 1 ||
        rowData.tableData.id === 2 ||
        rowData.tableData.id === 3 ||
        rowData.tableData.id === 4 ? (
          <div>
            {rowData.company_name ? <Button squre={true}>New</Button> : null}
            {"   "}
            {rowData.company_name ? rowData.company_name : "-"}
          </div>
        ) : (
          <div>
            {rowData.company_name ? rowData.company_name : "-"}
            {"   "}
          </div>
        )
    },
    {
      title: "Address",
      field: "company_address",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.company_address}</Render>
    },
    {
      title: "Phone Number",
      field: "phone_no",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.phone_no}</Render>
    },
    {
      title: "Mobile No",
      field: "mobile_no",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.mobile_no}</Render>
    },
    {
      title: "Full Name",
      field: "fullname",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.fullname}</Render>
    },
    {
      title: "Savings",
      field: "savings",
      sortable: true,
      filterable: true,
      render: rowData => rowData.savings ? <Render>
        {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac" }
        {rowData.savings.toFixed(2)}
      </Render> :"-"
    },
    {
      title: "E-Mail",
      field: "email",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.email}</Render>
    },
    {
      title: "Total Users",
      field: "total_users",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.total_users}</Render>
    },
    {
      title: "Total Surveys",
      field: "total_surveys",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.total_surveys}</Render>
    },
    {
      title: "Total Fittings",
      field: "total_fittings",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.total_fittings}</Render>
    },
    {
      title: "Active From",
      field: "created_at",
      sortable: true,
      filterable: true,
      render: rowData => <Render>{rowData.created_at}</Render>
    },
    {
      title: "Verified",
      field: "verify",
      sortable: true,
      filterable: true,
      render: rowData =>
        rowData.verify ? (
          <div style={{ color: "green",minHeight:'2.5rem',textAlign:'center',alignItems:'center',display:'flex' }}>
            YES
          </div>
        ) : (
          <div style={{ color: "red",minHeight:'2.5rem',textAlign:'center',alignItems:'center',display:'flex' }}>NO</div>
        )
    }
  ],
  data: []
};
