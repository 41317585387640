import React from "react";
import { Row, Col } from "reactstrap";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextInput/textinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLock,
  faBuilding,
  faUser,
  // faEyeSlash,
  // faEye,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import api from "../../../../apiServices/index";
// import TextInputWithButton from "../../../../component/common/textinputwithbutton/textinputwithbutton";

class CreateAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credential: {
        company_id: null,
        config_id: null,
      },
      isLoading: false,
      apiSuccess: false,
      apiError: false,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    let { credential } = this.state;
    const { value, name } = e.target;
    credential[name] = value;
    this.setState({ credential, apiError: false, apiSuccess: false });
  };

  componentWillMount() {
    this.getCompanyId();
  }

  getCompanyId = () => {
    let { credential } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/getConfig")
      .then((response) => {
        const { data, status } = response;
        credential.company_id = data.data[0].amature_company_id;
        credential.config_id = data.data[0]._id;
        if (status === 200) {
          this.setState({ isLoading: false, credential });
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  handleSubmit = () => {
    this.setState({ apiError: false, apiSuccess: false, isLoading: true });
    let { credential } = this.state;
    // credential.company_id = credential.company_id.trim();
    //eslint-disable-next-line
    const body = {
      amature_company_id: credential.company_id,
    };

    api
      .putWithToken(`/updateConfig/${this.state.credential.config_id}`, {
        amature_company_id: this.state.credential.company_id,
      })
      .then((response) => {
        //eslint-disable-next-line
        const { data, status } = response;

        if (status === 200) {
          this.setState({ apiSuccess: true, isLoading: false });
        }
      })
      .catch((error) => {
        const data = error.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={4} md={4}>
              <div className="col-xl-12">
                {this.state.isLoading ? (
                  <div
                    className="loader-container"
                    style={{ height: "100%", width: "95%" }}
                  >
                    <div
                      className="spinner-border text-secondary Loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <section className="box">
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      onClick={this.handleEdit}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    Default Settings
                  </header>
                  <hr />
                  <div className="content-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <form onSubmit={this.handleFormSubmit}>
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{ marginLeft: "10px", marginBottom: "5px" }}
                          >
                            Default Company ID
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <TextInput
                              placeholder={"Enter company ID"}
                              search={false}
                              onChange={this.handleChange}
                              value={this.state.credential.company_id}
                              name="company_id"
                            >
                              <FontAwesomeIcon
                                onClick={this.handleEdit}
                                size="1x"
                                icon={faBuilding}
                              />
                            </TextInput>
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            textAlign: "left",
                          }}
                        >
                          <Button
                            disabled={this.state.credential.company_id === ""}
                            onClick={this.handleSubmit}
                            full={true}
                          >
                            UPDATE
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <p
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.apiError && (
                      <div className="error-message-box">
                        Please enter the valid Amateur company ID.
                      </div>
                    )}
                  </p>
                  <p
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {this.state.apiSuccess && (
                      <div className="success-message-box">
                        Amateur company ID updated successfully.
                      </div>
                    )}
                  </p>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateAdmin;
