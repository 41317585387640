import { apiUrl, API } from "../contants/defaultvalues";
import axios from "axios";
import { setServerError } from "../store/Actions";
import store from "../store/index";

export const Bearer = "Bearer ";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.assign("/login");
    } else if (error.response.status === 400) {
      return Promise.reject(error);
    } else if (error.response.status === 500) {
      return Promise.reject(error);
    } else {
      let payload = {
        isModal: true,
        statusCode: error.response.status,
      };
      store.dispatch(setServerError(payload));
    }
  }
);

const URL = apiUrl + API;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postWithoutToken: (url, data) => {
    return axios.post(URL + url, data);
  },

  postWithTokenInUrl: (url, data) => {
    return axios({
      data,
      method: "post",
      url: URL + url,
    });
  },

  postWithToken: (url, data) => {
    return axios({
      data,
      headers: { authorization: Bearer + localStorage.getItem("access_token") },
      method: "post",
      url: URL + url,
    });
  },
  putWithToken: (url, data) => {
    return axios({
      data,
      headers: { authorization: Bearer + localStorage.getItem("access_token") },
      method: "put",
      url: URL + url,
    });
  },
  getWithoutToket: (url) => {
    return axios.get(URL + url);
  },
  getWithToken: (url) => {
    return axios({
      method: "get",
      headers: { authorization: Bearer + localStorage.getItem("access_token") },
      url: URL + url,
    });
  },
  getWithTokenInBlobRes: (url) => {
    return axios({
      method: "get",
      responseType: "blob",
      headers: { authorization: Bearer + localStorage.getItem("access_token") },
      url: URL + url,
    });
  },
  getParamsWithToken: (url, params) => {
    return axios({
      params,
      method: "get",
      headers: { authorization: Bearer + localStorage.getItem("access_token") },
      url: URL + url,
    });
  },
  deleteWithToken: (url) => {
    return axios({
      method: "delete",
      headers: { authorization: Bearer + localStorage.getItem("access_token") },
      url: URL + url,
    });
  },
};
