import React from "react";
import "./header.css";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Media,
  Card,
  CardBody,
  DropdownItem,
  CardFooter,
  Input,
} from "reactstrap";
import api from "../../../apiServices/index";
import moment from "moment";
import Button from "../../../component/common/button/button";
import sadEmoji from "../../../assets/img/sadEmoji.png";
import { Link } from "react-router-dom";

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      notificationData: [],
      limit: 6,
      isLoading: false,
      select_value: "",
      count: 0,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  componentWillMount() {
    this.getNotifications();
    this.getNotificationCount();
  }

  handleClick = () => {
    this.setState({
      limit: this.state.limit + 6,
    });
    this.getNotifications();
  };

  getNotifications = (select_value) => {
    this.setState({ isLoading: true });

    api
      .getWithToken(
        `/video_tutorial/notification?type=${select_value}&page=1&limit=${this.state.limit}`
      )
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        this.setState({
          notificationData: data,
          isLoading: false,
        });
      })

      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getNotificationCount = () => {
    api
      .getWithToken(`/video_tutorial/notification?type=show_unread`)
      .then((res) => {
        const count = res && res.data && res.data.data && res.data.data.count;
        this.setState({
          count: count,
        });
      })
      .catch((err) => {});
  };

  handleChange(e) {
    e.preventDefault();
    this.setState({
      select_value: e.target.value,
    });
    this.getNotifications(e.target.value);
  }

  markNotification = () => {
    this.setState({ isLoading: true });
    localStorage.setItem("is_view", true);
    api
      .putWithToken(`/video_tutorial/notification/mark_read`)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        this.getNotificationCount();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        style={{ marginRight: 50 }}
        className="d-inline-block"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item  waves-effect"
          id="page-header-notifications-dropdown"
        >
          <FontAwesomeIcon
            size="1x"
            icon={faBell}
            style={{
              color: "#1AB394",
              fontSize: "20px",
              marginTop: 20,
            }}
          />
          {this.state.count > 0 ? (
            <span className="noti-dot">{this.state.count}</span>
          ) : null}
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg p-0">
          <div className="notification-div">
            <div className="noti-title">Notifications</div>
            {this.state.notificationData &&
              this.state.notificationData.length > 0 && (
                <div className="mark" onClick={this.markNotification}>
                  Mark all notifications as read
                </div>
              )}
          </div>

          <div
            style={{
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                marginRight: "20px",
                fontWeight: "bolder",
                backgroundColor: "white",
                textAlign: "right",
                paddingLeft: "450px",
              }}
            >
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                className="select-toggle"
                value={this.state.select_value}
                onChange={(e) => this.handleChange(e)}
              >
                <option selected={true} disabled="disabled" value="">
                  Select
                </option>
                <option value="show_all">Show All</option>
                <option value="show_read">Show Read</option>
                <option value="show_unread">Show Unread</option>
              </Input>
            </div>
          </div>

          <div
            style={{
              overflowY: "scroll",
              height: 600,
            }}
          >
            {this.state.isLoading ? (
              <div
                className="loader-container"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="spinner-border text-secondary Loader"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            {this.state.notificationData &&
              this.state.notificationData.length <= 0 && (
                <div
                  style={{
                    marginTop: "200px",
                    fontSize: "30px",
                    fontWeight: "bolder",
                    textAlign: "center",
                  }}
                  className="card-div"
                >
                  <img src={sadEmoji} alt="empty" />
                  <div
                    style={{
                      fontSize: "16px",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    Sorry!
                  </div>
                </div>
              )}

            {this.state.notificationData &&
              this.state.notificationData.length > 0 &&
              this.state.notificationData.map((notification, i) => {
                return (
                  <React.Fragment key={i}>
                    <Media>
                      <div>
                        <div className="card-div">
                          <div className="noti-card-title">
                            <Media body>
                              <Link to="/admin/tutorial" onClick={this.toggle}>
                                <Card className="noti-card">
                                  <CardBody className="noti-cardbody">
                                    <div style={{ flex: "1 1 auto" }}>
                                      <p>
                                        <strong>{notification.message}</strong>
                                      </p>
                                    </div>
                                  </CardBody>
                                  <CardFooter className="noti-card-footer">
                                    <span className="noti-footer">
                                      {moment(notification.created_at)
                                        .startOf("minute")
                                        .fromNow()}
                                    </span>
                                  </CardFooter>
                                </Card>
                              </Link>
                            </Media>
                          </div>
                        </div>
                      </div>
                    </Media>
                  </React.Fragment>
                );
              })}
          </div>

          {this.state.notificationData &&
            this.state.notificationData.length > 5 && (
              <>
                <DropdownItem divider />
                <div
                  style={{
                    textAlign: "center",
                    color: "#1AB394",
                    margin: "10px 0 10px 0",
                  }}
                >
                  <Button onClick={this.handleClick} full={true}>
                    View All
                  </Button>
                </div>
              </>
            )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default Notification;
