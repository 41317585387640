import React from "react";
import Render from "../../component/common/commonRender/commonRender";

export const getallProductsData = () => {
  return {
    Header: [
      {
        title: "Fitting Name",
        field: "new_fitting_name",
        render: (rowData) => <Render>{rowData.new_fitting_name}</Render>,
      },
      {
        title: "Power",
        field: "new_fitting_power",
        type: "numeric",
        render: (rowData) => <Render>{rowData.new_fitting_power}</Render>,
      },
      {
        title: "Quantity",
        field: "new_quantity",
        type: "numeric",
        render: (rowData) => <Render>{rowData.new_quantity}</Render>,
      },
      {
        title: "RRP",
        field: "rrp",
        type: "numeric",
        render: (rowData) => <Render>{rowData.rrp}</Render>,
      },
      {
        title: "Installation Price",
        field: "installation_price",
        type: "numeric",
        render: (rowData) => <Render>{rowData.installation_price}</Render>,
      },
      {
        title: "Accessories Plan & Cost",
        field: "accessories_plan_cost",
        type: "numeric",
        render: (rowData) => (
          <Render>{Number(rowData.accessories_plan_cost)}</Render>
        ),
      },
      {
        title: "Fitting Image",
        field: "new_picture_id",
        editable: "never",
        render: (rowData) => (
          <img
            src={rowData.new_picture_id}
            alt="product-img"
            height={70}
            width={130}
          />
        ),
      },
    ],
    data: [],
  };
};
