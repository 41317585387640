import React from "react";
import Render from "../../component/common/commonRender/commonRender";
import moment from "moment";
import { Badge } from "reactstrap";
import Button from "../../component/common/button/button";

export const InvoiceData = {
  Header: [
    {
      title: "Invoice",
      field: "_id",
      editable: "never",
      render: (rowData) => (
        <div>
          <Button
            full={true}
            onClick={() => {
              window.open(rowData.receipt_url);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
    {
      title: "ID",
      field: "_id",
      editable: "never",
      render: (rowData) => <Render>{rowData._id}</Render>,
    },
    // {
    //   title: "Charge ID",
    //   field: "charge_id",
    //   editable: "never",
    //   render: (rowData) => <Render>{rowData.charge_id}</Render>,
    // },
    {
      title: "Amount",
      editable: "never",
      field: "amount",
      render: (rowData) => <Render>{rowData.amount}</Render>,
    },
    {
      title: "Paid Date",
      editable: "never",
      field: "paid_date",
      render: (rowData) => (
        <Render>{moment(rowData.paid_date).format("DD-MM-YYYY")}</Render>
      ),
    },
    {
      title: "Payment Method",
      field: "payment_method",
      editable: "never",
      render: (rowData) => <Render>{rowData.payment_method}</Render>,
    },
    {
      title: "Card Last 4 Digit",
      field: "last4",
      editable: "never",
      render: (rowData) => <Render>{rowData.last4}</Render>,
    },
    // {
    //   title: "Expiry Month",
    //   field: "exp_month",
    //   editable: "never",
    //   render: (rowData) => <Render>{rowData.exp_month}</Render>,
    // },
    // {
    //   title: "Expiry Year",
    //   field: "exp_year",
    //   editable: "never",
    //   render: (rowData) => <Render>{rowData.exp_year}</Render>,
    // },
    {
      title: "E-Mail",
      field: "receipt_email",
      editable: "never",
      render: (rowData) => <Render>{rowData.receipt_email}</Render>,
    },
    {
      title: "Status",
      field: "status",
      editable: "never",
      render: (rowData) => {
        return rowData.is_active === true ? (
          <Badge color="success">Active</Badge>
        ) : (
          <Badge color="danger">Inactive</Badge>
        );
      },
    },
  ],
  data: [],
};
