import React from "react";
import "./navitem.css";
import { setPlanExpireModal } from "../../../store/Actions";
import { connect } from "react-redux";
import {
  AdminString,
  AdminTypeString,
  // SuperAdminString,
} from "../../../contants/constantVariable";

class Navitem extends React.Component {
  //eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  condition = () => {
    return (
      this.props.history.location.pathname ===
        "/admin/" + this.props.routeName ||
      this.props.history.location.pathname ===
        "/admin/" + this.props.childScreen + "/" + this.props.ParamsId.id ||
      this.props.history.location.pathname ===
        "/super_admin/" + this.props.routeName ||
      this.props.history.location.pathname ===
        "/super_admin/" + this.props.childScreen + "/" + this.props.ParamsId.id
    );
  };

  handleClick = () => {
    const { history, routeName } = this.props;
    if (localStorage.getItem(AdminTypeString) === AdminString) {
      if (
        routeName === "Profile" ||
        routeName === "Current Package" ||
        routeName === "dashboard"
      ) {
        history.push(this.props.linkTo);
      } else {
        localStorage.getItem("plan_expire") === "true"
          ? this.props.dispatch(setPlanExpireModal({ isModal: true }))
          : history.push(this.props.linkTo);
      }
    } else {
      history.push(this.props.linkTo);
    }
  };
  render() {
    //eslint-disable-next-line
    const { history, match } = this.props;
    return (
      <div
        className="navitem-container"
        style={{
          backgroundColor: this.condition() ? "#FFF" : "",
        }}
      >
        {this.condition() ? (
          <div className="nav-a-line">&nbsp;</div>
        ) : (
          <div>&nbsp;</div>
        )}
        <img
          src={this.condition() ? this.props.aicon : this.props.icon}
          alt="icon"
        ></img>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          onClick={this.handleClick}
          style={{
            color: this.condition() ? this.props.acolor : this.props.color,
          }}
        >
          {this.props.linkName}
        </a>
      </div>
    );
  }
}

export default connect()(Navitem);
