import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../apiServices/index";
import { CardSubtitle, CardTitle } from "reactstrap";
import "./planPurchase.css";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import TextField from "@material-ui/core/TextField";
import CustomSelect from "./CustomSelect";
import Button from "../../../../component/common/button/button";
// import DurationSelect from "./DurationSelect";
import CardDemo from "./StripePayment";
import { FaCheck } from "react-icons/fa";

class PackagePurchase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      durationList: [],
      selectValue: "",
      selectedDurationId: "",
      selectedPlan: {},
      selectedDuration: {},
      planEligibleError: false,

      isLoading: false,
      showPaymentModal: false,
      isStaticError: false,
    };
  }

  startLoader = () => {
    this.setState({ isLoading: true });
  };

  stopLoader = () => {
    this.setState({ isLoading: false });
  };

  componentWillMount = () => {
    this.getAllPlan();
    this.getPackageDurationList();
  };

  getPackageDurationList = () => {
    //eslint-disable-next-line
    let { durationList } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/plan_duration")
      .then((response) => {
        const responseData = response.data && response.data.data;
        if (response.status === 200) {
          this.setState({ durationList: responseData, isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  handleChangeDuration = (e) => {
    const { value } = e.target;
    let { durationList, selectedDuration } = this.state;
    //eslint-disable-next-line
    durationList.map((item) => {
      if (item._id === value) {
        selectedDuration = item;
      }
    });
    this.setState({
      selectedDurationId: value,
      selectedDuration,
      isStaticError: false,
    });
  };

  getAllPlan = () => {
    this.setState({ isLoading: true });
    api.getWithToken("/plan").then((response) => {
      const responseData = response.data.data;
      this.setState({ isLoading: false });
      if (response.status === 200) {
        this.setState({ data: responseData });
      }
    });
  };

  handleChange = (e) => {
    this.setState({ isLoading: true, planEligibleError: false });
    const { value } = e.target;
    const Plans = this.state.data;
    let selectedPlan;
    //eslint-disable-next-line
    Plans.map((item, i) => {
      if (item._id === value) {
        selectedPlan = item;
      }
    });

    const url = `/current_package/${value}`;
    api
      .getWithToken(url)
      .then((response) => {
        const data = response && response.data && response.data.data;
        if (data.plan_eligible) {
          this.setState({
            selectValue: value,
            selectedPlan,
            isLoading: false,
            isStaticError: false,
          });
        } else {
          this.setState({
            selectValue: "",
            selectedPlan: {},
            planEligibleError: true,
            isStaticError: false,
            isLoading: false,
          });
        }
      })
      .catch((err) => {});
  };

  handleSubmit = () => {
    //eslint-disable-next-line
    const { selectedPlan, selectedDuration } = this.state;
    if (
      Object.keys(selectedPlan).length !== 0
      // &&
      // Object.keys(selectedDuration).length !== 0
    ) {
      this.setState({ showPaymentModal: true });
    } else {
      this.setState({ isStaticError: true });
    }
  };

  render() {
    const { data, selectedPlan, selectedDuration } = this.state;

    let amount = (selectedPlan && selectedPlan.price) || 0;
    let multiplicationFactore =
      (selectedDuration && selectedDuration.multiply_factor) || 1;
    let price = amount * multiplicationFactore;
    let duration = (selectedPlan && selectedPlan.interval) || "";
    let perUserPrice = price / this.state.selectedPlan.user_limit || 0;
    perUserPrice = perUserPrice > 0 ? perUserPrice.toFixed(2) : perUserPrice;
    let perSurveyPrice = price / this.state.selectedPlan.survey_limit || 0;
    perSurveyPrice =
      perSurveyPrice > 0 ? perSurveyPrice.toFixed(2) : perSurveyPrice;
    let perSchedulePrice = price / this.state.selectedPlan.schedule_limit || 0;
    perSchedulePrice =
      perSchedulePrice > 0 ? perSchedulePrice.toFixed(2) : perSchedulePrice;

    return (
      <div className="col-xl-12 h-100">
        {this.state.isLoading ? (
          <div
            className="loader-container"
            style={{ height: "100%", width: "95%" }}
          >
            <div className="spinner-border text-secondary Loader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <section className="box h-100">
          <header className="panel_header text-uppercase mt-2">
            <FontAwesomeIcon className="mr-1 ml-2" size="1x" icon={faBox} />
            Upgrade Package
          </header>
          <hr className="ml-2 mr-2" />
          <div className="content-body">
            <div className="p-3 pb-0 custom-card">
              <div className="mb-0">
                <CardTitle className="mb-0 text-black text-capitalize  text-center ">
                  Subscribe / Upgrade Now
                </CardTitle>
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <CardSubtitle
                    style={{ lineHeight: 1.5 }}
                    className="mb-0   text-center w-50 text-muted font-weight-light text-small"
                  >
                    Choose a plan for the best bang for your buck
                  </CardSubtitle>
                </div>
                <CustomSelect
                  value={this.state.selectValue}
                  data={data}
                  handleChange={this.handleChange}
                />
                <div className="w-100 mt-3">
                  {/* <DurationSelect
                    value={this.state.selectedDurationId}
                    data={this.state.durationList}
                    handleChange={this.handleChangeDuration}
                  /> */}
                </div>

                <CardTitle className="mb-0 mt-4 text-black text-capitalize  text-center ">
                  <div className="package-amount">
                    <sup>{"\u20ac"}</sup>
                    {price || 0}
                    <sub>/ {duration}</sub>
                  </div>
                </CardTitle>
                <CardSubtitle className="mt-3  mb-0 text-center text-muted font-weight-light">
                  {this.state.selectedPlan.user_limit || 0} Users / {duration}
                </CardSubtitle>
                <CardSubtitle className="mt-1   text-center text-muted font-weight-light">
                  {"\u20ac"} {perUserPrice || 0} / User
                </CardSubtitle>
                <CardSubtitle className="mt-3  mb-0 text-center text-muted font-weight-light">
                  {this.state.selectedPlan.survey_limit || 0} Surveys /{" "}
                  {duration}
                </CardSubtitle>
                <CardSubtitle className="mt-1   text-center text-muted font-weight-light">
                  {"\u20ac"} {perSurveyPrice || 0} / Survey
                </CardSubtitle>
                <CardSubtitle className="mt-3  mb-0 text-center text-muted font-weight-light">
                  {this.state.selectedPlan.schedule_limit || 0} Schedule /{" "}
                  {duration}
                </CardSubtitle>
                <CardSubtitle className="mt-1   text-center text-muted font-weight-light">
                  {"\u20ac"} {perSchedulePrice || 0} / Schedule
                </CardSubtitle>
                {this.state.selectedPlan.what_we_get && (
                  <div
                    className="mt-1 mb-2 text-center text-muted"
                    style={{ fontSize: 18, fontWeight: 400 }}
                  >
                    What you get
                  </div>
                )}

                <ul
                  style={{ listStyleType: "none" }}
                  className="text-center text-muted"
                >
                  {this.state.selectedPlan.what_we_get &&
                    this.state.selectedPlan.what_we_get.map((what, i) => (
                      <li key={i}>
                        <FaCheck
                          style={{
                            color: "#1ab394 ",
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                        </FaCheck>
                        <b>&nbsp;{what.value}</b>
                      </li>
                    ))}
                </ul>

                <div>
                  {this.state.showPaymentModal && (
                    <CardDemo
                      startLoader={this.startLoader}
                      stopLoader={this.stopLoader}
                      amount={price}
                      planId={selectedPlan._id}
                      planName={selectedPlan.name}
                      days={selectedDuration.days}
                      plan_id={selectedPlan.plan_id}
                    />
                  )}
                </div>
                <div className="text-center">
                  {!this.state.showPaymentModal && (
                    <Button
                      type="submit"
                      onClick={this.handleSubmit}
                      full={true}
                    >
                      Buy
                    </Button>
                  )}

                  {this.state.isStaticError && (
                    <div
                      style={{
                        marginTop: "20px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <div className="error-message-box">
                        Please select the package or package duration.
                      </div>
                    </div>
                  )}

                  {this.state.planEligibleError && (
                    <div
                      style={{
                        marginTop: "20px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <div className="error-message-box">
                        This plan is not eligible for you.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PackagePurchase;
