import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLightbulb,
  faTimesCircle,
  faCheckCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";
import PhoneInput from "react-phone-number-input";

class AdminFormInput extends React.Component {
  //eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <p style={{ height: 114 }}>
        <label htmlFor="user_pass">
          {this.props.label}
          <br />

          <div
            className={this.props.hasError ? "error-border" : "input-border"}
          >
            {this.props.isPhoneInput ? (
              <div
                style={{
                  paddingLeft: 5,
                  boxSizing: "border-box",
                }}
              >
                <PhoneInput
                  placeholder="Ex:1234567890"
                  defaultCountry="IE"
                  smartCaret={true}
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </div>
            ) : (
              <input
                type={this.props.type}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.handleChange}
                id={this.props.id}
                className="input"
                size="20"
              />
            )}
          </div>

          {/* {this.props.isPasswordField && this.props.showPassword && (
            <FontAwesomeIcon
              className="float-right close-icon icon-cursor-pointer"
              // style={{ color: SucessColor }}
              size="lg"
              onClick={this.props.handleShowPassword}
              icon={faEye}
            />
          )}

          
          {this.props.isPasswordField && !this.state.showPassword && (
            <FontAwesomeIcon
              className="float-right close-icon icon-cursor-pointer"
              // style={{ color: SucessColor }}
              size="lg"
              onClick={this.props.handleShowPassword}
              icon={faEyeSlash}
            />
          )} */}

          {this.props.isPasswordField ? (
            this.props.showPassword ? (
              <FontAwesomeIcon
                className="float-right close-icon icon-cursor-pointer"
                // style={{ color: SucessColor }}
                size="lg"
                onClick={this.props.handleShowPassword}
                icon={faEye}
              />
            ) : null
          ) : null}

          {this.props.isPasswordField ? (
            !this.props.showPassword ? (
              <FontAwesomeIcon
                className="float-right close-icon icon-cursor-pointer"
                // style={{ color: SucessColor }}
                size="lg"
                onClick={this.props.handleShowPassword}
                icon={faEyeSlash}
              />
            ) : null
          ) : null}

          {!this.props.isPasswordField &&
            !this.props.isFieldChange &&
            this.props.isSubmitting &&
            !this.props.hasError && (
              <FontAwesomeIcon
                className="float-right close-icon"
                style={{ color: SucessColor }}
                size="lg"
                icon={faCheckCircle}
              />
            )}

          {!this.props.isPasswordField &&
            !this.props.isFieldChange &&
            this.props.hasError && (
              <FontAwesomeIcon
                className="float-right close-icon"
                style={{ color: ErrorColor }}
                size="lg"
                icon={faTimesCircle}
              />
            )}
        </label>
      </p>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(AdminFormInput);
