import React from "react";
import { Row, Col } from "reactstrap";

import Logo from "../../../assets/img/newlogo.png";
import ReeValidate from "ree-validate";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLightbulb,
//   faTimesCircle,
//   faCheckCircle,
// } from "@fortawesome/free-regular-svg-icons";
import api from "../../../apiServices/index";
import { connect } from "react-redux";
// import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";
// import { blue } from "@material-ui/core/colors";
// import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import AdminFormInput from "./AdminFormInputs";
import { CountryDropdown } from "react-country-region-selector";
// import cc from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";
import "./countryPicker.css";
import LocaleCurrency from "locale-currency";

class AdminProfile extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "email",
      //eslint-disable-next-line
      ["Mobile No"]: "min:5|max:15",
      //eslint-disable-next-line
      ["Phone No"]: "min:5|max:15",
    });

    this.state = {
      creadiantial: {
        fullname: "",
        email: "",
        company_address: "",
        //eslint-disable-next-line
        ["Mobile No"]: "",
        //eslint-disable-next-line
        ["Phone No"]: "",
        country: "IE",
        currency_code: "EUR",
        currency_symbol: "€",
      },

      fields: {
        fullname: false,
        email: false,
        company_address: false,
        //eslint-disable-next-line
        ["Mobile No"]: false,
        //eslint-disable-next-line
        ["Phone No"]: false,
      },

      Ischecked: false,
      apiError: false,
      apiErrorData: {
        code: 0,
        error: "",
      },
      apiError1: false,
      isSubmitting: false,
      errors: this.validator.errors,
      isLoading: false,
      isMobileValid: true,
      isPhoneValid: true,
    };
  }

  handleCountyChange = (country) => {
    let { creadiantial } = this.state;
    creadiantial.country = country;
    const CurrencyCode = LocaleCurrency.getCurrency(country);
    creadiantial.currency_code = CurrencyCode;
    const symbol = getSymbolFromCurrency(CurrencyCode);
    creadiantial.currency_symbol = symbol;
    this.setState({ creadiantial });
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;
    let { fields } = this.state;
    fields[name] = true;
    creadiantial[name] = value;
    errors.remove(name);
    this.setState({
      creadiantial,
      fields,
      apiError: false,
      apiError1: false,
      // isSubmitting:false,
    });
  };

  trimValues = () => {
    let { creadiantial } = this.state;
    creadiantial.fullname = creadiantial.fullname.trim();
    creadiantial.email = creadiantial.email.trim();
    creadiantial.company_address = creadiantial.company_address.trim();
    creadiantial["Mobile No"] = creadiantial["Mobile No"].trim();
    creadiantial["Phone No"] = creadiantial["Phone No"].trim();
    return creadiantial;
  };

  checkNumbers = () => {
    const { creadiantial } = this.state;
    let isMobileValid = isValidPhoneNumber(creadiantial.mobile_no);
    let isPhoneValid = isValidPhoneNumber(creadiantial.phone_no);
    this.setState({ isMobileValid, isPhoneValid });

    if (isMobileValid && isPhoneValid) {
      return true;
    } else {
      return false;
    }
  };

  setFieldChangeFalse = () => {
    let { fields } = this.state;
    fields.fullname = false;
    fields.email = false;
    fields.company_address = false;
    fields["Mobile No"] = false;
    fields["Phone No"] = false;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true, isLoading: true });
    this.setFieldChangeFalse();
    let creadiantial = this.trimValues();
    // let isNumbersValid = this.checkNumbers();
    const { errors } = this.validator;
    const { history } = this.props;

    this.validator.validateAll(creadiantial).then((success) => {
      if (success) {
        const body = {
          fullname: creadiantial.fullname,
          email: creadiantial.email,
          company_address: creadiantial.company_address,
          mobile_no: creadiantial["Mobile No"],
          phone_no: creadiantial["Phone No"],
          country: creadiantial.country,
          currency_code: creadiantial.currency_code,
          currency_symbol: creadiantial.currency_symbol,
        };

        api
          .putWithToken("/update", body)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              history.push("/verify-link-admin");
            }
          })
          .catch((error) => {
            const data = error.response.data;
            this.setState(
              { apiError: true, isLoading: false, apiErrorData: data },
              () => {}
            );
          });
      } else {
        this.setState({ errors, isLoading: false });
      }
    });
  };

  handleChangeCheckbox = () => {
    this.setState({ Ischecked: !this.state.Ischecked });
  };

  handleTermLink = (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    const { history, match } = this.props;
    let url = window.location.href;
    url = url.split("/admin-profile")[1];
    window.open(`${url}/terms-and-condition`);
  };

  ErrorMessage = (message) => (
    <p style={{ marginTop: "20px" }}>
      <div className="error-message-box">{message}</div>
    </p>
  );

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    {this.state.isLoading ? (
                      <div className="loader-container">
                        <div
                          className="spinner-border text-secondary LoginpageLoader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : null}

                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>
                    <h2
                      style={{
                        textAlign: "center",
                        opacity: "0.5",
                        textTransform: "uppercase",
                      }}
                    >
                      Profile Detail
                    </h2>
                    <form onSubmit={this.handleSubmit} method="POST">
                      <AdminFormInput
                        value={this.state.creadiantial.fullname}
                        handleChange={this.handleChange}
                        label={"Full Name"}
                        type="text"
                        placeholder="Ex: John Wick"
                        name="fullname"
                        id="fullname"
                        isFieldChange={this.state.fields.fullname}
                        isSubmitting={this.state.isSubmitting}
                      />

                      <AdminFormInput
                        value={this.state.creadiantial.email}
                        handleChange={this.handleChange}
                        label={"E-Mail"}
                        type="text"
                        placeholder="Ex: test@yahoo.com"
                        name="email"
                        id="email"
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields.email}
                        hasError={errors.has("email")}
                      />

                      <AdminFormInput
                        value={this.state.creadiantial.company_address}
                        handleChange={this.handleChange}
                        label={"Address"}
                        type="text"
                        placeholder="Ex: 2071 Green Avenue Fremont CA - 94539"
                        name="company_address"
                        id="company_addres"
                        isFieldChange={this.state.fields.company_address}
                        isSubmitting={this.state.isSubmitting}
                      />

                      <p>
                        <label htmlFor="user_pass">
                          Country
                          <CountryDropdown
                            classes="mb-4 mt-1 country-picker form-control"
                            name="Country"
                            valueType="short"
                            labelType="full"
                            value={this.state.creadiantial.country}
                            onChange={(val) => {
                              this.handleCountyChange(val);
                            }}
                          />
                        </label>
                      </p>

                      <AdminFormInput
                        value={this.state.creadiantial["Mobile No"]}
                        handleChange={this.handleChange}
                        label={"Mobile No"}
                        type="number"
                        placeholder="Ex:1234567890"
                        name="Mobile No"
                        id="Mobile No"
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields["Mobile No"]}
                        hasError={errors.has("Mobile No")}
                      />

                      <AdminFormInput
                        value={this.state.creadiantial["Phone No"]}
                        handleChange={this.handleChange}
                        label={"Phone No"}
                        type="number"
                        placeholder="Ex:1234567890"
                        name="Phone No"
                        id="Phone No"
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields["Phone No"]}
                        hasError={errors.has("Phone No")}
                      />

                      {/* <AdminFormInput
                        value={this.state.creadiantial.mobile_no}
                        handleChange={(mobile_no) => {
                          let { creadiantial } = this.state;
                          let { fields } = this.state;
                          if (mobile_no === undefined) {
                            creadiantial.mobile_no = "";
                          } else {
                            creadiantial.mobile_no = mobile_no;
                          }
                          fields.mobile_no = true;
                          this.setState({
                            creadiantial,
                            fields,
                            isMobileValid: true,
                          });
                        }}
                        label={"Mobile No"}
                        isPhoneInput={true}
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields.mobile_no}
                        hasError={!this.state.isMobileValid}
                      /> */}

                      {/* <AdminFormInput
                        value={this.state.creadiantial.phone_no}
                        handleChange={(phone_no) => {
                          let { creadiantial } = this.state;
                          let { fields } = this.state;
                          if (phone_no === undefined) {
                            creadiantial.phone_no = "";
                          } else {
                            creadiantial.phone_no = phone_no;
                          }
                          fields.phone_no = true;
                          this.setState({
                            creadiantial,
                            fields,
                            isPhoneValid: true,
                          });
                        }}
                        label={"Phone No"}
                        isPhoneInput={true}
                        isSubmitting={this.state.isSubmitting}
                        isFieldChange={this.state.fields.phone_no}
                        hasError={!this.state.isPhoneValid}
                      /> */}

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          value={"checkbox1"}
                          checked={this.state.Ischecked}
                          key={"test1"}
                          onChange={this.handleChangeCheckbox}
                          name={"checkbox1"}
                          label={"dispaly name"}
                          ref="check_me"
                        />

                        <label
                          className="custom-control-label"
                          for="customCheck1"
                        >
                          By clicking, you agree to Ledme's &nbsp;
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            style={{ display: "inline-flex" }}
                            href=""
                            onClick={this.handleTermLink}
                          >
                            <p style={{ color: "blue" }}>
                              Terms and Conditions
                            </p>
                          </a>
                        </label>
                      </div>

                      <p className="submit">
                        <input
                          disabled={
                            this.state.creadiantial.fullname === "" ||
                            this.state.creadiantial.email === "" ||
                            this.state.creadiantial.company_address === "" ||
                            this.state.creadiantial["Mobile No"] === "" ||
                            this.state.creadiantial["Phone No"] === "" ||
                            this.state.Ischecked === false
                          }
                          style={{ fontFamily: "roboto-regular" }}
                          onSubmit={this.handleSubmit}
                          type="submit"
                          name="wp-submit"
                          id="wp-submit"
                          className="btn btn-accent btn-block"
                          value="Save"
                        />
                      </p>
                    </form>
                    <p>
                      {errors.has("email") && (
                        <p className="error-message-box">
                          Wrong E-Mail Format. Please enter valid E-mail.
                        </p>
                      )}
                    </p>

                    {errors.has("Mobile No") &&
                      this.ErrorMessage(errors.first("Mobile No"))}

                    {errors.has("Phone No") &&
                      this.ErrorMessage(errors.first("Phone No"))}

                    <p style={{ marginTop: "20px" }}>
                      {!this.state.isMobileValid && (
                        <div className="error-message-box">
                          Invalid Mobile No.
                        </div>
                      )}
                    </p>

                    <p style={{ marginTop: "20px" }}>
                      {!this.state.isPhoneValid && (
                        <div className="error-message-box">
                          Invalid Phone No.
                        </div>
                      )}
                    </p>

                    <p>
                      {this.state.apiError && (
                        <div className="error-message-box">
                          {this.state.apiErrorData.error}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(AdminProfile);
