import React from "react";
import { Row, Col } from "reactstrap";
import { UsersData } from "../../../../contants/Users/users";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../apiServices/index";
import SweetAlert from "react-bootstrap-sweetalert";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: UsersData,
      isLoading: false,
      sweetAlert: false,
    };
  }

  componentWillMount() {
    this.getAllUsers();
  }

  getAllUsers = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/dashboard/all/users")
      .then((response) => {
        const responseData = response.data.data;
        // let newData = [];
        if (response.status === 200) {
          data.data = responseData;
          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/user/${data._id}`)
      .then((response) => {
        // const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getAllUsers();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleUpdate = (data) => {
    let body = {
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      mob_number: data.mob_number,
      password: data.password,
      is_active: data.active,
      user_type: data.user_type,
      tax_percentage: Number(data.tax_percentage),
    };

    this.setState({ isLoading: true });
    api
      .putWithToken(`/user/${data._id}`, body)
      .then((response) => {
        // const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getAllUsers();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  toggalSweetAlert = () => {
    this.setState({ sweetAlert: !this.state.sweetAlert });
  };

  onConfirm = () => {
    this.setState({ sweetAlert: false });
    // this.getContactUs();
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box " style={{ minHeight: "68vh" }}>
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <FontAwesomeIcon
                            style={{ marginRight: "2px" }}
                            size="1x"
                            icon={faUser}
                          />{" "}
                          USERS
                        </header>
                      }
                      deleteTextMsg={"Are you sure want to delete this user?"}
                      NoDataMessage={"No users data found"}
                      PdfFile={"All_Users_List"}
                      isDownload={true}
                      RowEdit={true}
                      RowDelete={true}
                      data={this.state.data}
                      {...this.props}
                      handleUpdate={this.handleUpdate}
                      handleDelete={this.handleDelete}
                      toolbar={true}
                    />
                  </div>

                  {this.state.sweetAlert && (
                    <SweetAlert
                      custom
                      btnSize="lg"
                      show={this.toggalSweetAlert}
                      danger
                      title={
                        this.state.apiError
                          ? this.state.apiErrorData.error
                          : this.state.message
                      }
                      onConfirm={this.onConfirm}
                    ></SweetAlert>
                  )}
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Users;
