import React from "react";
import { getToken } from "../defaultvalues";
import { Route, Redirect } from "react-router-dom";
import {
  AdminString,
  SuperAdminString,
  AdminTypeString
} from "../constantVariable";

export const AdminAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      getToken() &&
      localStorage.getItem(AdminTypeString) === AdminString &&
      localStorage.getItem("is_admin_varify") === "true" ? (
        <Component {...props} />
      ) : (
        <div>
          {localStorage.clear()}
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        </div>
      )
    }
  />
);

export const SuperAdminAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      getToken() &&
      localStorage.getItem(AdminTypeString) === SuperAdminString ? (
        <Component {...props} />
      ) : (
        <div>
          {localStorage.clear()}
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        </div>
      )
    }
  />
);
