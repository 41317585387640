import * as ActionTypes from "../Action-types/index";

const initialstate = {};

const AdminProfile = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SET_ADMIN_PROFILE_DATA:
      return setadminprofiledata(state, payload);

    default:
      return state;
  }
};

const setadminprofiledata = (state, payload) => {
  state = Object.assign({ ...payload });
  return state;
};




export default AdminProfile;
