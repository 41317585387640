import React from "react";
import Render from "../../component/common/commonRender/commonRender";
import { AdminTypeString, AdminString } from "../constantVariable";

export const getallUsersData = () => {
  return {
    Header: [
      {
        title: "Company Name",
        field: "company_name",
        hidden: localStorage.getItem(AdminTypeString) === AdminString,
        editable: "never",
        render: (rowData) => <Render>{rowData.company_name}</Render>,
      },
      {
        title: "First Name",
        field: "first_name",
        render: (rowData) => <Render>{rowData.first_name}</Render>,
      },
      {
        title: "Last Name",
        field: "last_name",
        render: (rowData) => <Render>{rowData.last_name}</Render>,
      },
      {
        title: "Country",
        field: "country",
        editable: "never",
        render: (rowData) => <Render>{rowData.country}</Render>,
      },
      {
        title: "Mobile No",
        field: "mob_number",
        type: "numeric",
        render: (rowData) => <Render>{rowData.mob_number}</Render>,
      },
      {
        title: "E-Mail",
        field: "email",
        editable: "never",
        render: (rowData) => <Render>{rowData.email}</Render>,
      },
      {
        title: "Savings",
        field: "savings",
        editable: "never",
        render: (rowData) =>
          rowData.savings ? (
            <Render>
              {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"}
              {rowData.savings.toFixed(2)}
            </Render>
          ) : (
            "-"
          ),
      },

      {
        title: "Total Surveys",
        field: "total_survey",
        editable: "never",
        render: (rowData) => <Render>{rowData.total_survey}</Render>,
      },
      {
        title: "Total Fittings",
        field: "total_fittings",
        editable: "never",
        render: (rowData) => <Render>{rowData.total_quantity}</Render>,
      },
      {
        title: "Active From",
        field: "created_at",
        editable: "never",
        render: (rowData) => (
          <Render>
            <div
              style={{
                minHeight: "2.5rem",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {rowData.created_at}
            </div>
          </Render>
        ),
      },
    ],
    data: [],
  };
};
