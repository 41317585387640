import React, { Component } from "react";
import Modal from "../model/model";
import "./imageModal.css";
import { ModalHeader, ModalFooter } from "reactstrap";
import Button from "../../common/button/button";
import Dropzone from "../../common/Dropzone/Dropzone";
// import { faTrashAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ImageView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      isUpdate: false,
      UploadedImage: null,
    };
  }

  toggle = () => {
    this.setState({ isOpenModal: !this.state.isOpenModal });
  };

  openModal = () => {
    this.setState({ isOpenModal: true, isUpdate: false });
  };

  closeModal = () => {
    this.setState({ isOpenModal: false });
  };

  changeIsUpadate = () => {
    this.setState({ isUpdate: !this.state.isUpdate });
  };

  onUploadImage = (file) => {
    this.setState({ UploadedImage: file });
  };

  renderImage = ({ isSmall, src }) => {
    if (!isSmall) {
      return (
        <img
          className="fitting-image"
          onClick={this.openModal}
          style={{ height: "100%", width: "100%" }}
          src={src}
          alt="fitting"
        />
      );
    } else {
      return (
        <img
          className="fitting-image"
          onClick={this.openModal}
          style={{ height: "70px", width: "130px" }}
          src={src}
          alt="fitting"
        />
      );
    }
  };

  render() {
    const { isUpdate } = this.state;

    return (
      <div>
        {this.state.UploadedImage &&
          this.renderImage({
            isSmall: true,
            src: this.state.UploadedImage.dataURL,
          })}

        {!this.state.UploadedImage &&
          this.renderImage({ isSmall: true, src: this.props.pictures })}

        <Modal isOpen={this.state.isOpenModal}>
          <ModalHeader toggle={this.closeModal}>
            <div>{this.props.modalTitle}</div>
          </ModalHeader>
          {isUpdate ? (
            <div style={{ padding: "1rem" }}>
              <Dropzone
                onUploadImage={this.onUploadImage}
                fittingID={this.props.rowData._id}
                imageField={this.props.imageField}
              />
            </div>
          ) : (
            <div style={{ height: "336px", width: "100%" }}>
              {this.state.UploadedImage &&
                this.renderImage({
                  isSmall: false,
                  src: this.state.UploadedImage.dataURL,
                })}
              {!this.state.UploadedImage &&
                this.renderImage({ isSmall: false, src: this.props.pictures })}
            </div>
          )}
          <ModalFooter>
            <Button full={true} onClick={this.changeIsUpadate}>
              {isUpdate ? "Back" : "Upload Image"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ImageView;
