import React from "react";
import { Table } from "reactstrap";
import "./detailedOverviewTabel.css";

class DetailedScheduleTable extends React.Component {
  render() {
    const { scheduleData } = this.props;
    return (
      <div className="detail-overview-table mb-3">
        <Table>
          <tbody>
            <tr>
              <td className="detail-over-view-td pt-3">Total fittings</td>
              <td className="detailtablenumber pt-3">
                {scheduleData.total_fitting}{" "}
              </td>
            </tr>
            <tr>
              <td
                style={{ color: "#1ab394", fontSize: "12px" }}
                className="pt-3"
              >
                Schedule Passed
              </td>
              <td className="detailtablenumber">
                {scheduleData.passed_schedule}
              </td>
            </tr>
            <tr>
              <td style={{ color: "#1ab394", fontSize: "12px" }}>
                Schedule Failed
              </td>
              <td className="detailtablenumber pt-3">
                {scheduleData.failed_schedule}
              </td>
            </tr>
            <tr>
              <td
                style={{ color: "#1ab394", fontSize: "12px" }}
                className="pt-3"
              >
                Incomplete Schedule
              </td>
              <td className="detailtablenumber pt-3">
                {scheduleData.incomplete_schedule}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DetailedScheduleTable;
