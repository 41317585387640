import React from "react";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextField";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLock,
//   faBuilding,
//   faUser,
//   faEyeSlash,
//   faEye,
// } from "@fortawesome/free-solid-svg-icons";
import PackageLoader from "./PackageLoader";
import api from "../../../../apiServices/index";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import { Input } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";

let disabled = false;
class CreatePackage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        // productId: "",
        packageName: "",
        userLimit: "",
        price: "",
        surveyLimit: "",
        packageDuration: "",
        scheduleLimit: "",
      },
      what_we_get: [{ value: "" }],
      isLoading: false,
      successMessage: false,
      errorMessage: false,
      isError: false,
    };
  }

  renderLabel = (name) => <div style={{ marginLeft: 10 }}>{name}</div>;

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  createPackage = (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    const { data } = this.state;

    this.setState({ isLoading: true });
  };

  buttonConditions = () => {
    return (
      this.state.data.packageName === "" ||
      // this.state.data.productId == "" ||
      this.state.data.price === "" ||
      this.state.data.userLimit === "" ||
      this.state.data.surveyLimit === "" ||
      this.state.data.scheduleLimit === "" ||
      this.state.data.packageDuration === "" ||
      disabled === true
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { data } = this.state;
    this.setState({
      isLoading: true,
      successMessage: false,
      errorMessage: false,
    });
    const body = {
      name: data.packageName,
      price: data.price,
      interval: data.packageDuration,
      nickname: data.packageName,
      currency: "eur",
      user_limit: data.userLimit,
      survey_limit: data.surveyLimit,
      schedule_limit: data.scheduleLimit,
      package_duration: data.packageDuration,
      what_we_get: JSON.parse(JSON.stringify(this.state.what_we_get)),
    };

    api
      .postWithToken("/plan", body)
      .then((response) => {
        this.setState({ isLoading: false, successMessage: true });
        window.location.reload(true);
      })
      .catch((err) => {
        this.setState({ errorMessage: true, isLoading: false });
      });
  };

  renderSuccess = () => {
    return (
      <p
        style={{
          marginTop: "10px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <div className="success-message-box">Package created successfully.</div>
      </p>
    );
  };

  renderFail = () => {
    return (
      <p
        style={{
          marginTop: "10px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <div className="error-message-box">Package could not created.</div>
      </p>
    );
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let { what_we_get } = this.state;
    const list = [...what_we_get];
    list[index][name] = value;
    this.setState({
      what_we_get: list,
    });
  };

  handleRemoveClick = (index) => {
    let { what_we_get } = this.state;
    const list = [...what_we_get];
    list.splice(index, 1);
    this.setState({
      what_we_get: list,
    });
  };

  handleAddClick = () => {
    let { what_we_get } = this.state;
    this.setState({ what_we_get: [...what_we_get, { value: "" }] });
  };

  render() {
    return (
      <div className="col-xl-12">
        <PackageLoader isLoading={this.state.isLoading} />
        <section className="box">
          <header className="panel_header text-uppercase">
            <img src={surveysIcon} alt="survey" />
            {" Create Package"}
          </header>
          <hr />
          <div className="content-body">
            <div className="content-body-inside">
              <form onSubmit={this.createPackage}>
                {/* <div>
                  {this.renderLabel("Product ID")}
                  <TextInput
                    placeholder={"Enter Product ID"}
                    search={false}
                    value={this.state.data.productId}
                    name="productId"
                    onChange={this.handleChange}
                  />
                </div> */}

                <div className="mt-1">
                  {this.renderLabel("Package Name")}
                  <TextInput
                    placeholder={"Enter Package Name"}
                    search={false}
                    name="packageName"
                    values={this.state.data.packageName}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="mt-4">
                  {this.renderLabel("User Limit")}
                  <TextInput
                    type="number"
                    placeholder={"User Limit"}
                    search={false}
                    name="userLimit"
                    value={this.state.data.userLimit}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="mt-4">
                  {this.renderLabel("Survey Limit")}
                  <TextInput
                    type="number"
                    placeholder={"Survey Limit"}
                    search={false}
                    name="surveyLimit"
                    value={this.state.data.surveyLimit}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="mt-4">
                  {this.renderLabel("Schedule Limit")}
                  <TextInput
                    type="number"
                    placeholder={"Schedule Limit"}
                    search={false}
                    name="scheduleLimit"
                    value={this.state.data.scheduleLimit}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="mt-4">
                  {this.renderLabel("Price/month")}
                  <TextInput
                    type="number"
                    placeholder={"Enter Price"}
                    search={false}
                    name="price"
                    onChange={this.handleChange}
                    value={this.state.data.price}
                  />
                </div>

                <div className="mt-4">
                  {this.renderLabel("Package Duration")}
                  <Input
                    type="select"
                    id="country"
                    name="packageDuration"
                    onChange={this.handleChange}
                    value={this.state.data.packageDuration}
                    className="ml-2 mt-2"
                  >
                    <option selected>Select</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </Input>
                </div>

                <div className="mt-4">
                  {this.renderLabel("What you get")}

                  {this.state.what_we_get.map((what, i) => {
                    return (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                          className="mb-2"
                        >
                          <TextInput
                            type="text"
                            placeholder={"Enter Value"}
                            search={false}
                            name="value"
                            onChange={(e) => this.handleInputChange(e, i)}
                            value={what.value}
                          />

                          <div className="mt-3 ml-2">
                            {this.state.what_we_get &&
                              this.state.what_we_get.length !== 1 && (
                                <FaTrashAlt
                                  style={{
                                    color: "#363A41",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => this.handleRemoveClick(i)}
                                ></FaTrashAlt>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="mt-4 ml-2">
                    <Button
                      onClick={this.handleAddClick}
                      full={true}
                      type="button"
                    >
                      Add
                    </Button>
                  </div>
                </div>

                {this.state.what_we_get &&
                  this.state.what_we_get.map((what) => {
                    return !what.value.trim()
                      ? (disabled = true)
                      : (disabled = false);
                  })}
                <div className="mt-4 ml-2">
                  <Button
                    disabled={this.buttonConditions()}
                    onClick={this.handleSubmit}
                    full={true}
                  >
                    CREATE
                  </Button>
                </div>
                {this.state.successMessage && this.renderSuccess()}
                {this.state.errorMessage && this.renderFail()}
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CreatePackage;
