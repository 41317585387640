import React from "react";
import { Row, Col } from "reactstrap";
import { getallUsersData } from "../../../../contants/allUsersData/allUsersData";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../apiServices/index";
import {
  AdminTypeString,
  AdminString,
  // SuperAdminString
} from "../../../../contants/constantVariable";

class AllUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
    };
  }

  componentWillMount() {
    const usersData = getallUsersData();
    this.setState({ data: usersData }, () => {
      this.getAllUsers();
    });
  }

  getAllUsers = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/dashboard/all/users")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          data.data = responseData;
          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/user/${data._id}`)
      .then((response) => {
        //eslint-disable-next-line
        const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getAllUsers();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleUpdate = (data) => {
    let body = {
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      mob_number: data.mob_number,
    };

    this.setState({ isLoading: true });
    api
      .putWithToken(`/user/${data._id}`, body)
      .then((response) => {
        //eslint-disable-next-line
        const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getAllUsers();
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box " style={{ minHeight: "68vh" }}>
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <FontAwesomeIcon
                            style={{ marginRight: "2px" }}
                            size="1x"
                            icon={faUser}
                          />{" "}
                          USERS STATISTIC
                        </header>
                      }
                      deleteTextMsg={"Are you sure want to delete this user?"}
                      NoDataMessage={"No users data found"}
                      RowEdit={
                        localStorage.getItem(AdminTypeString) === AdminString
                          ? false
                          : true
                      }
                      RowDelete={
                        localStorage.getItem(AdminTypeString) === AdminString
                          ? false
                          : true
                      }
                      data={this.state.data}
                      handleDelete={this.handleDelete}
                      handleUpdate={this.handleUpdate}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AllUsers;
