import React, { Component } from "react";
import Modal from "../model/model";
import Button from "../button/button";
import { ModalHeader, ModalBody, Container } from "reactstrap";
import { faMapMarkerAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInputWithButton from "../../../component/common/textinputwithbutton/textinputwithbutton";
import "./AddressModal.css";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import api from "../../../apiServices/index";

class AddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      address: this.props.manualAddress ? this.props.manualAddress : "",
      savedAddress: this.props.manualAddress ? this.props.manualAddress : "",
      isEdit: false,
      isLoading: false,
    };
  }

  openModal = () => {
    this.setState({ isOpenModal: true, isEdit: false });
  };

  closeModal = () => {
    this.setState({ isOpenModal: false });
  };

  showAddressInMap = (e) => {
    window.open(`http://maps.google.com?q=${this.props.surveyAddress}`);
  };

  handleChange = (e) => {
    //eslint-disable-next-line
    const { value, name } = e.target;
    this.setState({ address: value });
  };

  handleEdit = () => {
    this.setState({ isEdit: true, address: this.state.savedAddress });
  };

  updateAddress = (id) => {
    const { address } = this.state;
    let body = {
      manual_address: address,
    };
    this.setState({ isLoading: true });
    api
      .postWithToken(`/survey/${id}`, body)
      .then((response) => {
        //eslint-disable-next-line
        const responseData = response.data.data;
        if (response.status === 200) {
          this.setState({ isLoading: false, savedAddress: address }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div>
        <Button
          full={true}
          onClick={(e) => {
            e.stopPropagation();
            this.openModal();
          }}
        >
          Show Address
        </Button>
        <Modal isOpen={this.state.isOpenModal}>
          {this.state.isLoading && (
            <div
              className="loader-container"
              style={{ height: "100%", width: "98%" }}
            >
              <div
                className="spinner-border text-secondary Loader"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <ModalHeader toggle={this.closeModal}>Schedule Address</ModalHeader>
          <ModalBody>
            <Container>
              <div>
                <div style={{ marginTop: 10 }}>
                  {this.state.isEdit ? (
                    <div>
                      <div
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Address
                      </div>
                      <div className="modal-input">
                        <TextInputWithButton
                          isLoading={this.state.isUserNameLoading}
                          search={false}
                          value={this.state.address}
                          onChange={this.handleChange}
                          btnLable="Save"
                          name="Address"
                          id="Address"
                          btnDisable={this.state.address !== "" ? false : true}
                          onButtonClick={() => {
                            this.setState({ isEdit: false });
                            this.updateAddress(this.props.suveyId);
                          }}
                          button={true}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#000000" }}
                            size="1x"
                            icon={faHome}
                          />
                        </TextInputWithButton>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            marginRight: 5,
                          }}
                        >
                          Address:
                        </p>
                        {this.state.savedAddress}
                      </div>
                      {this.props.isEdit && (
                        <div>
                          <IconButton onClick={this.handleEdit}>
                            <CreateIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    height: 48,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      <FontAwesomeIcon
                        onClick={this.handleEdit}
                        size="1x"
                        icon={faMapMarkerAlt}
                      />
                    </p>
                    {this.props.surveyAddress}
                  </div>
                </div>

                <div style={{ marginBottom: 15 }}>
                  <Button full={true} onClick={this.showAddressInMap}>
                    Show Address In Map
                  </Button>
                </div>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AddressModal;
