import { combineReducers } from "redux";
import Search from "./search";
import adminProfile from "./adminProfile";
import ParamsId from "./pramasId";
import ServerError from './serverError'
import LoadedCompanyData from "./LoadedCompanyData";
import QuotationLoader from "./QuotaionLoader"
import planExpire from './planExpire'

const RootReducer = combineReducers({
  Search,
  adminProfile,
  ParamsId,
  LoadedCompanyData,
  ServerError,
  QuotationLoader,
  planExpire
});

export default RootReducer;
