import * as ActionTypes from "../Action-types/index";

const initialstate = {
  id: null
};

const ParamsId = (state = initialstate, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SET_PARAMS_ID:
      return setParamsId(state, payload);
    case ActionTypes.RESET_PARAMS_ID:
      return resetParamsId(state, payload);
    default:
      return state;
  }
};

const resetParamsId = (state, payload) => {
  state = Object.assign({ id: null });
  return state;
};

const setParamsId = (state, payload) => {
  state = Object.assign({ id: payload });
  return state;
};

export default ParamsId;
