export const AdminTypeString =
  "1faba5678op36dfjyumlp36972wcgtrsjdkfjdkas6oidvnsfg32auerhfeyrhjasfdlk698ajdfjkajkdf";
export const AdminString =
  "Riijsdhjnnvj551asdfkjsdak32132564dsfajrekuiarrupajkdfsajknvhadf6354asdhfjhj2asfg5asf4a1";
export const SuperAdminString =
  "efhjashdfkjkjsakj156453asdhfjhajhjdashfj5adftavadsf4a5rtyasg2af5aser4agtydkldyww24";

export const GetFormattedDate = date => {
  let AlFormttedDate = date;
  date = new Date(date);
  date = date.toLocaleString();
  if (date === "Invalid Date") {
    return AlFormttedDate;
  } else {
    return date;
  }
};
