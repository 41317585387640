import React from "react";
import { Col, Row } from "reactstrap";
import { LoadedScheduleData } from "../../../../contants/LoadedScheduleData/LoadedScheduleData";
import * as Actions from "../../../../store/Actions/index";
import { connect } from "react-redux";
import api from "../../../../apiServices/index";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import Calendar from "../../../../assets/icons/calendar.png";

class LoadedSchedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subHeaderHeight: 0,
      data: LoadedScheduleData,
      isLoading: false,
    };
  }

  getLoadedScheduleData = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    let id = this.props.match.params.id;
    this.props.dispatch(Actions.setParamsId(id));
    api
      .getWithToken(`/schedule/${id}/fitting`)
      .then((res) => {
        const resData = res && res.data && res.data.data;
        if (res.status === 200) {
          data.data = resData;

          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((err) => {
        const data = err && err.response && err.response.data;
        this.setState(
          { apiError: true, apiErrorData: data, isLoading: false },
          () => {}
        );
      });
  };

  componentWillMount() {
    this.getLoadedScheduleData();
  }

  componentWillUnmount() {
    this.props.dispatch(Actions.ResetParamsId());
    this.props.dispatch(Actions.resetLoadedCompanyData());
  }

  handleUpdate = (data) => {
    let body = new FormData();
    body.append("location", data.location);
    body.append("description", data.description);
    body.append("last_test_date", data.last_test_date);
    body.append("last_test_duration", data.last_test_duration);
    body.append("next_test_due_date", data.next_test_due_date);
    body.append("next_test_duration", data.next_test_duration);
    body.append("message", data.message);
    body.append("status", data.status);
    body.append("schedule_number", data.schedule_number);
    body.append("is_remember", data.is_remember);

    this.setState({ isLoading: true });
    api
      .postWithToken(`/schedule/fitting/${data._id}`, body)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getLoadedScheduleData();
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  handleDelete = (data) => {
    this.setState({ isLoading: true });
    api
      .deleteWithToken(`/schedule/fitting/${data._id}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false }, () => {
            this.getLoadedScheduleData();
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    //eslint-disable-next-line
    let { subHeaderHeight } = this.state;
    // eslint-disable-next-line
    subHeaderHeight = subHeaderHeight + 92;

    return (
      <div>
        <div className="content" style={{ marginTop: "110px" }}>
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box " style={{ height: "150%" }}>
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img
                            src={Calendar}
                            alt="schedule"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          {" Schedule Fittings"}
                        </header>
                      }
                      deleteTextMsg={
                        "Are you sure want to delete this fitting?"
                      }
                      NoDataMessage={"Users have not created any Fittings yet"}
                      columnAdded={this.columnAdded}
                      HandleshowMore={this.showMore}
                      RowEdit={true}
                      RowDelete={true}
                      handleDelete={this.handleDelete}
                      handleUpdate={this.handleUpdate}
                      showMore={this.state.showMore}
                      data={this.state.data}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(LoadedSchedules);
