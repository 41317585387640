import React from "react";
import { Row, Col } from "reactstrap";
// import Button from "../../../../component/common/button/button";
// import TextInput from "../../../../component/common/TextField";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLock,
//   faBuilding,
//   faUser,
//   faEyeSlash,
//   faEye,
// } from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import api from "../../../../apiServices/index";
import "./Package.css";
// import TextInputWithButton from "../../../../component/common/textinputwithbutton/textinputwithbutton";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import {
  getPackagesColumn,
  getPackagesDurationColumn,
} from "../../../../contants/Packages/PackagesData";
import PackageForm from "./PackageForm";
// import { data } from "currency-codes";
import DeleteModal from "../../../../component/common/model/DeleteError";

class CreateAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPackageDurationLoading: false,
      data: [],
      isModal: false,
      packageDurationList: [],
      errorData: {
        code: 0,
        error: "",
      },
    };
  }

  componentWillMount() {
    const usersData = getPackagesColumn();
    const packageDuration = getPackagesDurationColumn();
    this.setState(
      { data: usersData, packageDurationList: packageDuration },
      () => {
        this.getAllPackges();
        this.getPackageDurationList();
      }
    );
  }

  getPackageDurationList = () => {
    let { packageDurationList } = this.state;
    this.setState({ isPackageDurationLoading: true });
    api
      .getWithToken("/plan_duration")
      .then((response) => {
        const responseData = response.data && response.data.data;
        if (response.status === 200) {
          packageDurationList.data = responseData;
          this.setState({
            packageDurationList,
            isPackageDurationLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ isPackageDurationLoading: false });
      });
  };

  getAllPackges = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/plan")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          data.data = responseData;
          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handlePackageDurationUpdate = (rowData) => {
    this.setState({ isPackageDurationLoading: true });

    const body = {
      name: rowData.name,
      days: rowData.days,
    };

    const url = `/plan_duration/${rowData._id}`;
    api
      .putWithToken(url, body)
      .then((response) => {
        this.getPackageDurationList();
        this.setState({ isPackageDurationLoading: false });
      })
      .catch((err) => {
        this.setState({ isPackageDurationLoading: false });
      });
  };

  handleUpdate = (rowData) => {
    this.setState({ isLoading: true });

    const body = {
      is_active: rowData.is_active === "true" ? true : false,
    };
    const url = `/plan/${rowData._id}`;
    api
      .putWithToken(url, body)
      .then((response) => {
        this.getAllPackges();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorData: err.response.data,
          isModal: true,
        });
      });
  };

  handleDelete = (rowData) => {
    const url = `/plan/${rowData._id}`;
    this.setState({ isLoading: true });
    api
      .deleteWithToken(url)
      .then((response) => {
        this.getAllPackges();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({
          isModal: true,
          errorData: err.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <div>
        <DeleteModal
          message={this.state.errorData.error}
          isModal={this.state.isModal}
          handleClose={() => {
            this.setState({ isModal: false });
          }}
        />
        <div className="content">
          <Row>
            <Col xs={4} md={4}>
              <PackageForm />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box">
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          PACKAGES
                        </header>
                      }
                      deleteTextMsg={
                        "Are you sure want to delete this package ?"
                      }
                      NoDataMessage={"No any package created yet."}
                      RowEdit={true}
                      RowDelete={true}
                      data={this.state.data}
                      handleDelete={this.handleDelete}
                      handleUpdate={this.handleUpdate}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box">
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isPackageDurationLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          Package Duration
                        </header>
                      }
                      deleteTextMsg={
                        "Are you sure want to delete this package ?"
                      }
                      NoDataMessage={"No any package duration created yet."}
                      RowEdit={
                        this.state.packageDurationList.data.length !== 0
                          ? true
                          : false
                      }
                      RowDelete={false}
                      data={this.state.packageDurationList}
                      handleUpdate={this.handlePackageDurationUpdate}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateAdmin;
