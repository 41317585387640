import React from "react";
import Render from "../../component/common/commonRender/commonRender";

export const getallContactUs = () => {
  return {
    Header: [
      {
        title: "Title",
        field: "title",
        render: (rowData) => <Render>{rowData.title}</Render>,
      },
      {
        title: "URL",
        field: "url",
        render: (rowData) => <Render>{rowData.url}</Render>,
      },
    ],
    data: [],
  };
};
