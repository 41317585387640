import React from "react";
import TextInputWithButton from "../../../../component/common/textinputwithbutton/textinputwithbutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class PasswordInput extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <div
          style={{
            marginLeft: "10px",
            marginBottom: "5px",
            color: this.props.hasError ? "red" : "#000000",
          }}
        >
          {this.props.lable}
        </div>
        <div>
          <TextInputWithButton
            style={{
              borderColor: this.props.hasError
                ? "red"
                : "rgba(55, 71, 79, 0.4)",
            }}
            search={false}
            placeholder={this.props.placeholder}
            type={this.props.type}
            height=""
            width=""
            onChange={this.props.handleChange}
            disabled={this.props.disabled}
            name={this.props.name}
            value={this.props.value}
            button={false}
            endIcon={
              this.props.isShowPassword ? (
                <FontAwesomeIcon
                  onClick={this.props.showPassword}
                  size="1x"
                  icon={faEye}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={this.props.showPassword}
                  size="1x"
                  icon={faEyeSlash}
                />
              )
            }
          >
            <FontAwesomeIcon size="1x" icon={faLock} />
          </TextInputWithButton>
        </div>
      </div>
    );
  }
}

export default PasswordInput;
