import React from "react";
import Render from "../../component/common/commonRender/commonRender";
// import ImageView from "../../component/common/CompanyimageModal/companyImagemodal";

export const UsersData = {
  Header: [
    {
      title: "First Name",
      field: "first_name",
      render: (rowData) => (
        <Render>
          {rowData.first_name}
          {/* {console.log("======rowdata======", rowData.company_logo)} */}
        </Render>
      ),
    },
    {
      title: "Last Name",
      field: "last_name",
      render: (rowData) => <Render>{rowData.last_name}</Render>,
    },
    {
      title: "Country",
      editable: "never",
      field: "country",
      render: (rowData) => <Render>{rowData.country}</Render>,
    },
    {
      title: "Mobile No",
      field: "mob_number",
      render: (rowData) => <Render>{rowData.mob_number}</Render>,
    },
    {
      title: "E-Mail",
      field: "email",
      editable: "never",
      render: (rowData) => <Render>{rowData.email}</Render>,
    },
    {
      title: "Username",
      field: "username",
      editable: "never",
      render: (rowData) => <Render>{rowData.username}</Render>,
    },
    {
      title: "Password",
      field: "password",
      render: (rowData) => <Render>{rowData.password}</Render>,
    },
    // user_type: "senior amature"
    {
      title: "User Type",
      field: "user_type",
      lookup: {
        //eslint-disable-next-line
        ["senior amature"]: "Professional",
        //eslint-disable-next-line
        ["amature"]: "Single Survey",
      },
      editable: "onUpdate",
      render: (rowData) => (
        <Render>
          {rowData.user_type === "senior amature"
            ? "Professional"
            : "Single Survey"}
        </Render>
      ),
    },
    {
      title: "Status",
      field: "active",
      lookup: { true: "Active", false: "Deactive" },
      editable: "onUpdate",
      render: (rowData) => {
        return rowData.active === true ? (
          <div style={{ color: "green" }}>Active</div>
        ) : (
          <div style={{ color: "red" }}>Deactive</div>
        );
      },
    },
    {
      title: "Active From",
      field: "created_at",
      editable: "never",
      render: (rowData) => <Render>{rowData.created_at}</Render>,
    },
    {
      title: "Proposal Text",
      field: "proposal_text",
      editable: "never",

      render: (rowData) => (
        <Render>
          <span style={{ textOverflow: "break-word", width: 20 }}>
            {rowData.proposal_text}
          </span>
        </Render>
      ),
    },
    {
      title: "Company Logo",
      field: "company_logo",
      editable: "never",
      render: (rowData) => (
        <img
          src={rowData.company_logo}
          alt=""
          style={{ height: "80px", width: "100%" }}
        />
        /* <ImageView
          rowData={rowData}
          imageField={0}
          modalTitle="Company Logo"
          pictures={rowData.company_logo}
        /> */
      ),
    },
    // {
    //   title: "Saved Discount",
    //   field: "discount_percentage",
    //   editable: "never",
    //   render: (rowData) => (
    //     <Render>{rowData.customer_discounts.discount_percentage}</Render>
    //   ),
    // },
    // {
    //   title: "Tax Percentage",
    //   field: "tax_percentage",
    //   type: "number",
    //   render: (rowData) => <Render>{rowData.tax_percentage}</Render>,
    // },
  ],
  data: [],
};
