import React from "react";
import { Row, Col } from "reactstrap";
import { getRecentSurvey } from "../../../../contants/RecentSurvey/RecentSurvey";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";
import TimeIcon from "../../../../assets/icons/time.png";
import DetailedOvereviewTable from "../../../../component/DetailedOverviewTable/DetailedOvereviewTable";
import ChartBar from "../../../../component/common/Chart/chart";
import { connect } from "react-redux";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import api from "../../../../apiServices/index";
import ScheduleChart from "./ScheduleChart";

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      chartData: [],
      activityData: {
        avarage_kwh_saved: 0,
        avarage_savings_per_customer: 0,
        chart_data: [],
        maximum_kwh_price: 0,
        minimal_kwh_price: 0,
        new_surveys: 0,
        total_companies: 0,
        total_customers: 0,
        total_energy_savings: 0,
        total_fitting: 0,
        total_savings: 0,
        total_surveys: 0,
        total_users: 0,
      },
      scheduleData: {
        total_fitting: 0,
        passed_schedule: 0,
        failed_schedule: 0,
        incomplete_schedule: 0,
      },
      isLoading: true,
    };
  }

  componentWillMount() {
    let RecentSurvey = getRecentSurvey();
    this.setState({ data: RecentSurvey });
    //eslint-disable-next-line
    let { data, chartData, activityData } = this.state;
    api
      .getWithToken("/dashboard/activity/overview")
      .then((response) => {
        const responseData = response.data.data;
        if (response.status === 200) {
          if (response.data.data === "No Survey data found") {
          } else {
            //eslint-disable-next-line
            responseData.chart_data.map((data) => {
              chartData.push(data.value.toFixed(2));
            });
            this.setState({ activityData: responseData, chartData }, () => {
              api
                .getWithToken("/dashboard/recent/surveys")
                .then((response) => {
                  let responseData = response.data.data;
                  if (response.status === 200) {
                    //eslint-disable-next-line
                    responseData.map((object, i) => {
                      if (this.state.activityData.new_surveys > i) {
                        object.is_recent_survey = true;
                      }
                    });
                    data.data = responseData;
                    this.setState({ data, isLoading: false }, () => {});
                  }
                })
                .catch((error) => {
                  this.setState({ isLoading: false });
                });
            });
          }
        }
      })
      .catch((error) => {});
    this.getScheduleOverviewData();
  }

  getScheduleOverviewData() {
    api
      .getWithToken("/dashboard/schedule/overview")
      .then((res) => {
        const data = res && res.data && res.data.data;
        this.setState({
          scheduleData: data,
        });
      })
      .catch((err) => {});
  }

  render() {
    const { scheduleData } = this.state;

    return (
      <div>
        <div className="content">
          <div className="row margin-0 flexible-row">
            <div className="col-xl-4 col-md-4 col-lg-4 col-sm-6 col-12">
              <div style={{ marginRight: "10px" }}>
                <div className="db_box icon_stats">
                  <i className=" widicon float-left i-note icon-primary"></i>
                  <div className="stats float-left">
                    <h2 className="widtitle">
                      {this.state.activityData.new_surveys}
                    </h2>
                    <span className="widtag">New Surveys</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 col-lg-4 col-sm-6 col-12">
              <div style={{ marginRight: "10px" }}>
                <div className="db_box icon_stats">
                  <i className=" widicon float-left i-note icon-primary"></i>
                  <div className="stats float-left">
                    <h2 className="widtitle">
                      {this.state.activityData.total_surveys}
                    </h2>
                    <span className="widtag">Total Surveys</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4  col-md-4 col-lg-4 col-sm-6 col-12">
              <div className="db_box icon_stats">
                <i className=" widicon float-left i-user icon-primary"></i>
                <div className="stats float-left">
                  <h2 className="widtitle">
                    {this.state.activityData.total_users}
                  </h2>
                  <span className="widtag">Total Users</span>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box ">
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img src={surveysIcon} alt="survey" /> RECENT SURVEYS
                        </header>
                      }
                      NoDataMessage={"Users have not created any survey yet"}
                      RowEdit={false}
                      RowDelete={false}
                      RowClickable={true}
                      data={this.state.data}
                      isLoading={this.state.isLoading}
                      toolbar={true}
                      {...this.props}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4}>
              <div className="col-xl-12">
                <section className="box ">
                  <header className="panel_header">
                    <img src={surveysIcon} alt="survey" /> ACTIVITY OVERVIEW
                  </header>
                  <hr />
                  <div className="content-body">
                    <div className="total-surveys-container">
                      <div>
                        <div className="total-survey-text">Total Surveys</div>
                      </div>
                      <div className="total-survey-number">
                        {this.state.activityData.total_surveys}
                      </div>
                    </div>
                    <div
                      className="total-surveys-container"
                      style={{ marginTop: "0px" }}
                    >
                      <div>
                        <div className="total-survey-text">Total Users</div>
                      </div>
                      <div className="total-survey-number">
                        {this.state.activityData.total_users}
                      </div>
                    </div>
                  </div>
                  <div className="detailed-overview-text">
                    <img
                      src={TimeIcon}
                      style={{ marginTop: "-10px" }}
                      alt="time"
                    />{" "}
                    Detailed Overview
                  </div>
                  <div style={{ marginTop: "3px", marginBottom: "0px" }}>
                    <DetailedOvereviewTable
                      activityData={this.state.activityData}
                    />
                  </div>
                </section>
              </div>
            </Col>
            <Col xs={8} md={8}>
              <div className="Float-Right">
                <div className="col-xl-12">
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="my-chart">
                        <section className="box ">
                          <header
                            style={{ marginLeft: "10px" }}
                            className="panel_header"
                          >
                            <img src={surveysIcon} alt="survey" /> AVERAGE KWH
                            PRICE (KWh)
                          </header>
                          <hr
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          />
                          <div className="content-body">
                            <ChartBar chartData={this.state.chartData} />
                          </div>
                        </section>
                      </div>
                    </Col>
                  </Row>

                  <Row className="widget-for-24">
                    <Col xs={4} md={4}>
                      <div style={{ marginRight: "10px" }}>
                        <section
                          className="box widget-left-right-10 text-center"
                          style={{ padding: "13px 0" }}
                        >
                          <div
                            style={{
                              margin: "20px 15px 15px 15px",
                              fontSize: "40px",
                              fontFamily: "roboto-regular",
                              color: "#565656",
                            }}
                          >
                            {`${this.state.activityData.minimal_kwh_price.toFixed(
                              2
                            )}`}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontFamily: "roboto-regular",
                              color: "#000000",
                              marginBottom: "-5px",
                            }}
                          >
                            Minimum KWh Saved
                          </div>
                        </section>
                      </div>
                    </Col>

                    <Col xs={4} md={4}>
                      <div style={{ marginRight: "10px" }}>
                        <section
                          className="box  text-center"
                          style={{ padding: "13px 0" }}
                        >
                          <div
                            style={{
                              margin: "20px 15px 15px 15px",
                              fontSize: "40px",
                              fontFamily: "roboto-regular",
                              color: "#565656",
                            }}
                          >
                            {` ${this.state.activityData.avarage_kwh_saved.toFixed(
                              2
                            )}`}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontFamily: "roboto-regular",
                              color: "#000000",
                              marginBottom: "-5px",
                            }}
                          >
                            Average KWh Saved
                          </div>
                        </section>
                      </div>
                    </Col>

                    <Col xs={4} md={4} style={{ paddingRight: "0px" }}>
                      <section
                        className="box  text-center"
                        style={{ padding: "13px 0" }}
                      >
                        <div
                          style={{
                            margin: "20px 15px 15px 15px",
                            fontSize: "40px",
                            fontFamily: "roboto-regular",
                            color: "#565656",
                          }}
                        >
                          {`${this.state.activityData.maximum_kwh_price.toFixed(
                            2
                          )}`}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontFamily: "roboto-regular",
                            color: "#000000",
                            marginBottom: "-5px",
                          }}
                        >
                          Maximum KWh Saved
                        </div>
                      </section>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <ScheduleChart
              scheduleData={scheduleData}
              activityData={this.state.activityData}
            />
          </Row>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    Search: state.Search,
  };
};

export default connect(MapStateToProps)(DefaultLayout);
