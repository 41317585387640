import React, { Component } from "react";
import "./modal.css";
import { Modal } from "reactstrap";

class ModalView extends Component {
  //eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal
          style={this.props.style}
          centered
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

export default ModalView;
