import React from "react";
import Button from "../../component/common/button/button";
import Render from "../../component/common/commonRender/commonRender";
// import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddressModal from "../../component/common/AddressModal/AddressModal";

import {
  AdminTypeString,
  AdminString,
  // SuperAdminString
} from "../constantVariable";

export const getAllSurveysData = () => {
  return {
    Header: [
      {
        title: "Company Name",
        field: "company_name",
        hidden: localStorage.getItem(AdminTypeString) === AdminString,
        editable: "never",
        render: (rowData) => (
          <div>
            {rowData.is_recent_survey ? (
              <Button squre={true}>New</Button>
            ) : null}

            {"   "}
            <Render>{rowData.company_name}</Render>
          </div>
        ),
      },
      {
        title: "First Name",
        field: "first_name",
        editable: "never",
        render: (rowData) => (
          <div>
            {localStorage.getItem(AdminTypeString) === AdminString ? (
              rowData.is_recent_survey ? (
                <Button squre={true}>New</Button>
              ) : null
            ) : null}
            {"   "}
            <Render>{rowData.first_name}</Render>
          </div>
        ),
      },

      {
        title: "Last Name",
        field: "last_name",
        render: (rowData) => <Render>{rowData.last_name}</Render>,
        editable: "never",
      },

      {
        title: "E-Mail",
        render: (rowData) => <Render>{rowData.email}</Render>,
        field: "email",
        editable: "never",
      },
      {
        title: "Survey Name",
        field: "survey_name",
        render: (rowData) => <Render>{rowData.survey_name}</Render>,
      },
      {
        title: "Survey Company Name",
        field: "survey_company_name",
        render: (rowData) => <Render>{rowData.survey_company_name}</Render>,
      },
      {
        title: "Survey Address",
        field: "survey_address",
        editable: "never",
        render: (rowData) =>
          rowData.survey_address ? (
            <AddressModal
              isEdit={true}
              suveyId={rowData.suvey_id}
              surveyAddress={rowData.survey_address}
              manualAddress={rowData.survey_manual_address}
            />
          ) : (
            "-"
          ),
      },
      {
        title: "Operational Hours Per Year",
        field: "operational_hours_per_year",
        render: (rowData) => (
          <Render>{rowData.operational_hours_per_year.toFixed(2)}</Render>
        ),
      },
      {
        title: "Rate Per Kwh",
        field: "rate_per_kwh",
        render: (rowData) => <Render>{rowData.rate_per_kwh}</Render>,
      },
      {
        title: "Fittings",
        field: "fittings",
        render: (rowData) => <Render>{rowData.fittings}</Render>,
        editable: "never",
      },
      {
        title: "Labour Cost",
        field: "labour_cost",
        render: (rowData) =>
          rowData.labour_cost ? (
            <Render>
              {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"}
              {rowData.labour_cost}
            </Render>
          ) : (
            "-"
          ),
        editable: "never",
      },
      {
        title: "Accessories & Plan Cost",
        field: "accessories_plan_cost",
        editable: "never",
        render: (rowData) =>
          rowData.accessories_plan_cost ? (
            <Render>
              {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"}
              {rowData.accessories_plan_cost}
            </Render>
          ) : (
            "-"
          ),
      },
      {
        title: "Savings",
        field: "total_saving_per_annum",
        render: (rowData) =>
          rowData.total_saving_per_annum ? (
            <Render>
              {" "}
              {"up to"}{" "}
              {rowData.currency_symbol ? rowData.currency_symbol : "\u20ac"}{" "}
              {rowData.total_saving_per_annum.toFixed(2)} {"/year"}
            </Render>
          ) : (
            "-"
          ),
        editable: "never",
      },
      {
        title: "Survey Date and Time",
        field: "created_at",
        editable: "never",
        render: (rowData) => <Render>{rowData.created_at}</Render>,
      },
      {
        title: "Verified Surveys",
        field: "survey_verified",
        render: (rowData) =>
          rowData.survey_verified ? (
            <div style={{ color: "green" }}>Yes</div>
          ) : (
            <div style={{ color: "red" }}>No</div>
          ),
        editable: "never",
      },
      {
        title: "Finalized Fitting",
        field: "data_fill",
        lookup: { true: "Yes", false: "No" },
        render: (rowData) => {
          return rowData.data_fill ? (
            <div style={{ color: "green" }}>Yes</div>
          ) : (
            <div style={{ color: "red" }}>No</div>
          );
        },
      },
      {
        title: "Survey Completed",
        field: "is_completed",
        lookup: { true: "Yes", false: "No" },
        render: (rowData) => {
          return rowData.is_completed ? (
            <div style={{ color: "green" }}>Yes</div>
          ) : (
            <div style={{ color: "red" }}>No</div>
          );
        },
      },
      {
        title: "Project Completed",
        field: "is_project_completed",
        lookup: { true: "Yes", false: "No" },
        render: (rowData) => {
          return rowData.is_project_completed ? (
            <div style={{ color: "green" }}>Yes</div>
          ) : (
            <div style={{ color: "red" }}>No</div>
          );
        },
      },
      {
        title: "Saved Discount",
        field: "discount_percentage",
        type: "number",
        render: (rowData) => <Render>{rowData.discount_percentage}</Render>,
      },
      {
        title: "Tax Percentage",
        field: "tax_percentage",
        type: "number",
        render: (rowData) => <Render>{rowData.tax_percentage}</Render>,
      },
      {
        title: "Grant/Carbon Credit",
        field: "carbon_discounts",
        type: "number",
        render: (rowData) => (
          <Render>
            {rowData && rowData.carbon_discounts
              ? rowData.carbon_discounts
              : "-"}
          </Render>
        ),
      },
    ],
    data: [],
  };
};
