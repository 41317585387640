import React from "react";
import { Row, Col } from "reactstrap";
import "./login.css";
import Logo from "../../../assets/img/newlogo.png";
import ReeValidate from "ree-validate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLightbulb,
  faTimesCircle,
  faCheckCircle,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import api from "../../../apiServices/index";
import {
  AdminTypeString,
  AdminString,
  SuperAdminString,
} from "../../../contants/constantVariable";
import { SucessColor, ErrorColor } from "../../../contants/defaultvalues";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "email",
    });

    this.state = {
      creadiantial: {
        email: "",
        password: "",
        username: "",
      },
      apiError: false,
      apiErrorData: {
        code: 0,
        error: "",
      },
      apiError1: false,
      isSubmitting: false,
      errors: this.validator.errors,
      isLoading: false,
      showPassword: false,
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const { errors } = this.validator;
    let { creadiantial } = this.state;

    creadiantial[name] = value;
    errors.remove(name);
    this.setState({
      creadiantial,
      apiError: false,
      apiError1: false,
    });
  };

  handleSubmit = (e) => {
    this.setState({ isSubmitting: true, isLoading: true, apiError: false });
    e.preventDefault();
    let { creadiantial } = this.state;
    creadiantial.username = creadiantial.username.trim();
    const { errors } = this.validator;
    const { history } = this.props;
    errors.remove();

    setTimeout(() => {
      api
        .postWithoutToken("/login", {
          username: creadiantial.username,
          password: creadiantial.password,
        })
        .then((response) => {
          let data = response.data;
          if (response.status === 200) {
            data = data.data;
            let adminType = data.is_super_admin
              ? SuperAdminString
              : AdminString;
            localStorage.clear();
            let main_route = data.is_super_admin ? "super_admin" : "admin";
            localStorage.setItem("main_route", main_route);
            localStorage.setItem("access_token", data.accessToken);
            localStorage.setItem(AdminTypeString, adminType);
            localStorage.setItem("user_id", data.id);
            localStorage.setItem("username", data.username);
            localStorage.setItem("email", data.email);

            localStorage.setItem("plan_expire", data.plan_expire);

            localStorage.setItem("is_admin_varify", data.verify);
            localStorage.setItem("is_super_admin", data.is_super_admin);
            if (data.currency_symbol) {
              localStorage.setItem("currency_symbol", data.currency_symbol);
            }

            setTimeout(() => {
              data.is_super_admin
                ? history.push("/super_admin")
                : data.email === "" || data.verify === false
                ? history.push("/admin-profile")
                : history.push("/admin");
            }, 1000);
          }
        })
        .catch((error) => {
          const data = error.response.data;
          this.setState(
            { apiError: true, apiErrorData: data, isLoading: false },
            () => {}
          );
        });
    }, 200);
  };

  handleForgotPassword = (event) => {
    event.preventDefault();
    const { history } = this.props;
    history.push("verify-email");
  };

  handleRegisterAsProfessional = (event) => {
    event.preventDefault();
    const { history } = this.props;
    history.push("register-professional");
  };

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    //eslint-disable-next-line
    const { errors } = this.state;
    return (
      <div>
        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid login_page">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    role="status"
                    className="login  loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    {this.state.isLoading ? (
                      <div className="loader-container">
                        <div
                          className="spinner-border LoginpageLoader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : null}

                    <div className="logo-align">
                      <img src={Logo} alt="logo" />
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      <p style={{ marginTop: "10px", marginBottom: "0px" }}>
                        <label htmlFor="user_login">
                          User Name
                          <br />
                          <div className={"input-border"}>
                            <input
                              type="text"
                              name="username"
                              value={this.state.creadiantial.username}
                              onChange={this.handleChange}
                              id="username"
                              className="form-control "
                            />
                          </div>
                          <FontAwesomeIcon
                            className=""
                            style={{ color: ErrorColor, opacity: "0" }}
                            size="lg"
                            icon={faTimesCircle}
                          />
                          {this.state.isSubmitting ? (
                            <FontAwesomeIcon
                              className="float-right close-icon"
                              style={{ color: SucessColor }}
                              size="lg"
                              icon={faCheckCircle}
                            />
                          ) : null}
                        </label>
                      </p>

                      <p>
                        <label htmlFor="user_pass">
                          Password
                          <br />
                          <div className="input-border">
                            <input
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              name="password"
                              value={this.state.creadiantial.password}
                              onChange={this.handleChange}
                              id="user_pass"
                              className="input"
                              size="20"
                            />
                          </div>
                          <FontAwesomeIcon
                            className=""
                            style={{ color: "red", opacity: "0" }}
                            size="lg"
                            icon={faTimesCircle}
                          />
                          {/* {this.state.creadiantial.password != "" &&
                          this.state.isSubmitting ? (
                            <FontAwesomeIcon
                              className="float-right close-icon"
                              style={{ color: SucessColor }}
                              size="lg"
                              icon={faCheckCircle}
                            />
                          ) : null} */}
                          {this.state.showPassword && (
                            <FontAwesomeIcon
                              className="float-right close-icon icon-cursor-pointer"
                              // style={{ color: SucessColor }}
                              size="lg"
                              onClick={this.handleShowPassword}
                              icon={faEye}
                            />
                          )}
                          {!this.state.showPassword && (
                            <FontAwesomeIcon
                              className="float-right close-icon icon-cursor-pointer"
                              // style={{ color: SucessColor }}
                              size="lg"
                              onClick={this.handleShowPassword}
                              icon={faEyeSlash}
                            />
                          )}
                        </label>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                            // backgroundColor: "red",
                          }}
                        >
                          <input
                            disabled={
                              this.state.creadiantial.username === "" ||
                              this.state.creadiantial.password === ""
                            }
                            style={{
                              fontFamily: "roboto-regular",
                            }}
                            onSubmit={this.handleSubmit}
                            type="submit"
                            name="wp-submit"
                            id="wp-submit"
                            className="btn btn-accent  "
                            value="Sign In"
                          />
                        </label>
                      </div>
                      {/* <div className="btns-css text-center">
                        <p className="submit">
                          <input
                            disabled={
                              this.state.creadiantial.username == "" ||
                              this.state.creadiantial.password == ""
                            }
                            style={{
                              fontFamily: "roboto-regular",
                            }}
                            onSubmit={this.handleSubmit}
                            type="submit"
                            name="wp-submit"
                            id="wp-submit"
                            className="btn btn-accent btn-block "
                            value="Sign In"
                          />
                        </p>
                      </div> */}
                    </form>

                    <p id="nav">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a href="" onClick={this.handleForgotPassword}>
                        Forgot password?
                      </a>{" "}
                    </p>
                    <p id="nav" style={{ marginTop: 8 }}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href=""
                        className="register-professional-link"
                        onClick={this.handleRegisterAsProfessional}
                      >
                        Register as professional
                      </a>{" "}
                    </p>

                    <p>
                      {this.state.apiError && (
                        <div className="error-message-box">
                          {this.state.apiErrorData.error}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Login;
