import React from "react";
import { Row, Col } from "reactstrap";
import { InvoiceData } from "../../../../contants/Invoices";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../apiServices/index";
import surveysIcon from "../../../../assets/icons/allsurveysblack.png";

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: InvoiceData,
      isLoading: false,
    };
  }

  componentWillMount() {
    this.getAllInvoices();
  }

  getAllInvoices = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/plan/purchase_history")
      .then((response) => {
        const responseData = response.data;
        //eslint-disable-next-line
        let newData = [];
        if (response.status === 200) {
          data.data = responseData;
          this.setState({ data, isLoading: false }, () => {});
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  handleView = (rowData) => {
    window.open(rowData.receipt_url);
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="col-xl-12">
                <section className="box " style={{ minHeight: "68vh" }}>
                  <div className="content-body custom-pagination">
                    <MaterialTable
                      isLoading={this.state.isLoading}
                      TableTitle={
                        <header className="panel_header text-uppercase">
                          <img src={surveysIcon} alt="survey" /> Invoices
                        </header>
                      }
                      deleteTextMsg={"Are you sure want to delete this user?"}
                      NoDataMessage={"No invoice data found"}
                      PdfFile={"All_Users_List"}
                      isDownload={false}
                      RowEdit={false}
                      RowDelete={false}
                      data={this.state.data}
                      {...this.props}
                      actions={false}
                      isPdf={false}
                      isView={true}
                      handleView={this.handleView}
                      toolbar={true}
                    />
                  </div>
                </section>
                <div className="float-right"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Invoices;
