import React from "react";
import "./header.css";
import Dashboardicon from "../../../assets/icons/Group 21.png";
import AllsurveysIcon from "../../../assets/icons/allsurveysgreen.png";
import SchedualactiveIcon from "../../../assets/icons/Schedualactive.png";
import QuatationIcon from "../../../assets/icons/quatationgreen.png";
import UsersIcon from "../../../assets/icons/user-green.png";
import { connect } from "react-redux";
import {
  SuperAdminString,
  AdminTypeString,
} from "../../../contants/constantVariable";
import TermsActiveIcon from "../../../assets/icons/termsactive.png";
import VideoTutorialActive from "../../../assets/icons/videoactive.png";

import { Navbar, Container } from "reactstrap";
import Notification from "./Notification";

class MainHeader extends React.Component {
  render() {
    const { history } = this.props;
    let HeaderName;
    localStorage.getItem(AdminTypeString) === SuperAdminString
      ? (HeaderName = history.location.pathname.replace("/super_admin/", ""))
      : (HeaderName = history.location.pathname.replace("/admin/", ""));
    let icon;
    if (HeaderName === "dashboard") {
      icon = Dashboardicon;
    } else if (HeaderName === "lighting surveys") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "emergency lighting schedules") {
      icon = SchedualactiveIcon;
    } else if (HeaderName === "Default Settings") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Current Package") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Video Tutorials") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Invoices") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Package config") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Create Package") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Companies Statistic") {
      icon = UsersIcon;
    } else if (HeaderName === "Users Statistic") {
      icon = UsersIcon;
    } else if (HeaderName === "lighting quotations") {
      icon = QuatationIcon;
    } else if (HeaderName === "emergency lighting reports") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "loaded-surveys/" + this.props.ParamsId.id) {
      HeaderName = this.props.LoadedCompanyData.company_name;
      icon = AllsurveysIcon;
    } else if (HeaderName === "loaded-schedules/" + this.props.ParamsId.id) {
      HeaderName = this.props.LoadedCompanyData.company_name;
      icon = SchedualactiveIcon;
    } else if (HeaderName === "create admin") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "Companies Profile") {
      icon = AllsurveysIcon;
    } else if (HeaderName === "create company") {
      icon = UsersIcon;
    } else if (HeaderName === "create users") {
      icon = UsersIcon;
    } else if (HeaderName === "User Profile") {
      icon = UsersIcon;
    } else if (HeaderName === "Company Profile") {
      icon = UsersIcon;
    } else if (HeaderName === "terms & condition") {
      icon = TermsActiveIcon;
    } else if (HeaderName === "Tutorial") {
      icon = VideoTutorialActive;
    } else if (HeaderName === "all products") {
      icon = AllsurveysIcon;
    }

    return (
      <Navbar
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top"
        }
      >
        <Container fluid>
          <hr />
          <div className="header-fix-2">
            <div className="navbar-wrapper">
              <div className="header-fix">
                <div className="header-sc-1">
                  <div className="header-sc-1-1">
                    <img
                      src={icon}
                      style={{ height: "15px", width: "15px" }}
                      alt="icon"
                    />
                    <h5>{HeaderName}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {localStorage.getItem("is_super_admin") === "false" ? (
                <Notification />
              ) : null}
            </div>
          </div>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ParamsId: state.ParamsId,
    LoadedCompanyData: state.LoadedCompanyData,
  };
};

export default connect(mapStateToProps)(MainHeader);
