import React from "react";
import { FaYoutubeSquare } from "react-icons/fa";
import api from "../../../../apiServices/index";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import { getallVideoTutorial } from "../../../../contants/allUsersData/videoTutorialData";
import SweetAlert from "react-bootstrap-sweetalert";

class VideoTutorialTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tutorialData: [],
      data: [],
      sweetAlert: false,
    };
  }

  toggalSweetAlert = () => {
    this.setState({ sweetAlert: !this.state.sweetAlert });
  };

  getVideoTutorial = () => {
    let { data } = this.state;
    this.setState({ isTutorialLoading: true });

    api
      .getWithToken("/video_tutorial")
      .then((res) => {
        const resData = res && res.data && res.data.data;
        data.data = resData;
        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  componentWillMount() {
    const videoData = getallVideoTutorial();
    this.setState({ data: videoData }, () => {
      this.getVideoTutorial();
    });
  }

  handleUpdate = (rowData) => {
    this.setState({
      apiTutorialError: false,
      apiTutorialSuccess: false,
      isLoading: true,
    });

    if (rowData.title.trim() === "") {
      this.setState({
        apiErrorData: "Please enter title",
        sweetAlert: true,
      });
    } else {
      let data = {
        title: rowData.title,
      };

      api
        .putWithToken(
          `/video_tutorial/edit?tutorial_id=${rowData.tutorial_id}`,
          data
        )
        .then((response) => {
          const { status } = response;

          if (status === 200) {
            this.setState({
              apiTutorialSuccess: true,
              isLoading: false,
            });
          }
          this.getVideoTutorial();
        })
        .catch((error) => {
          const err = error && error.response && error.response.data;
          this.setState(
            {
              apiTutorialError: true,
              apiErrorData: err,
              isLoading: false,
            },
            () => {}
          );
        });
      this.getVideoTutorial();
    }
  };

  handleDelete = (rowData) => {
    this.setState({
      apiTutorialError: false,
      apiTutorialSuccess: false,
      isLoading: true,
    });

    api
      .deleteWithToken(
        `/video_tutorial/delete?tutorial_id=${rowData.tutorial_id}`
      )
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          this.setState({
            apiTutorialSuccess: true,
            isLoading: false,
          });
        }
        this.getVideoTutorial();
      })
      .catch((error) => {
        const err = error && error.response && error.response.data;
        this.setState(
          {
            apiTutorialError: true,
            apiErrorData: err,
            isLoading: false,
          },
          () => {}
        );
      });
    this.getVideoTutorial();
  };

  onConfirm = () => {
    this.setState({ sweetAlert: false });
    this.getVideoTutorial();
  };

  render() {
    const { columns } = this.state;
    if (this.props.showMore) {
      //eslint-disable-next-line
      this.props.data.optionalHeader.map((data) => {
        columns.push(data);
      });
      this.props.HandleshowMore();
      this.props.columnAdded();
    }
    return (
      <div className="col-xl-12">
        <section className="box">
          <header className="panel_header text-uppercase">
            <FaYoutubeSquare fontSize={20} />
            {" Video Tutorial"}
          </header>
          <hr />

          <div className="pt-3">
            <MaterialTable
              isLoading={this.state.isLoading}
              TableTitle={
                <header className="panel_header text-uppercase">
                  <FaYoutubeSquare fontSize={20} /> VideoTutorial
                </header>
              }
              toolbar={false}
              NoDataMessage={"No video tutorial data found"}
              data={this.state.data}
              RowEdit={true}
              handleUpdate={this.handleUpdate}
              RowDelete={true}
              deleteTextMsg={"Are you sure want to delete this tutorial?"}
              handleDelete={this.handleDelete}
              search={false}
              {...this.props}
            />

            {this.state.sweetAlert && (
              <SweetAlert
                custom
                btnSize="lg"
                show={this.toggalSweetAlert}
                danger
                title={this.state.apiErrorData}
                onConfirm={this.onConfirm}
              ></SweetAlert>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default VideoTutorialTable;
