import React from "react";
import { FaYoutubeSquare } from "react-icons/fa";
import api from "../../../../apiServices/index";
import Button from "../../../../component/common/button/button";
import TextInput from "../../../../component/common/TextInput/textinput";
import io from "socket.io-client";
import { apiUrl } from "../../../../contants/defaultvalues";

let socket = io(apiUrl, {
  transports: ["websocket", "polling", "flashsocket"],
});

class VideoTutorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      tutorialError: false,
      disabled: false,
      isTutorialLoading: false,
      apiTutorialSuccess: false,
      apiTutorialError: false,
      video: "",
      title: "",
    };
  }

  getVideoTutorial = () => {
    this.setState({ isTutorialLoading: true });

    api
      .getWithToken("/video_tutorial")
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data[0];
        this.setState({
          isTutorialLoading: false,
          tutorial_link: data && data.tutorial_link,
        });
      })
      .catch((error) => {
        this.setState({ isTutorialLoading: false });
      });
  };

  imageHandler = (e) => {
    let { video } = this.state;
    const Image = e.target.files[0];

    video = Image;
    this.setState({ video });
    if (Image && !Image.name.match(/\.(x-msvideo|mpeg|ogg|webm|mp4)$/)) {
      this.setState({
        apiErrorData:
          "Selected file formate is invalid. Please select valid video file.",
      });
    } else {
      this.setState({ apiErrorData: "" });
    }
  };

  handleUploadVideo = () => {
    if (
      (this.state.title === "" && this.state.video === "") ||
      this.state.video === undefined
    ) {
      this.setState({
        apiErrorData: "Please enter title and video",
        isTutorialLoading: false,
        disabled: true,
      });
    }
    if (this.state.video === "" || this.state.video === undefined) {
      this.setState({
        apiErrorData: "Please enter video",
        isTutorialLoading: false,
        disabled: true,
      });
    }
    if (this.state.title === "") {
      this.setState({
        apiErrorData: "Please enter title",
        isTutorialLoading: false,
        disabled: true,
      });
    } else {
      this.setState({
        apiTutorialError: false,
        apiTutorialSuccess: false,
        isTutorialLoading: true,
      });

      const { video } = this.state;
      let data = new FormData();
      data.append("video", video);
      data.append("title", this.state.title);

      api
        .postWithToken("/video_tutorial/add", data)
        .then((response) => {
          const { status } = response;

          if (status === 200) {
            socket.emit("sendNotification", {
              title: "Checkout new informative video",
              message: "New tutorial video is uploaded. Check out now",
            });
            setTimeout(() => {
              this.setState({
                apiTutorialSuccess: true,
                isTutorialLoading: false,
                disabled: false,
              });
              window.location.reload(true);
            }, 3000);
          }
        })
        .catch((error) => {
          const err = error && error.response && error.response.data;
          this.setState(
            {
              apiTutorialError: true,
              apiErrorData: err,
              isTutorialLoading: false,
            },
            () => {}
          );
        });
    }
  };

  render() {
    return (
      <div className="col-xl-12">
        {this.state.isTutorialLoading ? (
          <div
            className="loader-container"
            style={{ height: "100%", width: "95%" }}
          >
            <div className="spinner-border text-secondary Loader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <section className="box">
          <header className="panel_header text-uppercase">
            <FaYoutubeSquare fontSize={20} />
            {"  "}
            Video Tutorial
          </header>
          <hr />
          <div className="content-body">
            <div
              style={{
                marginBottom: "10px",
                marginRight: "10px",
                marginLeft: "5px",
              }}
            >
              <form onSubmit={this.handleFormSubmit}>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ marginLeft: "10px", marginBottom: "5px" }}>
                    Title
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <TextInput
                      placeholder={"Enter title"}
                      search={false}
                      onChange={(e) => this.setState({ title: e.target.value })}
                      value={this.state.title}
                      name="title"
                    ></TextInput>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <div style={{ marginLeft: "10px", marginBottom: "5px" }}>
                    Video
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <TextInput
                      type="file"
                      name="video"
                      id="input"
                      accept="video/x-msvideo,video/mpeg,video/ogg,video/webm,video/mp4"
                      onChange={(e) => {
                        this.imageHandler(e);
                      }}
                    ></TextInput>
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "40px",
                    textAlign: "left",
                  }}
                >
                  <Button
                    full={true}
                    onClick={this.handleUploadVideo}
                    disabled={
                      (this.state.apiErrorData !== "" &&
                        this.state.apiErrorData !== undefined) ||
                      this.state.video === undefined ||
                      this.state.video === "" ||
                      this.state.title === "" ||
                      this.state.title.trim() === "" ||
                      this.state.disabled
                        ? true
                        : false
                    }
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>

            <p
              style={{
                marginTop: "20px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {this.state.apiErrorData !== "" &&
                this.state.apiErrorData !== undefined && (
                  <div className="error-message-box">
                    {this.state.apiErrorData}
                  </div>
                )}
            </p>

            <p
              style={{
                marginTop: "20px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {this.state.apiTutorialError && (
                <div className="error-message-box">
                  {this.state.apiErrorData.error}
                </div>
              )}
            </p>
            <p
              style={{
                marginTop: "20px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {this.state.apiTutorialSuccess && (
                <div className="success-message-box">
                  Video tutorial addded successfully
                </div>
              )}
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default VideoTutorial;
