import React from "react";
import "./button.css";

class Button extends React.Component {
  render() {
    return (
      <button
        {...this.props}
        type={this.props.type}
        style={{opacity:this.props.disabled ? 0.5 :1}}
        disabled={this.props.disabled}
        onSubmit={this.props.onSubmit}
        onClick={this.props.onClick}
        className={`my-theme-button ${this.props.long ? " longBtn" : ""} ${
          this.props.extraSmall ? " xsBtn" : ""
        } ${this.props.full ? " fullBtn" : ""}
        ${this.props.autobutton ? " autobutton" : ""}
        ${this.props.small ? " smallBtn" : ""} ${
          this.props.squre ? " squreBtn" : ""
        } `}
      >
        {this.props.children}
      </button>
    );
  }
}

export default Button;
