import React from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../../component/common/button/button";
import api from "../../../../apiServices/index";
import TextInput from "../../../../component/common/TextInput/textinput";
import { getallContactUs } from "../../../../contants/allUsersData/contactUsData";
import MaterialTable from "../../../../component/meterialTable/meterialTable";
import SweetAlert from "react-bootstrap-sweetalert";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      apiError: false,
      apiSuccess: false,
      isLoading: false,
      data: [],
      title: "",
      url: "",
      sweetAlert: false,
      message: "",
    };
  }

  componentWillMount() {
    const contactUsData = getallContactUs();
    this.setState({ data: contactUsData }, () => {
      this.getContactUs();
    });
  }

  getContactUs = () => {
    let { data } = this.state;
    this.setState({ isLoading: true });
    api
      .getWithToken("/contact_us")
      .then((res) => {
        const responseData = res && res.data && res.data.data;
        data.data = responseData;
        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  toggalSweetAlert = () => {
    this.setState({ sweetAlert: !this.state.sweetAlert });
  };

  handleShow = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false, title: "", url: "", apiError: false });
  };

  handleSubmit = () => {
    this.setState({
      apiError: false,
      apiSuccess: false,
      isLoading: true,
    });
    const data = {
      title: this.state.title,
      url: this.state.url,
    };

    api
      .postWithToken("/contact_us/add", data)
      .then((res) => {
        const { status } = res;

        if (status === 200) {
          this.setState({
            apiSuccess: true,
            isLoading: false,
          });
          window.location.reload(true);
        }
        this.getContactUs();
        this.handleClose();
      })
      .catch((error) => {
        const err = error && error.response && error.response.data;
        this.setState(
          {
            apiError: true,
            apiErrorData: err,
            isLoading: false,
          },
          () => {}
        );
      });
    this.getContactUs();
  };

  handleUpdate = (rowData) => {
    this.setState({
      apiError: false,
      apiSuccess: false,
      isLoading: true,
    });
    const data = {
      title: rowData.title,
      url: rowData.url,
    };

    api
      .putWithToken(`/contact_us/edit?contact_id=${rowData.contact_id}`, data)
      .then((res) => {
        const { status } = res;

        if (status === 200) {
          this.setState({
            apiSuccess: true,
            isLoading: false,
            sweetAlert: true,
            message: "Contact us updated successfully",
          });
        }
        this.getContactUs();
      })
      .catch((error) => {
        const err = error && error.response && error.response.data;
        this.setState(
          {
            apiError: true,
            apiErrorData: err,
            isLoading: false,
            sweetAlert: true,
          },
          () => {}
        );
      });
    this.getContactUs();
  };

  handleDelete = (rowData) => {
    this.setState({
      apiError: false,
      apiSuccess: false,
      isLoading: true,
    });

    api
      .deleteWithToken(`/contact_us/delete?contact_id=${rowData.contact_id}`)
      .then((res) => {
        const { status } = res;

        if (status === 200) {
          this.setState({
            apiSuccess: true,
            isLoading: false,
          });
        }
        this.getContactUs();
      })
      .catch((error) => {
        const err = error && error.response && error.response.data;
        this.setState(
          {
            apiError: true,
            apiErrorData: err,
            isLoading: false,
          },
          () => {}
        );
      });
    this.getContactUs();
  };

  onConfirm = () => {
    this.setState({ sweetAlert: false });
    this.getContactUs();
  };

  render() {
    return (
      <div className="col-xl-12">
        <section className="box">
          <header className="panel_header text-uppercase">
            <FontAwesomeIcon size="1x" icon={faUser} /> App Contact Us
          </header>
          <hr />
          <div className="content-body">
            <div className="pt-3">
              <CustomButton
                onClick={this.handleShow}
                full={true}
                className="pb-3"
              >
                Add Contact Us +
              </CustomButton>
            </div>

            <Col sm={6} md={4} xl={3}>
              <Modal isOpen={this.state.modalOpen} centered={true}>
                <form onSubmit={this.handleSubmit}>
                  {this.state.isLoading ? (
                    <div
                      className="loader-container"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <div
                        className="spinner-border text-secondary Loader"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : null}
                  <ModalHeader
                    style={{ color: "#1AB394", marginBottom: 10 }}
                    toggle={this.handleClose}
                  >
                    Add Contact Us
                  </ModalHeader>
                  <ModalBody>
                    <div
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <div
                          style={{ marginLeft: "10px", marginBottom: "5px" }}
                        >
                          Title
                        </div>
                        <div
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          <TextInput
                            placeholder={"Enter title"}
                            search={false}
                            onChange={(e) =>
                              this.setState({ title: e.target.value })
                            }
                            value={this.state.title}
                            name="title"
                            style={{ color: "black" }}
                          ></TextInput>
                        </div>
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        <div
                          style={{ marginLeft: "10px", marginBottom: "5px" }}
                        >
                          URL
                        </div>
                        <div
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          <TextInput
                            placeholder={"Enter url"}
                            search={false}
                            onChange={(e) =>
                              this.setState({ url: e.target.value })
                            }
                            value={this.state.url}
                            name="url"
                            style={{ color: "black" }}
                          ></TextInput>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "20px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {this.state.apiError && (
                        <div className="error-message-box">
                          {this.state.apiErrorData.error}
                        </div>
                      )}
                    </p>
                    <p
                      style={{
                        marginTop: "20px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {this.state.apiSuccess && (
                        <div className="success-message-box">
                          Contact us addded successfully
                        </div>
                      )}
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      onClick={this.handleClose}
                      className="my-theme-button btn btn-secondary"
                      color="secondary"
                    >
                      Close
                    </button>

                    <CustomButton full={true}>Save changes</CustomButton>
                  </ModalFooter>
                </form>
              </Modal>
            </Col>

            <div className="pt-3">
              <MaterialTable
                isLoading={this.state.isLoading}
                TableTitle={
                  <header className="panel_header text-uppercase">
                    <FontAwesomeIcon
                      style={{ marginRight: "2px" }}
                      size="1x"
                      icon={faUser}
                    />{" "}
                    App Contact Us
                  </header>
                }
                NoDataMessage={"No contact us data found"}
                data={this.state.data}
                toolbar={false}
                RowEdit={true}
                handleUpdate={this.handleUpdate}
                RowDelete={true}
                deleteTextMsg={"Are you sure want to delete this contact?"}
                handleDelete={this.handleDelete}
              />

              {this.state.sweetAlert && (
                <SweetAlert
                  custom
                  btnSize="lg"
                  show={this.toggalSweetAlert}
                  danger
                  title={
                    this.state.apiError
                      ? this.state.apiErrorData.error
                      : this.state.message
                  }
                  onConfirm={this.onConfirm}
                ></SweetAlert>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ContactUs;
