import React from "react";
import { Row, Col } from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBox } from "@fortawesome/free-solid-svg-icons";
// import api from "../../../../apiServices/index";
// import { CardText, CardSubtitle } from "reactstrap";
import PackageDetails from "./PackageDetails";
import PackagePurchase from "./PackagePurchase";

class CurrentPackage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={6} md={6}>
              <PackageDetails />
            </Col>
            <Col xs={6} md={6} className="h-100">
              <PackagePurchase />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CurrentPackage;
